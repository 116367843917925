import router from '../../router/index'
import HTTP from "../../Axios/Axios";
import Swal from 'sweetalert2'


const showAlert = (val) => {
  Swal.fire({
    width: 300,
    color: 'white',
    text: val[0],
    position: 'top',
    background: val[1]==='success'? '#68B984':'#F96666',
    timer: 1500,
    showConfirmButton:false
  })
}

const state = {
 username:'',
 useremail:'',
 usertoken:'',
 userrole:'',
 userid:'',
 userphonenumber:'',
 userstore:{},
 usercompany:'',
 usercompanyId:'',
 userimage:''
};
const getters = {
  USER_NAME: (state)=>{
      return state.username
    },
  USER_EMAIL: (state)=>{
      return state.useremail
    },
  USER_TOKEN: (state)=>{
      return state.usertoken
    },
  USER_ROLE: (state)=>{
      return state.userrole
    },
  USER_PHONE: (state)=>{
      return state.userphonenumber
    },
  USER_ID: (state)=>{
      return state.userid
    },
  USER_STORE: (state)=>{
      return state.userstore
    },
  USER_COMPANY: (state)=>{
      return state.usercompany
    },
  USER_COMPANY_ID: (state)=>{
      return state.usercompanyId
    },
  USER_IMG: (state)=>{
      return state.userimage
    },
 
};
// -------------------------------------------------------//
// -------------------------------------------------------//


const mutations = {
    Sign_Up:(state,item) =>(   
      
        state.username = item.fullName,
        state.useremail = item.email,
        state.usertoken = item.access_token,
        state.userrole = item.role,
        state.userphonenumber = item.phoneNumber,
        state.userid = item.id,
        state.userstore = item.store,
        state.usercompany = item.companyName,
        state.usercompanyId = item.companyId,
        state.userimage = item.img,
        router.push('/Home')
    ),
    
    Sign_Out:(state,item) =>(   
      localStorage.clear(),
      state.username = '',
      state.useremail = '',
      state.usertoken = '',
      state.userrole = '',
      state.userstore = '',
      state.usercompany = '',
      state.userimage = '',
      state.userphonenumber = '',
      state.userid = '',
      router.push('/')
      
      ),
      Update_profile:(state,item) =>(   
        
          state.userphonenumber = item.phoneNumber,
          state.userimage = item.img
        
      )
    };
// -------------------------------------------------------//
// -------------------------------------------------------//


const actions = {
    async SignUp({commit},payload){
      try {
        const email = payload.email;
        const password = payload.password;
        const resp =await HTTP.post('auth/login',{email,password})
        console.log(resp);
        localStorage.setItem('userstore', JSON.stringify(resp.data));
        commit('Sign_Up',resp.data)
        showAlert(["Welcome Back, " +resp.data.fullName, 'success'])
        
      } catch (error) {
        error.response ?showAlert(["Error: " +error.response.data.message,'error']):null
      }
    },
    async updateMyProfile ({commit},profile){
      try {
        const resp =await HTTP.patch('user/update',profile)
        commit('Update_profile',resp.data)
        // ---------updating the local persisted data---------
        let persisteduser = JSON.parse(localStorage.getItem('userstore'))
        persisteduser.phoneNumber = resp.data.phoneNumber
        persisteduser.img = resp.data.img
        localStorage.setItem('userstore', JSON.stringify(persisteduser));


        showAlert(["Your profile has been updated succesfully", 'success'])
      } catch (error) {

        showAlert(["Error: " +error.response.data.message,'error'])

      }
   },
    async updatePassword ({commit},{password,currentPassword}){
      try {
        const resp =await HTTP.patch('user/changePassword',{password,currentPassword})
        showAlert([resp.data.message, 'success'])
      } catch (error) {
        showAlert(["Error: " +error.response.data.message,'error'])
      }
   },
    async ActivateAccount ({commit},data){
      try {
        const resp =await HTTP.post('auth/setActivated',data)
        showAlert([resp.data.message, 'success'])
        router.push('/')
      } catch (error) {
        showAlert(["Error: " +error.response.data.message,'error'])
      }
   },
    async SendResetEmail ({commit},email){
      try {
        const resp =await HTTP.post('/auth/forgetPassword',email)
        router.push('/ResetPassword/:'+email.email)
      } catch (error) {
        showAlert(["Error: " +error.response.data.message,'error'])
      }
   },
    async ResetPassword ({commit},data){
      try {
        const email =data.email
        const code =data.code
        const password =data.password

        const resp =await HTTP.post('/auth/confirmCode',{code,email})
        
        .then(async(resp)=>{
          const resetToken =  resp.data.resetToken
          const response = await HTTP.post('/auth/newPassword',{password,resetToken})
          showAlert([response.data.message, 'success'])
          localStorage.clear()
          router.push('/')
        })

          
      } catch (error) {
        showAlert(["Error: " +error.response.data.message,'error'])
      }
   },
   
 };


// -------------------------------------------------------//
// -------------------------------------------------------//
const SignUpState = {
  state,
  mutations,
  actions,
  getters,
};

export default SignUpState;