<template >
<div v-if="!toggleAddUser">
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">USERS
            <div class="h-2 w-11 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict" :class="{'hidden':restrict}" class=" mt-28 ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer " @click="toggleAddUser=!toggleAddUser">
            <img :src="add" alt="add" class="h-3 mr-1.5">
            add user
        </button>
        </div>
        <!-- All -->
        <div class="flex mt-5  ml-auto justify-end ">
            <div class=" font-bold text-[15px] -mr-8 mt-2 " :class="{'-mr-48':userStore}" > FILTER BY
                <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
            </div>
            <div  >
                <div class="flex justify-end mr-[100px] space-x-24 " >
                    <DropDown :key="companykey" v-if="!userStore" placeholderp="Company" :items="staticList.company" displayable="name" @selected="(selected)=>setcompany(selected)"  width="false"/>
                        <div class="space-y-3 -ml-5">
                    <DropDown :key="rolekey" placeholderp="Role" :items="roles" displayable="name" @selected="(selected)=>setrole(selected)"  width="false"/>
                    <DropDown :key="statuskey" placeholderp="Status" :items="status" displayable="name" @selected="(selected)=>setstastus(selected)"  width="false"/>
                        </div>
                </div>
                <div class=" flex items-center ">
                <img :src="calander" alt="calander" @click="toggledatepicker = true" class=" h-10 py-1.5 rounded-lg px-2.5 mt-6 mr-3 bg-main_gray">
                <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                <div class="inputbox p-0  flex items-center mr-5  mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4" >
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll"  placeholder="Search by user name..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter" @delete="deleteitem" @detail="detailitem"  @toggle="toggleuser" showdoc="notNeeded" exportName="USers" :headerList="usersHeaders" :itemlist="itemlist"/>
    </div>
    <AddUser v-if="toggleAddUser" @close="toggleAddUser=!toggleAddUser"  class="absolute top-0 left-0 z-30 bg-white "/>
    <UpdateUser v-if="toggleUpdate" :olddata="olddata" @close="closeUpdate" class="absolute top-0 left-0 z-20  bg-white w-full"/>
</template>

<script  setup>
import { ref } from "@vue/reactivity"
import { computed,onBeforeMount, watch } from "@vue/runtime-core"
import AddUser from "./AddUser.vue"
import UpdateUser from "./UpdateUser.vue"
import DateRange from "../../components/DateRange.vue";
import TableView from "../../components/TableView.vue";
import DropDown from "../../components/DropDown.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

const store = useStore()
onBeforeMount(() => {
    store.dispatch('fetchUsers')
})

let itemlist =  ref(store.getters.usersList)
let staticList =  computed(() =>(store.getters.staticList))
if (staticList.value.company.length>1 && staticList.value.company[0].name!=="ALL") {
    staticList.value.company.unshift({ id: 0, name: "ALL"});
}
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))


const usersHeaders = [
 { text: "User ID", value: "id" , sortable: true },
  { text: "Full Name", value: "fullName"},
  { text: "Email", value: "email"},
  { text: "Phone #", value: "phoneNumber"},
  { text: "Role", value: "role"},
  { text: "Company", value: "company.name"},
  { text: "Actions", value: "operation"},
];

 const roles = [
    { id: 1, name: 'System Admin', value: "SYSTEM_ADMIN" },
    { id: 2, name: 'Upper Management', value: "UPPER_MANAGEMENT" },
    { id: 3, name: 'Middle Management', value: "MIDDLE_MANAGEMENT" },
    { id: 4, name: 'Project Manager', value: "PROJECT_MANAGER" },
    { id: 5, name: 'Master store Manager', value: "MASTER_STORE_MANAGER" },
    { id: 6, name: 'Brunch store Manager', value: "BRANCH_STORE_MANAGER" },
  ]
 const status = [
    { id: 1, name: 'Active',value: true},
    { id: 2, name: 'Not-Active',value:false },
   
  ]

const toggleAddUser = ref(false)
const toggledatepicker = ref(false)

watch(toggleAddUser, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
       itemlist = computed(() =>(store.getters.usersList))
       filter.value = new Date()
    }
})


const company = ref()
const role = ref()
const searchFilter = ref('')
// keys of filter inputs to refresh them when nedded
const statuskey = ref()
const companykey = ref()
const rolekey = ref()
const toggleUpdate = ref(false)
 
let filter = ref(1)
const filterVars = ref({company:null,role:null,status:null , search:null,init:null,end:null})

// activate and disable user
const toggleuser=(val)=>{
    if (userRole.value === 'SYSTEM_ADMIN') {
         store.dispatch('toggleUser',val)
         .then(()=>{
             itemlist = ref(store.getters.usersList)
             filter.value = new Date()

         })
    }
   
}

const closeUpdate=(val)=>{
    itemlist = ref(store.getters.usersList)
    filter.value = new Date() 
   toggleUpdate.value = !toggleUpdate.value 
}

const setrole=(val)=>{
    role.value = val.name 
    filterVars.value.role = val.value
    filterAll();
}
const setcompany=(val)=>{
    company.value = val.name 
    filterVars.value.company = val.name
    filterAll();
}
const setstastus=(val)=>{
    company.value = val.name 
    filterVars.value.status = val.value
    filterAll();
}
const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const filterAll = ( ) =>{
    itemlist = ref(store.getters.usersList)
    if (filterVars.value.company !== null && filterVars.value.company !=='ALL') {
        itemlist.value = itemlist.value.filter((user)=>user.company.name === filterVars.value.company)   
    }
    if (filterVars.value.role !== null){

        itemlist.value = itemlist.value.filter((user)=>user.role === filterVars.value.role)
    }
    if (filterVars.value.status !== null){

        itemlist.value = itemlist.value.filter((user)=>user.status === filterVars.value.status)
    }
    if (filterVars.value.init !== null){

        itemlist.value = itemlist.value.filter((user)=>new Date(user.createdAt) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )

   }
    if (filterVars.value.end !== null){

        itemlist.value = itemlist.value.filter((user)=>new Date(user.createdAt) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
    if (searchFilter.value !== ''){

        itemlist.value = itemlist.value.filter((user)=>(user.fullName).toLowerCase().includes((searchFilter.value).toLowerCase()))
    } 
    filter.value = new Date()

}

const deleteitem=(val)=>{
     if (userRole.value === 'SYSTEM_ADMIN'){

        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteUser',val.id)
                            .then(()=>{
                                store.dispatch('fetchUsers')
                            })
                            .then(()=>{
                                itemlist = computed(() =>(store.getters.usersList))
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
     }    
}

const olddata = ref();
const detailitem=(val)=>{
    if (userRole.value === 'SYSTEM_ADMIN'){
        olddata.value = val;
        toggleUpdate.value = !toggleUpdate.value
    }
    
}

const restrict = computed(()=>{
    if (userRole.value!=='SYSTEM_ADMIN') {
        return true
    }
    else{
        return false
    }
})

</script>



<style lang="postcss">
    
</style>