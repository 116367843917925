<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer" > Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">RECEIVE GOODS
            <div class="h-2 w-[138px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <input type="text" v-model="grvn"   placeholder="GRV doc #…" required class="inputbox nav_blue mt-10 mb-6">
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                        <input type="text" v-model="Suppliername"   placeholder="Supplier's name..." required class="inputbox  mb-3">
                        <input type="text" v-model="deliveredby"   placeholder="Delivered by..." required class="inputbox  mb-3">
                        <input type="text" v-model="purchaserequest"   placeholder="Purchase requisition #..." required class="inputbox  mb-3 ">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.grvscanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="grvscan?grvscan.name:''" disabled  placeholder="Attach GRV scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="grvscanfile" accept=".pdf,image/*" @change="uploadgrv" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                   
                        <input type="text" v-model="invoicen"   placeholder="Invoice #..." required class="inputbox  mb-3">
                        <input type="text" v-model="receivedby"   placeholder="Received by..." required class="inputbox  mb-3">
                       <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="deliverydate" placeholder="Delivery date..." :upperLimit="new Date()" style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                       <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.invoicescanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="invoicescan?invoicescan.name:''" disabled  placeholder="Attach invoice scan..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="invoicescanfile" accept=".pdf,image/*" @change="uploadinvoice" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in itemList" :key="item" class="mb-2"> 
            <div class="flex">
                <!-- <div class=" relative inputbox  w-40 mr-2">
                    <img :src="magnifyingglass" alt="magnifyingglass" class="absolute right-3 h-3 mt-1.5">
                    <input type="text" v-model="allItems[index].itemId" @input="searchItem(index)"  placeholder="Item ID..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                </div> -->
                <ItemSearch  :items="staticItemList" displayable="itemName" @selected="(selected)=>searchItem(selected,index)"  width="false" class="mr-24 mx-1 "/>
                <input type="text" v-model="allItems[index].uom" readonly   placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="number" v-model="allItems[index].balance" @input="(event)=>checkBalance(index,event)"  placeholder="Qty..." required class="inputbox ml-3 mr-3 w-36">
                <input type="number" v-model="allItems[index].price" @input="(event)=>checkPrice(index,event)"   placeholder="Unit price..." required class="inputbox  mr-3 w-36">
                <input type="text" v-model="allItems[index].remark"   placeholder="Remark..." class="inputbox  mr-3 w-56">
                <button v-if="index>0" @click="deductList(index)" class="bg-red-500 text-white text-3xl w-9 h-9 mx-2 items-center  rounded-full ">-</button>
                <button v-if="index===itemList-1" @click="addItemInputs" class=" text-white text-3xl w-9 h-9 mx-2 items-center  pb-3 rounded-full "
                :class="{'bg-nav_blue':itemList!==1,'bg-[#8F8F8F]':itemList===1}">+</button>
            </div>
                <p class="text-[12px] block">{{allItems[index].desc}}</p>
        </div>

    <div class="mt-20">
            <button  v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">submit</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
        </div>
    </form>    
      
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Datepicker from 'vue3-datepicker'
import ItemSearch from "@/components/ItemSearch.vue";
import DropDown from "../../components/DropDown.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";


const close = defineEmits("close")


onBeforeMount(() => {  
    store.dispatch('fetchGoodsRecived')
})


const store = useStore()
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
const staticItemList =  ref(JSON.parse(JSON.stringify(store.getters.itemList)))
const grvn = ref()
const Suppliername = ref()
const deliveredby = ref()
const grvscan = ref()
const invoicen = ref()
const receivedby = ref()
const invoicescan = ref()
const purchaserequest = ref()
const deliverydate = ref()

const itemId = ref()
const uom = ref()
const balance = ref()
const price = ref()
const remark = ref()
const isLoading = ref(false);


const allItems = ref([
    {itemId:'',desc:'',uom:'',balance:'',price:'',remark:''}
])

const setUom =(selected,index)=>{
    allItems.value[index].uom = selected.name
}

const itemList =computed(()=>{
   return allItems.value.length===0 ?allItems.value.length + 1:allItems.value.length
})
    
const addItemInputs = ()=>{
    allItems.value.push({itemId:'',desc:'',uom:'',balance:'',price:'',remark:''})
}

const deductList=(i)=> {
  allItems.value = allItems.value.filter((_, index) => index !== i)
  alldesc.value = alldesc.value.filter((_, index) => index !== i)
}

const uploadgrv =(event)=>{ 
 grvscan.value =  event.target.files[0]
}
const uploadinvoice =(event)=>{ 
 invoicescan.value =  event.target.files[0]
}

const isListEmpty = computed(()=>{
         for (let index = 0; index < itemList.value; index++) {
            if( !allItems.value[index].itemId||! allItems.value[index].uom|| !allItems.value[index].balance || !allItems.value[index].price){
                return true
            }
            
        }
})
const activate = computed(()=>{
    if(!grvn.value || !Suppliername.value || !deliveredby.value || !grvscan.value || !invoicen.value ||
     !receivedby.value|| !invoicescan.value|| !purchaserequest.value|| !deliverydate.value ||isListEmpty.value){
        return true;
    }else{
        return false;
    }
})

const checkBalance =(index,event)=>{
    if((Number(allItems.value[index].balance)>0)){
        //  allItems.value[index].balance = Number(event.data);
    }else{
        allItems.value[index].balance = null
    }
}
const checkPrice =(index,event)=>{
    if((Number(allItems.value[index].price)>0)){
        //  allItems.value[index].balance = Number(event.data);
    }else{
        allItems.value[index].price = null
    }
}

const searchItem=(selected,val)=>{
    // staticItemList.value =  JSON.parse(JSON.stringify(store.getters.itemList))
    allItems.value[val].itemId =selected
    // for (let index = val-1; index >= 0; index--) {
        // }
        
        for (let index = 0; index < staticItemList.value.length; index++) {
            
            if((staticItemList.value[index].id).toLowerCase() === (allItems.value[val].itemId).toLowerCase()) {
                allItems.value[val].uom = staticItemList.value[index].uom.abbreviation
                allItems.value[val].desc = staticItemList.value[index].itemName
                staticItemList.value = staticItemList.value.filter(item=>item.id.toLowerCase()!==allItems.value[val].itemId.toLowerCase())    
                
                return 
            } else{
                allItems.value[val].uom = ''
                allItems.value[val].desc = ''
                
            } 
        }
   
}

const submit =()=>{
   const formData = new FormData();
    grvn.value && formData.append("grvNumber", grvn.value);
    Suppliername.value && formData.append("supplierName", Suppliername.value);
    deliveredby.value && formData.append("deliverBy", deliveredby.value);
    grvscan.value && formData.append("GRV", grvscan.value);
    invoicen.value && formData.append("invoiceNumber", invoicen.value);
    receivedby.value && formData.append("receivedBy", receivedby.value);
    invoicescan.value && formData.append("INVOICE", invoicescan.value);
    purchaserequest.value && formData.append("pr", purchaserequest.value);
    deliverydate.value && formData.append("deliveredDate", deliverydate.value);
    allItems.value && formData.append("items", JSON.stringify(allItems.value));

     isLoading.value = true;
     store.dispatch('addGrv',formData)
     .then(()=>{ 
          store.dispatch('fetchGoodsRecived')
          store.dispatch('fetchInventory')
          isLoading.value = false;
      })
      .then(()=>{ 
            close('close')
        }) 
      .catch((error) => {
        isLoading.value = false;
    });
     
     
}


</script>

<style lang="postcss">

</style>