<template >
    <div class="flex  ml-5  ">
        <div v-for="(item,index) in menu" :key="index" class="py-2 cursor-pointer" :class="{'bg-blacker text-gold_yellow':selectedmenu===item.name,'bg-[#DFDFDF] text-blacker':selectedmenu!==item.name,'rounded-bl-xl pl-2':index===0,'rounded-br-xl':index===4 }" >
            <p class="border-r-2 border-[#707070] px-1.5" :class="{'border-r-0 pr-3 ': index===4,'border-blacker':selectedmenu===item.name}" @click="selectetab(item.name)" >{{item.name}} </p>
        </div>
    </div>
    <component :is="currentSelected"  ></component>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onMounted } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Items from "./Items.vue"
import Catagories from "./Catagories.vue"
import SubCatagories from "./SubCatagories.vue"
import UnitMeasure from "./UnitMeasure.vue"
import Company from "./Company.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import { useStore } from "vuex";

const store = useStore()
onMounted(() => {
    store.dispatch('fetchCatagory')
    store.dispatch('fetchSubCatagory')
    store.dispatch('fetchCompany')
    
    const userList =  computed(() => store.getters.usersList)
})
const storeList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
const itemList =  computed(() =>JSON.parse(JSON.stringify(store.getters.itemList)))

const selectedmenu = ref("Items")
const currentSelected = ref(Items)
function selectetab(selected){
    selectedmenu.value=selected

     selected==='Items'?currentSelected.value = Items 
  :  selected==='Categories'?currentSelected.value = Catagories 
  :  selected==='Sub-categories'?currentSelected.value = SubCatagories 
  :  selected==='Company'?currentSelected.value = Company 
  :  currentSelected.value = UnitMeasure 
 
}

 const menu = [
    { name: 'Items', display: false },
    { name: 'Categories', display: false },
    { name: 'Sub-categories', display: false },
    { name: 'Unit of Measures', display: false },
    { name: 'Company', display: false },
    
  ]




</script>



<style lang="postcss">
    
</style>