<template >
<div v-if="!toggleOrder && !toggleDetails">
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">PURCHASE ORDERS
            <div class="h-2 w-32 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict" :class="{'hidden':restrict || userStore===null}" class="mt-28 ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleOrder=!toggleOrder">
            <img :src="add" alt="add" class="h-3 mr-1.5">
           order purchase
        </button>
        
        </div>
        <!-- filter -->
        <div class="flex mt-16  ml-auto justify-end ">
           
            <div>
                <div class="flex items-center mr-[100px] space-x-3" :class="{' mr-[20px]':userStore}">
                     <div class=" font-bold text-[15px] mr-9 "> FILTER BY
                        <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                    </div>
                    <div class=" h-10 py-2 rounded-lg px-3  bg-main_gray">
                        <img :src="calander" alt="calander" @click="toggledatepicker = true" class="h-6" > 
                        <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                    </div>
                    <DropDown v-if="!userStore" placeholderp="Stores" :items="staticStoreList" displayable="storeName" @selected="(selected)=>setstores(selected)"  width="false"/>
                    <input v-else type="text" v-model="userStore.storeName" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">        
                </div>
                <div class=" flex ">
                <div class="inputbox p-0  flex items-center mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchfilter" @keyup.enter="filterAll" placeholder="Search by POV ID ..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter" showdoc="Needed"  showeye="Needed" showdel="Needed" @displayScan="showScan" @delete="deleteitem"  @detail="detailitem" exportName="PurchaseOrder" :headerList="Headers" :itemlist="poList"/>
</div>      
   <OrderPurchase v-if="toggleOrder" @close="toggleOrder=!toggleOrder" class="absolute top-0 left-0  bg-white w-full"/>
   <PurchaseOrderDetails v-if="toggleDetails" @close="toggleDetails=!toggleDetails"  class="absolute top-0 left-0  bg-white w-full"/>

</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, watch } from "@vue/runtime-core"
import PurchaseOrderDetails from "./PurchaseOrderDetails.vue"
import DateRange from "../../components/DateRange.vue";
import DropDown from "../../components/DropDown.vue"
import TableView from "../../components/TableView.vue";
import OrderPurchase from "./OrderPurchase.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";


const store = useStore()
onBeforeMount(() => {  
    store.dispatch('fetchPurchaseOrder')
})

let filter = ref('122')

const toggleOrder = ref(false)
const toggleDetails = ref(false)
const toggledatepicker = ref(false)

const searchfilter = ref('')
const storefilter = ref('')



let poList = ref(store.getters.poList)
let staticStoreList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList.store)))
staticStoreList.value.unshift({ id: 0, storeName: "ALL"});
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))


const Headers = [
  { text: "POV ID", value: "povNumber" , sortable: true },
  { text: "SRV ID", value: "srvNumber",sortable: true},
  { text: "Project", value: "project"},
  { text: "From", value: "from"},
  { text: "To", value: "to"},
  { text: "Approved By", value: "approvedBy"},
  { text: "Date", value: "datePov",sortable: true},
  { text: "Actions", value: "operation"},
];

watch(toggleOrder, async (newQuestion, oldQuestion) => {

    if ( oldQuestion===true ) {
        store.dispatch('fetchPurchaseOrder')
        .then(()=>{
            poList = ref(store.getters.poList)  
            filter.value = new Date()
        })
    }
})
watch(toggleDetails, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchPurchaseOrder')
        .then(()=>{
        poList = ref(store.getters.poList)
        filter.value = new Date()
        })
    }
})

const filterVars = ref({store:null,init:null,end:null})

const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}


const setstores=(val)=>{
    storefilter.value = val.storeName 
    filterVars.value.store = val.id
    filterAll();
}

const filterAll = ( ) =>{
    poList = ref(store.getters.poList)
    if (filterVars.value.store !== null && filterVars.value.store !== 0) {
        poList.value = poList.value.filter((user)=>user.storeId === filterVars.value.store)   
    }
     if (filterVars.value.init !== null){

        poList.value = poList.value.filter((user)=>new Date(user.povDate) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )
    }
    if (filterVars.value.end !== null){

        poList.value = poList.value.filter((user)=>new Date(user.povDate) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
    if (searchfilter.value !== ''){
        poList.value = poList.value.filter((user)=>(user.povNumber).toLowerCase().includes((searchfilter.value).toLowerCase()))
    }
    
    filter.value = new Date()

}




const detailitem=(val)=>{
    if (userRole.value === 'MASTER_STORE_MANAGER' ){
        store.dispatch('getPoDetail',{id:val.id})
        .then(()=>{ 
            toggleDetails.value = !toggleDetails.value
        })
    }
}
const deleteitem=(val)=>{
    if (userRole.value === 'MASTER_STORE_MANAGER' ){
        const data = {
            id :val.id,
            povNumber :val.povNumber
        }

        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deletePo',data)
                            .then(()=>{
                                store.dispatch('fetchPurchaseOrder')
                            })
                            .then(()=>{
                                poList = ref(store.getters.poList)
                                 filter.value = 'asd'
                            })
                            
                        }
                    })
                }
                filter.value = 's'
}

const showScan=(val)=>{
    window.open(val.povFile);
    window.open(val.srvFile);
}

const restrict = computed(()=>{
    if (!userStore ) {
        return true
    }else if (userRole.value!=='MASTER_STORE_MANAGER') {
        return true
    }
    else{
        return false
    }
})

</script>



<style lang="postcss">
    
</style>