import { createRouter, createWebHistory } from 'vue-router'
import SignUp from '../views/SignUp/SignUp.vue'
import MainContent from "../views/MainContent.vue"
import Verification from "../views/SignUp/Verification.vue"
import EmailEntry from "../views/SignUp/EmailEntry.vue"
import ResetPassword from "../views/SignUp/ResetPassword.vue"

const routes = [
  {
    path: '/',
    name: 'Login',
    component: SignUp,
  
  },
  {
    path: '/Home',
    name: 'Home',
    component: MainContent
  },
  {
    path: '/Verification/:token',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/ResetPassword/:email',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
     path: '/EmailEntry',
     name: 'EmailEntry',
     component: EmailEntry
      },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
