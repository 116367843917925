<template>
    <div class="grid grid-cols-3 ">
        <div class="col-span-1">    </div>
        <div class="h-full mt-20 ">
            <img :src="dashboardLogo" alt="Tsemex_brand" class="h-52 mx-auto">
            <h1 class="text-3xl font-bold text-center">Inventory Management System</h1>
            <div class="h-1.5 w-56 rounded-full mt-4 mx-auto bg-gold_yellow"></div>
            <form class="grid col-span-1 mt-16 ml-20">
                <h3 class="text-[18px] font-bold">SIGN IN</h3>
                <input type="email" v-model="userEmail" placeholder="Your email..." class="inputbox p-2.5 my-3">
                <div class=" relative mt-2 flex">
                    <input :type="showPassword" v-model="userPassword" placeholder="Password..." class="inputbox p-2.5  ">
                    <p v-if="showPassword === 'password'" @click.prevent="changeshowpassword" class="text-gray-500 cursor-pointer font-medium absolute inset-y-0 inset-x-64  flex items-center">SHOW</p>
                    <p v-else @click.prevent="changeshowpassword" class="text-gray-500 cursor-pointer font-medium absolute inset-y-0 inset-x-64  flex items-center">HIDE</p>   
                </div>
                <div class="flex ">
                    <button v-if="!isLoading" :disabled="activate" @click.prevent="Signup" class="submitbutton w-[108px]  mt-10">Sign In</button>
                    <button v-else :disabled="true"  class="submitbutton w-[108px]  mt-10 flex justify-center align-middle">
                       <Spinner/>
                    </button>
                    <h6 class="text-[14px] mt-12 ml-14">Forgot password? <span @click.prevent="Reset" class="text-nav_blue font-bold cursor-pointer">Reset</span></h6>
                </div>
            </form>
        </div>
        <div class="col-span-1">    </div>
        
    </div>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import dashboardLogo from "../../assets/dashboardLogo.png"
import Spinner from "../../components/Spinner.vue"
import { computed } from "@vue/runtime-core"
import { useStore } from "vuex"
import { useRouter} from 'vue-router'


const router = useRouter()

const isLoading = ref(false);
const showPassword = ref('password')
const userEmail = ref('')
const userPassword = ref('')
const store = useStore()

const activate = computed(()=>{
    if(!userEmail.value || !userPassword.value){
        return true;
    }else{
        return false;
    }
})

const Reset =()=>{
    router.push('/EmailEntry')
}
const Signup =()=>{
    isLoading.value = true;
    store.dispatch('SignUp',{email:userEmail.value.toLowerCase(),password:userPassword.value})
    .then(() => {
    isLoading.value = false;
    })
    .catch((error) => {
        console.error('Error during SignUp:', error);
        isLoading.value = false;
    });
}
const changeshowpassword =()=>{
    if (showPassword.value === 'text') {
        showPassword.value = 'password'
    }else{

        showPassword.value = 'text'
    }
}

</script>
<style lang="">

    
</style>