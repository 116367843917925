<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <div class="mx-7">
        <div class="flex justify-between">
        <div class=" font-black text-[30px] mt-16 ">BIN CARD
            <div class="h-2 w-[80px] rounded-full bg-gold_yellow "></div>
        </div>
        <div class="flex  ml-auto justify-end ">
           
            <div>
                <div class="flex items-center  space-x-3 " :class="{' mr-[20px]':userStore}">
                     <div class=" font-bold text-[15px] mr-9 "> FILTER BY
                        <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                    </div>
                   
                    <div class="space-y-3 mb-14 my-1">
                        <DropDown v-if="userCompany==='-'" placeholderp="Company" :items="staticList.company" displayable="name" @selected="(selected)=>setcompany(selected)"  width="false" class="mr-20"/>
                        <input v-else type="text" v-model="userCompany" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">        
                        <DropDown v-if="userCompany==='-'" placeholderp="Stores" :items="staticList.store" displayable="storeName" @selected="(selected)=>setstore(selected)"  width="false"/>
                        <input v-else type="text" v-model="userStore.storeName" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">   
                    </div>     
                </div>
                
            </div>
        </div>
        </div>
        <!--   input fields  -->
        <div class="mt-10  flex">
                    <div class="mr-">
                        <div class="">
                            <label for="Item ID" class="text-xs block mb-1 text-gray-500">Item ID</label>
                            <input type="text" v-model="itemId" readonly  id="Item ID" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Part #" class="text-xs block mb-1 text-gray-500">Part #</label>
                            <input type="text" v-model="partNumber" readonly  id="Part #" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Item name" class="text-xs block mb-1 text-gray-500">Item name</label>
                            <input type="text" v-model="itemName" readonly  id="Item name" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="Category" class="text-xs block mb-1 text-gray-500">Category</label>
                            <input type="text" v-model="category" readonly  id="Category" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Sub-category" class="text-xs block mb-1 text-gray-500">Sub-category</label>
                            <input type="text" v-model="subcategory" readonly  id="Sub-category" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Unit of measure" class="text-xs block mb-1 text-gray-500">Unit of measure</label>
                            <input type="text" v-model="uom" readonly  id="Unit of measure" class="inputbox  mb-3">
                        </div>
                        
                    </div>
                   <div class="ml-10 w-full">
                       <TableView  v-if="tableLoaded" :key="filter" :headerList="Headers" exportNeeded='false' :itemlist="itemTransaction" class="w-full px-4 "/>
                    <div v-else class="flex justify-center mt-24">
                        <Spinner/>
                    </div>
                    </div>
                   
                </div>
    </div>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import HTTP from "../../Axios/Axios";
import { computed, onBeforeMount } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const props = defineProps({
 currentItem:Object
})

onBeforeMount(() => {
    getItemBinCard()
})

let filter = ref(new Date())
const itemId = ref()
const partNumber = ref()
const itemName = ref()
const category = ref()
const subcategory = ref()
const uom = ref()

const selectedCompany = ref('')
const selectedStore = ref('')

const store = useStore()
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))

const userRole =  computed(() =>(store.getters.USER_ROLE))
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userCompany =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_COMPANY)))
const userCompanyId =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_COMPANY_ID)))

const itemTransaction =ref()
const tableLoaded =ref(false)
const toggledatepicker = ref(false)

const isLoading = ref(false);

const getItemBinCard = async() =>{

    const filterDate =ref(
        {
            id:props.currentItem.id,
            store:selectedStore===''?userStore.value.id:selectedStore.value.id,
            company:selectedCompany===''?userCompany.value==='-'?null:userCompanyId.value.id:selectedCompany.value.id
        }
    )
    isLoading.value = true;
    const resp =await HTTP.post('inventory/binCard',filterDate.value)
    .then((resp)=>{
        itemTransaction.value = resp.data.binCard
        itemId.value =resp.data.item.id
        partNumber.value = resp.data.item.partNumber
        itemName.value = resp.data.item.itemName
        category.value = resp.data.item.category.name
        subcategory.value = resp.data.item.subcategory.name
        uom.value = resp.data.item.uom.abbreviation 
        isLoading.value = false;
    }).then((resp)=>{
        tableLoaded.value= true
        filter.value = new Date()
    })
    .catch((error) => {
        isLoading.value = false;
    });
       

       
}

const setcompany=(val)=>{
    selectedCompany.value = val 
    getItemBinCard()
}
const setstore=(val)=>{
    selectedStore.value = val 
    getItemBinCard()
}

const Headers = [
  { text: "Date", value: "date" , sortable: true },
  { text: "Reference", value: "reference"},
  { text: "Recived qty", value: "receivedQty"},
  { text: "Issued qty", value: "issuedQty"},
  { text: "Balance", value: "balanceAtThis"},
];



</script>

<style lang="postcss">


</style>