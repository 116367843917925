<template>
    <div>

    <!-- backarrow -->
    <div class="flex items-center ml-3 mt-5">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5 cursor-pointer" @click="$emit('close')">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer " @click="$emit('close')"> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">ADD ITEM
            <div class="h-2 w-[80px] rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{newid}}</p>
                <p class="text-[9px]  text-gold_yellow ">~ Item ID</p>
            </div>
           
            <div class="flex w-[750px]   mt-10 ">
                <div class="grid col-span-1 gap-3">
                    <input type="text" v-model="partnumber" placeholder="Part number..." class="inputbox nav_blue mr-10">
                    <drop-down placeholderp="Category..." :items="staticList.category" displayable="name" @selected="(selected)=>setCatagory(selected)" />
                    <drop-down placeholderp="Unit of measure..." :items="staticList.uom" displayable="abbreviation" @selected="(selected)=>setUom(selected)" />
                    <drop-down placeholderp="Item type..." :items="itemType" displayable="name" @selected="(selected)=>setType(selected)" />
                </div>
                <div>
                    <input type="text" v-model="name" placeholder="Item name..." class="inputbox nav_blue mr-10">
                    <drop-down :key="selectedCatagId"  placeholderp="Sub-category..." :items="subcatagoryList" displayable="name" @selected="(selected)=>setSubcatagory(selected)" class="my-3" />
                    <div class=" relative inputbox  ">
                            <img :src="attachment" alt="attachment" @click="$refs.invoicescanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="itemspec?itemspec.name:''" @click="openImage(grvscan)" disabled  placeholder="Attach item spec..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="invoicescanfile" accept=".pdf,image/*" @change="uploadinvoice" style="display: none" />   
                    </div>
                    <p class="text-[10px] mt-0.5 text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                </div>
            </div>
        </div>
						
        <div class="mt-20">
            <button v-if="!isLoading" :disabled="activate" @click.prevent="submitItem" class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import Spinner from "../../components/Spinner.vue"
import attachment from "../../assets/attachment.svg"
import { useStore } from "vuex"

const close = defineEmits("close")

const store = useStore()
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
staticList.value.category.length>1 &&  staticList.value.category[0].name==='ALL'?staticList.value.category.shift():null


const partnumber = ref()
const catagory = ref()
const subcatagory = ref()
const unitmeasure = ref()
const type = ref()
const name = ref()
const itemspec = ref()

const isLoading = ref(false); 

const selectedCatagId = ref('')
 
const subcatagoryList = computed(()=>{
    return (staticList.value.subcategory.filter((sub)=>sub.categoryId === selectedCatagId.value))
})
 const itemType = [
    { id: 1,name:"Multiple time use", value: 'MULTIPLE_USE' },
    { id: 2,name:"One time use", value: 'ONE_TIME_USE' },
  ]


  
// -------creating new item id--------
    const itemId = ref()
    const newItem =  computed(() =>JSON.parse(JSON.stringify(store.getters.newItem)))
    let newid = Number(newItem.value)+1
    newid= 'IID/' + newid;

    // if (newid<10) {
    //     itemId.value = ('IID/000'+newid)
    // }else if(newid=>10 && newid<100) {
    //     itemId.value=('IID/00'+newid)
    // }else{
    //     itemId.value=('IID/'+newid)
    // }
    

const activate = computed(()=>{
    if(!catagory.value || !subcatagory.value || !unitmeasure.value || !type.value|| !itemspec.value|| !name.value || !partnumber.value){
        return true;
    }else{
        return false;
    }
})
const setCatagory=(val)=>{
    selectedCatagId.value=val.id
    catagory.value = val.id 
}
const setUom=(val)=>{
    unitmeasure.value = val.id 
}
const setType=(val)=>{
    type.value = val.value 
}
const setSubcatagory=(val)=>{
    subcatagory.value = val.id 
    
}
const uploadinvoice =(event)=>{ 
 itemspec.value =  event.target.files[0]
}

const openImage=(val)=>{
    window.open(val, '_blank');
}
const submitItem = async (event) => {
    const formData = new FormData();
    itemspec.value && formData.append("ITEMSPECIFICATION", itemspec.value);
    newid && formData.append("id", newid);
    partnumber.value ? formData.append("partNumber", partnumber.value): formData.append("partNumber", '');
    catagory.value && formData.append("category", catagory.value);
    subcatagory.value && formData.append("subcategory", subcatagory.value);
    unitmeasure.value && formData.append("uom", unitmeasure.value);
    type.value && formData.append("itemType", type.value);
    name.value && formData.append("itemName", name.value);
    
    isLoading.value = true;
   store.dispatch('addItem',{formData})
   .then(()=>{
    store.dispatch('fetchItems')
    isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });

}

</script>

