<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">RECEIVE GOODS
            <div class="h-2 w-32 rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <DropDown placeholderp="ISTV doc #..." :items="stvToAccept" displayable="istvNumber" @selected="(selected)=>setIstv(selected,index)"  class="mt-10 mb-6 "/>
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                       
                        <input type="text" v-model="grvnum"   placeholder="GRV doc #..." required class="inputbox  mb-3 ">
                        <input type="text" v-model="issuedby"   placeholder="Issued by..." required class="inputbox  mb-3">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.grvfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="grvscan?grvscan.name:''" disabled  placeholder="Attach GRV doc scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="grvfile" accept=".pdf,image/*" @change="uploadgrv" style="display: none" /> 
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                        
                       
                       <div class=" relative inputbox  mb-3">
                           <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                           <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="deliverydate" :upperLimit="new Date()" placeholder="Delivery date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                        <input type="text" v-model="recipient"   placeholder="Recipient..." required class="inputbox  mb-3">
                    </div>
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in itemList" :key="item" class="mb-2"> 
            <div class="flex">
                <input type="text" v-model="allItems[index].itemId" readonly  placeholder="Item ID..." required class="inputbox      mr-3 w-36">
                <input type="text" v-model="alldesc[index].uom"  readonly placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="text" v-model="allItems[index].balance" readonly  placeholder="Qty..." required class="inputbox ml-3 mr-3 w-36">
                <input type="text" v-model="allItems[index].remark"   placeholder="Remark..." class="inputbox  mr-3 w-56">
            </div>
                 <p class="text-[12px] block">{{alldesc[index].desc}}</p>
        </div>

    <div class="mt-20">
            <button v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="submitbutton">submit</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
        </div>
    </form>    
      
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Datepicker from 'vue3-datepicker'
import DropDown from "../../components/DropDown.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const close = defineEmits("close")

const store = useStore()
const stvToAccept =  computed(() =>JSON.parse(JSON.stringify(store.getters.stvToAccept)))


const issuedby = ref()
const grvscan = ref()
const recipient = ref()
const grvnum = ref()
const istvnum = ref()
const deliverydate = ref()

const itemId = ref()
const uom = ref()
const balance = ref()
const remark = ref()
const isLoading = ref(false);

const activate = computed(()=>{
    if(!issuedby.value || !grvscan.value || !recipient.value ||
     !grvnum.value|| !istvnum.value|| !deliverydate.value){
        return true;
    }else{
        return false;
    }
})

const allItems = ref([
    {itemId:'',balance:'',remark:''}
])

const alldesc = ref([
    {desc:'',uom:''}
])

const setUom =(selected,index)=>{
    alldesc.value[index].uom = selected.name
}

const itemList =computed(()=>{
   return allItems.value.length===0 ?allItems.value.length + 1:allItems.value.length
})
    
const addItemInputs = ()=>{
    allItems.value.push({itemId:'',balance:'',remark:''})
    alldesc.value.push({desc:'',uom:''})
}
const deductList=(i)=> {
  allItems.value = allItems.value.filter((_, index) => index !== i)
  console.log(i,allItems.value);
}

const uploadgrv =(event)=>{ 
 grvscan.value =  event.target.files[0]
 console.log(grvscan.value);
}

//   --------------------------
  
const setIstv =(selected)=>{
    
    if (istvnum.value) {
        allItems.value=[]
        alldesc.value=[]
        addItemInputs();
    }
    
     for (let index = 0; index < selected.IstvTransaction.length; index++) {
        // const element = array[index];
        allItems.value[index].itemId = selected.IstvTransaction[index].item.stock.item.id
        alldesc.value[index].uom = selected.IstvTransaction[index].item.stock.item.uom.abbreviation
        allItems.value[index].balance = selected.IstvTransaction[index].item.issuedQty
        allItems.value[index].remark = selected.IstvTransaction[index].item.remark
        alldesc.value[index].desc = selected.IstvTransaction[index].item.stock.item.itemName
        index < selected.IstvTransaction.length-1 && addItemInputs(index);
    }

istvnum.value = selected.id
   
}

//   --------------------------

const submit =()=>{
    const formData = new FormData();
    issuedby.value && formData.append("issuedBy", issuedby.value);
    grvscan.value && formData.append("GRV", grvscan.value);
    recipient.value && formData.append("recipient", recipient.value);
    grvnum.value && formData.append("grvNumber", grvnum.value);
    istvnum.value && formData.append("istvId", istvnum.value);
    deliverydate.value && formData.append("deliveredDate", deliverydate.value);
    allItems.value && formData.append("items",(JSON.stringify(allItems.value)));

    isLoading.value = true;
    store.dispatch('addStoreReciving',
        formData,
    )
    .then(()=>{
          store.dispatch('fetchPending')
          store.dispatch('fetchInventory')
          isLoading.value = false;
    })  
    .then(()=>{ 
            close('close')
        }) 
        .catch((error) => {
            isLoading.value = false;
        }); 

}

</script>

<style lang="postcss">


</style>