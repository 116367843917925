<template>
    <div class="grid grid-cols-3 ">
        <div class="col-span-1">    </div>
        <div class="h-full mt-20 ">
            <img :src="dashboardLogo" alt="Tsemex_brand" class="h-52 mx-auto">
            <h1 class="text-3xl font-bold text-center">Inventory Management System</h1>
            <div class="h-1.5 w-56 rounded-full mt-4 mx-auto bg-gold_yellow"></div>
            <form class="grid col-span-1 mt-16 ml-20">
                <h3 class="text-[18px] font-bold">FORGOT PASSWORD?</h3>
                <h6 class="text-gray-500 mb-4">Please enter your email to get a password reset link.</h6>
               
                <input type="email" v-model="userEmail" placeholder="Enter your email…" class="inputbox p-2.5 mt-5">
               
                <div class="flex justify-between w-3/4">
                    <button :disabled="activate" @click.prevent="SendEmail" class="submitbutton w-[108px]  mt-10">Reset</button>
                </div>
            </form>
        </div>
        <div class="col-span-1">    </div>
        
    </div>
</template>
<script setup>
import { ref } from "@vue/reactivity"
import dashboardLogo from "../../assets/dashboardLogo.png"
import { computed, onBeforeMount } from "@vue/runtime-core"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex"


const router = useRouter()
const userEmail = ref('')
const store = useStore()

const activate = computed(()=>{
    if(!userEmail.value){
        return true;
    }else{
        return false;
    }
})

const SendEmail =()=>{
    store.dispatch('SendResetEmail',{email:userEmail.value.toLowerCase()})
}


</script>
<style lang="">

    
</style>