<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">TRANSFER GOODS
            <div class="h-2 w-[138px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <input type="text" v-model="istvn"   placeholder="ISTV doc #..." required class="inputbox nav_blue mt-10 mb-6">
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                        <input type="text" v-model="transferFrom"   placeholder="Transfer from..." readonly required class="inputbox  mb-3">
                        <!-- <DropDown placeholderp="Transfer from..." :items="staticList.store" displayable="storeName" @selected="(selected)=>setFrom(selected,index)"  class="mr-20 mb-3 "/> -->
                        <input type="text" v-model="truckPlate"   placeholder="Truck plate #..." required class="inputbox  mb-3 ">
                        <input type="text" v-model="invoicenum"   placeholder="Invoice #..." required class="inputbox  mb-3">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.approvalfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="approvalscan?approvalscan.name:''" disabled  placeholder="Attach approval doc scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="approvalfile" accept=".pdf,image/*" @change="uploadapproval" style="display: none" />
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                        <DropDown placeholderp="Transfer to..." :items="staticList" displayable="storeName" @selected="(selected)=>setTo(selected,index)"  class="mr-20 mb-3 "/>
                       
                       <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="transferDate" :upperLimit="new Date()" placeholder="Delivery date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>   
                        </div>
                        <input type="text" v-model="srnum"   placeholder="S/R #..." required class="inputbox  mb-3">
                       <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.istvfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="istvscan?istvscan.name:''" disabled  placeholder="Attach ISTV scan..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="istvfile" accept=".pdf,image/*" @change="uploadistv" style="display: none" />
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in itemList" :key="item" class="mb-2"> 
            <div class="flex">
                <!-- <div class=" relative inputbox  w-40 mr-2">
                    <img :src="magnifyingglass" alt="magnifyingglass" class="absolute right-3 h-3 mt-1.5">
                    <input type="text" v-model="allItems[index].itemId"  @input="searchItem(index)"   placeholder="Item ID..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                </div> -->
                <ItemSearch  :items="staticItemList" :defaultValue="allItems[index].itemId" displayable="name" @selected="(selected)=>searchItem(selected,index)"  width="false" class="mr-24 mx-1 "/>
                <input type="text" v-model="alldesc[index].uom"  readonly placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="number" v-model="allItems[index].balance"  @input="(event)=>checkBalance(index,event)"  placeholder="Qty..." required class="inputbox ml-3 mr-3 w-36">
                <input type="text" v-model="allItems[index].remark"   placeholder="Remark..." class="inputbox  mr-3 w-56">
                <button v-if="index>0 || itemList!==1" @click="deductList(index)" class="bg-red-500 text-white text-3xl w-9 h-9 mx-2 items-center  rounded-full ">-</button>
                <button v-if="index===itemList-1" @click="addItemInputs" class=" text-white text-3xl w-9 h-9 mx-2 items-center  pb-3 rounded-full "
                :class="{'bg-nav_blue':itemList!==1,'bg-[#8F8F8F]':itemList===1}">+</button>
            </div>
            <div>
                <p class="text-[12px]">{{alldesc[index].desc}}</p>
                <p class="-mt-3 ml-[345px] text-[12px] font-semibold text-green-400">{{alldesc[index].balance}}</p>
            </div> 
        </div>

    <div class="mt-20">
            <button v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="submitbutton">submit</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
        </div>
    </form>    
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Datepicker from 'vue3-datepicker'
import ItemSearch from "@/components/ItemSearch.vue";
import DropDown from "../../components/DropDown.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const close = defineEmits("close")

const store = useStore()
const staticItemList =  ref(JSON.parse(JSON.stringify(store.getters.inventoryList)))
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userCompany =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_COMPANY)))
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)).store.filter(store => store.storeName !==userStore.value.storeName && store.company.name === userCompany.value))
const istvn = ref()
const invoicenum = ref()
const transferFrom = ref(userStore.value.storeName)
const transferTo = ref()
const approvalscan = ref()
const srnum = ref()
const istvscan = ref()
const truckPlate = ref()
const transferDate = ref()


const itemId = ref()
const uom = ref()
const qty = ref()
const remark = ref()
const isLoading = ref(false);

const allItems = ref([
    {itemId:'',balance:'',remark:''}
])

const alldesc = ref([
    {desc:'',uom:'',balance:null}
])

const setUom =(selected,index)=>{
    alldesc.value[index].uom = selected.name
}

const setFrom =(selected,index)=>{
    transferFrom.value = selected.storeName
    
}

const setTo =(selected,index)=>{
    transferTo.value = selected    
}


const checkBalance =(index,event)=>{
    if((Number(allItems.value[index].balance)>=0)&&(Number(allItems.value[index].balance)<=Number(alldesc.value[index].balance))){
        //  allItems.value[index].balance = Number(event.data);
    }else{
        allItems.value[index].balance = null
    }
}

const itemList =computed(()=>{
   return allItems.value.length===0 ?allItems.value.length + 1:allItems.value.length
})
    
const addItemInputs = ()=>{
    allItems.value.push({itemId:'',balance:'',remark:''})
    alldesc.value.push({desc:'',uom:'',balance:null})
}

const deductList=(i)=> {
  allItems.value = allItems.value.filter((_, index) => index !== i)
  alldesc.value = alldesc.value.filter((_, index) => index !== i)
}

const uploadapproval =(event)=>{ 
 approvalscan.value =  event.target.files[0]
}

const uploadistv =(event)=>{ 
 istvscan.value =  event.target.files[0]
}

const isListEmpty = computed(()=>{
         for (let index = 0; index < itemList.value; index++) {
            if( !allItems.value[index].itemId||! alldesc.value[index].uom||! allItems.value[index].balance){
                return true
            }
            
        }
})

// to disable button checking method
const activate = computed(()=>{
    if(!istvn.value || !invoicenum.value  || !approvalscan.value || !srnum.value || !istvscan.value||
     !truckPlate.value|| !transferDate.value|| !transferFrom.value|| !transferTo.value||isListEmpty.value){
        return true;
    }else{
        return false;
    }

})

const searchItem=(selected,val)=>{
    allItems.value[val].itemId =selected
    //  staticItemList.value =  JSON.parse(JSON.stringify(store.getters.inventoryList))
    // for (let index = val-1; index >= 0; index--) {
    //    staticItemList.value = staticItemList.value.filter(item=>item.id.toLowerCase()!==allItems.value[index].itemId.toLowerCase())    
    // }

    for (let index = 0; index < staticItemList.value.length; index++) {

           if((staticItemList.value[index].id).toLowerCase() ===(allItems.value[val].itemId).toLowerCase()) {
                 alldesc.value[val].uom = staticItemList.value[index].uom
                 alldesc.value[val].desc = staticItemList.value[index].name
                 alldesc.value[val].balance = staticItemList.value[index].balance
                 staticItemList.value = staticItemList.value.filter(item=>item.id.toLowerCase()!==allItems.value[val].itemId.toLowerCase())    
                 return 
         }else{
                 alldesc.value[val].uom = ''
                 alldesc.value[val].desc = ''
                 alldesc.value[val].balance = ''
         }  
    } 
}

const submit =()=>{
    const formData = new FormData();
    istvn.value && formData.append("istvNumber", istvn.value);
    invoicenum.value && formData.append("invoiceNumber", invoicenum.value);
    approvalscan.value && formData.append("APPROVAL", approvalscan.value);
    srnum.value && formData.append("sr", srnum.value);
    istvscan.value && formData.append("ISTV", istvscan.value);
    truckPlate.value && formData.append("truckPlate", truckPlate.value);
    transferDate.value && formData.append("transferDate", transferDate.value);
    transferFrom.value && formData.append("transferFrom", userStore.value.storeName);
    transferTo.value && formData.append("transferTo", transferTo.value.id);
    allItems.value && formData.append("items",(JSON.stringify(allItems.value)));

    isLoading.value = true;
    store.dispatch('addStoreTransfer',
        formData,
     ) 
      .then(()=>{ 
          store.dispatch('fetchStoreTransfer')
          store.dispatch('fetchInventory')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      }) 
      .catch((error) => {
            isLoading.value = false;
        });
        

}

</script>

<style lang="postcss">


</style>