<template>
    <div class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">STORE DETAILS
            <div class="h-2 w-[120px] rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            
           
            <div class="grid grid-cols-3 mt-10 ">
                <div class="grid col-span-1 gap-3">
                    <input type="text" v-model="sname" placeholder="Store name..." class="inputbox nav_blue mr-10">
                    <input type="text" v-model="address" placeholder="store address..." class="inputbox mr-10">
                    <input type="text" v-model="latitude" placeholder="Latitude..." class="inputbox">
                    <input type="text" v-model="longitude" placeholder="Longitude..." class="inputbox nav_blue mr-10">
                    
                    <div class=" relative inputbox">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-4 h-3.5 mt-1.5 picker">
                            <datepicker  ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="openingdate" :upperLimit="new Date()" placeholder="Opening date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" :class="{'hidden':!openingdate}" class="picker bg-main_gray w-full outline-none "/>
                            <input v-if="!openingdate" type="text" disabled :value="olddate" class="bg-main_gray">
                    </div>
                    <div class="relative">
                        <!-- <drop-down placeholderp="parentCompany" :items="storeList.company" displayable="name" @selected="(selected)=>setparentComapny(selected)" /> -->
                        <input v-if="!parentCompany" type="text" disabled :value="oldcompany" class="inputbox">
                    </div>
                </div>
                <div class="grid col-span-1 ">
                     <div class="relative">
                        <drop-down placeholderp="Store manager..."  :items="itemlist" displayable="fullName" @selected="(selected)=>setstoreManager(selected)" />
                        <input v-if="!storeManager" type="text" disabled :value="olduser" class="bg-main_gray absolute top-2.5 left-4 text-[15px] w-64">    
                    </div>
                    <div v-if="storeManager || olduser " class="mt-10 mr-10 flex flex-col items-center ">
                         <img class="h-28 w-28 mb-3  rounded-full " :src="profileImg!==null?profileImg:logo" alt="profile">
                        <p>{{phoneNumber}}</p>    
                        <p class="text-nav_blue ">{{useremail}}</p>    
                    </div>
                </div>
                <!-- <div class="grid col-span-1 ">
                     <div class="relative">
                        <drop-down placeholderp="Project manager..."  :items="projectManList" displayable="fullName" @selected="(selected)=>setprojectManager(selected)" />
                        <input v-if="!projectManager" type="text" disabled :value="PMolduser" class="bg-main_gray absolute top-2.5 left-4 text-[15px] w-64">    
                    </div>
                    <div v-if="PMuseremail" class=" mr-10 mt-10 flex flex-col items-center ">
                         <img  class="h-28 w-28 mb-3  rounded-full " :src="PMprofileImg!==null?PMprofileImg:logo" alt="profile">
                        <p>{{PMphoneNumber}}</p>    
                        <p class="text-nav_blue ">{{PMuseremail}}</p>    
                    </div>
                </div> -->
            </div>
        </div>
        
						
        <div class="mt-20">
            <button :disabled="activate" @click.prevent="submitStore" class="submitbutton">Update</button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import { useStore } from "vuex"
import HTTP from "../../Axios/Axios";
import Datepicker from 'vue3-datepicker'
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import logo from "../../assets/Tsemex_brand.png"
import DropDown from "../../components/DropDown.vue"

const close = defineEmits("close")
const props = defineProps({
  olddata:Object,
  prevCompanyId:Object,
}) 

const sname = ref()
const address = ref()
const longitude = ref()
const latitude = ref()
const openingdate = ref()
const parentCompany = ref()
const storeManager = ref()
// const projectManager = ref()
const olddate = ref()
const oldcompany = ref()
const oldcompanyId = ref()
const olduser = ref()
const olduserId = ref()
// const PMolduser = ref()
// const PMolduserId = ref()
const isMaster = ref()

const phoneNumber = ref()
const useremail = ref()
const profileImg = ref('')
// const PMphoneNumber = ref()
// const PMuseremail = ref()
// const PMprofileImg = ref('')

const allData = ref()
const id = props.olddata;
const prevCompanyId = props.prevCompanyId;
const store = useStore()
let itemlist = ref()
// let projectManList = ref()

const getStoreDetail = async() =>{
    const resp =await HTTP.post('store/getOne',{id:id})
    allData.value = resp.data
    sname.value = allData.value.storeName
    address.value = allData.value.storeAddress
    longitude.value = allData.value.longitude
    latitude.value = allData.value.latitude
    olddate.value = new Date (allData.value.openingDAte).getDate()+'/'+ (Number(new Date (allData.value.openingDAte).getMonth())+1 )+'/'+new Date (allData.value.openingDAte).getFullYear()
    oldcompany.value = allData.value.company.name
    oldcompanyId.value = allData.value.company.id
    profileImg.value = allData.value.user.img
    olduser.value = allData.value.user.fullName
    olduserId.value = allData.value.user.id
    phoneNumber.value = allData.value.user.phoneNumber
    useremail.value = allData.value.user.email
    isMaster.value = allData.value.isMasterStore

}
onBeforeMount(() => {
    getStoreDetail()
}),

itemlist =  computed(() =>JSON.parse(JSON.stringify(store.getters.usersList)).filter(user =>user.companyId === prevCompanyId && user.role !=="MIDDLE_MANAGEMENT" && user.role !=="UPPER_MANAGEMENT" &&user.role !=="SYSTEM_ADMIN"&&user.role !=="PROJECT_MANAGER" && user.Store.length <1))

const storeList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
const itemlistLength =  computed(() =>JSON.parse(JSON.stringify(store.getters.usersList)).length)


const activate = computed(()=>{
    if (allData.value) {
            if(allData.value.storeName!==sname.value ||
            storeManager.value?allData.value.user.fullName!==storeManager.value:false ||
            parentCompany.value?allData.value.company.name!==parentCompany.value:false ||
            allData.value.storeAddress!==address.value ||
            allData.value.longitude!==longitude.value ||
            allData.value.latitude!==latitude.value ||
            // projectManager.value ||
            openingdate.value?allData.value.openingDAte!==openingdate.value:false
            ){
            return false;
        }else{
            return true;
        }   

    } else {
        return true
        
    }
    
})


const setstoreManager=(val)=>{
    storeManager.value = val 
    phoneNumber.value=storeManager.value.phoneNumber
    useremail.value=storeManager.value.email
    profileImg.value=storeManager.value.img
    }

// const setprojectManager=(val)=>{
//     projectManager.value = val 
//     console.log(val);
//     PMphoneNumber.value=projectManager.value.phoneNumber
//     PMuseremail.value=projectManager.value.email
//     PMprofileImg.value=projectManager.value.img
//     }
// ------
const submitStore=()=>{
    if (allData.value) {
        
    isLoading.value = true;
    store.dispatch('updateStore',
    {
    id:id,    
    storeName:sname.value,
    storeAddress:address.value,
    latitude:latitude.value,
    longitude:longitude.value,
    companyId:parentCompany.value?parentCompany.value.id:oldcompanyId.value,
    storeManagerId:storeManager.value?storeManager.value.id:allData.value.user.id,
    // projectManagerId:projectManager.value?projectManager.value.id:allData.value.projectManagerId,
    openingDate:openingdate.value?openingdate.value:allData.value.openingDAte,
    })
    .then(()=>{ 
          store.dispatch('fetchUsers')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });
}}

</script>

<style lang="postcss">


</style>