<template>
    <nav class="bg-nav_blue text-white ">
       <img :src="logo" alt="logo" class="mx-auto ">
       <!-- profile -->
       <div class=" flex justify-between items-center">
            <div class="flex items-center ml-3">
                <img  class="h-9 w-9 rounded-full mr-3" :src="userImg!==null?userImg:logo" alt="profile">
                <div class=" ">
                    <p class="text-[15px]" >{{userName}}</p>
                    <p class="text-[8px] font-semibold text-stone-300" >{{ userRole }}</p>
                </div>
            </div>
            <button @click.prevent="signout" class="flex items-center gap-1.5 m-1 mt-2 text-[9px] font-semibold text-gold_yellow underline underline-offset-4">
                LOGOUT
                <img :src="arrowforward" alt="arrowforward" class="h-[7px] mt-0.5 ">    
            </button>
       </div>
       <!-- navigation -->
       <div class="mt-6  ">
            <div v-for="(item,index) in listitem" :key="item.name" @click="selecttab(item.name,item.navigate)" class="flex  items-center cursor-pointer  ">
              <div class="flex items-center space-x-5 my-0.5 py-1.5 w-full"
                    :class="{'bg-light_gray':item.name ===selectednav,'hidden':allowAccess(item.name)}">
               <img :src="item.name !==selectednav? item.icon :item.sicon" alt="img" class="h-4 w-4 ml-5 "  :class="{'h-5 w-5':index>0,' w-5 ':index===0}">
               <p class=" text-[15px]"
                :class="{'text-gold_yellow font-normal':item.name ===selectednav}"> {{item.name}}</p>
               </div>
            </div>
       </div>
      
    </nav>
</template>


<script setup>
import  account  from '../assets/account.svg'
import  dashboard  from '../assets/dashboard.svg'
import  catalogue  from '../assets/catalogue.svg'
import  grv  from '../assets/grv.svg'
import  inventory  from '../assets/inventory.svg'
import  po  from '../assets/po.svg'
import  requisition  from '../assets/requisition.svg'
import  returned  from '../assets/return.svg'
import  storeicon  from '../assets/store.svg'
import  susers  from '../assets/susers.svg'
import  saccount  from '../assets/saccount.svg'
import  sdashboard  from '../assets/sdashboard.svg'
import  scatalogue  from '../assets/scatalogue.svg'
import  sgrv  from '../assets/sgrv.svg'
import  sinventory  from '../assets/sinventory.svg'
import  spo  from '../assets/spo.svg'
import  srequisition  from '../assets/srequesition.svg'
import  sreturned  from '../assets/sreturn.svg'
import  sstore  from '../assets/sstore.svg'
import  users  from '../assets/users.svg'
import  stv  from '../assets/stv.svg'
import  sstv  from '../assets/sstv.svg'
import  logo  from '../assets/Tsemex_brand.png'
import  arrowforward  from '../assets/arrowforward.svg'
import { computed, onMounted, provide, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'


const store = useStore()
onMounted(() => {
    store.dispatch('fetchUsers')
    store.dispatch('fetchStores')
    store.dispatch('fetchStaticList')
    store.dispatch('fetchItems')
// /////////////////////////////////////  //
    store.dispatch('fetchGoodsRecived')
    store.dispatch('fetchPurchaseOrder')
    store.dispatch('fetchInventory')
    store.dispatch('fetchStoreTransfer')
    store.dispatch('fetchPending')
    store.dispatch('fetchReqisition')
    store.dispatch('fetchReturnedGoods')
    
})
    
const userName =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_NAME)))    
const userImg =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_IMG))) 
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

const allowAccess =  (name) =>{
    //  if(userRole.value ==='MASTER_STORE_MANAGER'){ 
    //    return  (name==='Users')}

     if(userRole.value ==='BRANCH_STORE_MANAGER') {
       return  (name==='Users'||name==='Stores' || name === 'Good Recived')
     }
     if(userRole.value ==='PROJECT_MANAGER') {
       return  ( name!=='Purchase Order'&& name!=='Dashboard'&& name!=='Inventory'&& name!=='Catalogue')
     }
     
     } 
     
const listitem = [
    {name:"Dashboard", icon: dashboard , sicon:sdashboard, navigate:"Dashboard" },
    {name:"Inventory", icon:inventory, sicon:sinventory , navigate:"Inventory"},
    {name:"Good Recived", icon:grv, sicon:sgrv , navigate:"GoodsRecived"},
    {name:"Requisitions", icon:requisition, sicon:srequisition , navigate:"Requisitions"},
    {name:"Inter Store Transfers", icon:stv, sicon:sstv , navigate:"Transfers"},
    {name:"Returned Goods", icon:returned, sicon:sreturned , navigate:"ReturnedGoods"},
    {name:"Purchase Order", icon:po, sicon:spo , navigate:"PurchaseOrder"},
    {name:"Stores", icon:storeicon, sicon:sstore , navigate:"Stores"},
    {name:"Catalogue", icon:catalogue, sicon:scatalogue , navigate:"Catalogue"},
    {name:"Users", icon:users, sicon:susers , navigate:"Users"},
    {name:"My Account", icon:account, sicon:saccount , navigate:"MyAccount"},
]

const listLength = computed(()=>{
   listitem.length;
})

const selectednav = ref('Dashboard')

const emit = defineEmits('navigation')
const selecttab=(name,navigate)=>{
    selectednav.value = name
    emit('navigation',navigate)

}
const signout=()=>{
    localStorage.removeItem('userstore');
    store.commit('Sign_Out')
}
 


</script>
