<template>
    <div class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] "> REGISTER MASTER STORE
            <div class="h-2 w-[200px] rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            
           
            <div class="grid grid-cols-3 mt-10  ">
                <div class="grid col-span-1 gap-3">
                    <drop-down placeholderp="Company..." :items="staticList" displayable="name" @selected="(selected)=>setcompany(selected)" />
                    <input type="text" v-model="sname" placeholder="Store name..." class="inputbox nav_blue mr-10">
                    <input type="text" v-model="address" placeholder="store address..." class="inputbox mr-10">
                    <input type="text" v-model="latitude" placeholder="Latitude..." class="inputbox">
                    <input type="text" v-model="longitude" placeholder="Longitude..." class="inputbox nav_blue mr-10">
                    
                    <div class=" relative inputbox">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-4 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="openingdate" :upperLimit="new Date()" placeholder="Opening date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        
                    </div>
                    
                </div>
                <div class="grid col-span-1 ">
                     
                    <drop-down :key="refreshManager" placeholderp="Store manager..." :items="itemlist" displayable="fullName" @selected="(selected)=>setstoreManager(selected)" />
                    <div v-if="storeManager" class=" mr-14 mt-10 flex flex-col items-center ">
                        <img class="h-28 w-28 mb-3  rounded-full " :src="profileImg!==null?profileImg:logo" alt="profile">
                        <p>{{phoneNumber}}</p>    
                        <p class="text-nav_blue ">{{useremail}}</p>    
                    </div>
                </div>
               <!-- <div class=" grid col-span-1 ">
                        <drop-down placeholderp="Project manager..." :items="projectManList" displayable="fullName" @selected="(selected)=>setprojectManager(selected)" />
                        <div v-if="projectManager" class="  mt-10 flex flex-col items-center ">
                            <img class="h-28 w-28 mb-3  rounded-full " :src="PMprofileImg!==null?PMprofileImg:logo" alt="profile">
                            <p>{{PMphoneNumber}}</p>    
                            <p class="text-nav_blue ">{{PMuseremail}}</p>    
                        </div>
                </div> -->
            </div>
        </div>
        

						
        <div class="mt-24">
            <button v-if="!isLoading" :disabled="activate"  @click.prevent="submitStore" class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import { useStore } from "vuex"
import Datepicker from 'vue3-datepicker'
import backarrow from "../../assets/arrowback.svg"
import logo from "../../assets/Tsemex_brand.png"
import calander from "../../assets/calander.svg"
import Spinner from "../../components/Spinner.vue"
import DropDown from "../../components/DropDown.vue"

const close = defineEmits("close")

const store = useStore()
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)).company.filter(user => user.hasMaster === false))
let itemlist =  computed(() =>JSON.parse(JSON.stringify(store.getters.usersList)).filter(user => user.role ==="MASTER_STORE_MANAGER"  && user.Store.length <1))
const itemlistLength =  computed(() =>itemlist.value.length)
// const projectManList =  computed(() =>JSON.parse(JSON.stringify(store.getters.usersList)).filter(user => user.role ==="PROJECT_MANAGER" && user.Store.length <1))
// const PMistLength =  computed(() =>projectManList.value.length)


const sname = ref()
const address = ref()
const longitude = ref()
const latitude = ref()
const openingdate = ref()
const company = ref()
const storeManager = ref()
// const projectManager = ref('')

let refreshManager = ref(1)
const isLoading = ref(false);


const setcompany=(val)=>{
    company.value = val
    itemlist =  computed(() =>JSON.parse(JSON.stringify(store.getters.usersList)).filter(user => user.role ==="MASTER_STORE_MANAGER" && user.Store.length <1 && user.company.name==val.name))
    refreshManager.value=refreshManager.value + 1
}

const activate = computed(()=>{
    if(!sname.value || !storeManager.value || !company.value || !address.value || !longitude.value || !latitude.value || !openingdate.value ){
        return true;
    }else{
        return false;
    }
})
const profileImg = ref('')
const phoneNumber = ref('')
const useremail = ref('')

const PMprofileImg = ref('')
const PMphoneNumber = ref('')
const PMuseremail = ref('')

const setstoreManager=(val)=>{
    storeManager.value = val 
    for (let index = 0; index < itemlistLength.value; index++) {
           if(itemlist.value[index].fullName===storeManager.value.fullName){
               profileImg.value=itemlist.value[index].img
                phoneNumber.value=itemlist.value[index].phoneNumber
                useremail.value=itemlist.value[index].email
            }

    }
    }
// const setprojectManager=(val)=>{
//      projectManager.value = val 
//     for (let index = 0; index < PMistLength.value; index++) {
//            if(projectManList.value[index].fullName===projectManager.value.fullName){
//                PMprofileImg.value=projectManList.value[index].img
//                 PMphoneNumber.value=projectManList.value[index].phoneNumber
//                 PMuseremail.value=projectManList.value[index].email
//             }

//     }}
// -------
const submitStore=()=>{

    isLoading.value = true;
    store.dispatch('addStore',
    {storeName:sname.value,
    storeAddress:address.value,
    latitude:latitude.value,
    longitude:longitude.value,
    // projectManagerId:projectManager.value!==''?projectManager.value.id:null,
    companyId:company.value.id,//
    storeManagerId:storeManager.value.id,
    openingDate:openingdate.value,
    isMasterStore:true}) 
    .then(()=>{ 
          store.dispatch('fetchUsers')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });
}

</script>

