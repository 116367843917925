<template >
<div v-if="!toggleIssueGoods && !toggleDetails">
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">REQUISITIONS
            <div class="h-2 w-24 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict || !userStore" :class="{'hidden':restrict ||!userStore}" class="mt-28 ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleIssueGoods=!toggleIssueGoods">
            <img :src="add" alt="add" class="h-3 mr-1.5">
           issue goods
        </button>
        
        </div>
        <!-- filter -->
        <div class="flex mt-16  ml-auto justify-end ">
           
            <div>
                <div class="flex items-center mr-[100px] space-x-3 " :class="{' mr-[20px]':userStore}">
                     <div class=" font-bold text-[15px] mr-9 "> FILTER BY
                        <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                    </div>
                    <div class=" h-10 py-2 rounded-lg px-3  bg-main_gray">
                        <img :src="calander" alt="calander" @click="toggledatepicker = true" class="h-6" > 
                        <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                    </div>
                    <div >
                        <DropDown v-if="!userStore" placeholderp="Stores" :items="staticStoreList.store" displayable="storeName" @selected="(selected)=>setstores(selected)"  width="false"/>
                        <input v-else type="text" v-model="userStore.storeName" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">    
                    </div>
                </div>
                <div class=" flex ">
                <div class="inputbox p-0  flex items-center mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll" placeholder="Search by SIV ID..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter" @displayScan="showScan" showdoc="Needed" @delete="deleteitem" @showdoc="showdocitem" @detail="detailitem" exportName="Requestion" :headerList="Headers" :itemlist="sivList"/>
 </div>       
   <IssueGoods v-if="toggleIssueGoods" @close="toggleIssueGoods=!toggleIssueGoods" class="absolute top-0 left-0  bg-white w-full"/>
   <SivDetail v-if="toggleDetails" @close="toggleDetails=!toggleDetails"  class="absolute top-0 left-0  bg-white w-full"/>

</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, watch } from "@vue/runtime-core"
import SivDetail from "./SivDetail.vue"
import DateRange from "../../components/DateRange.vue";
import DropDown from "../../components/DropDown.vue"
import TableView from "../../components/TableView.vue";
import IssueGoods from "./IssueGoods.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

const store = useStore()
onBeforeMount(() => {  
    store.dispatch('fetchReqisition')
     store.dispatch('fetchInventory')
    
})

let sivList = ref(store.getters.sivList)
let staticStoreList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
staticStoreList.value.store.unshift({ id: 0, storeName: "ALL"});
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

const Headers = [
  { text: "SIV ID", value: "sivNumber" , sortable: true },
  { text: "Requested by", value: "requestedBy"},
  { text: "Issued to", value: "issuedTo"},
  { text: "Store req.#", value: "srvNumber",sortable: true},
  { text: "Date", value: "date",sortable: true},
  { text: "Actions", value: "operation"},
];


const toggleIssueGoods = ref(false)
const toggleDetails = ref(false)
const toggledatepicker = ref(false)

watch(toggleIssueGoods, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {

        store.dispatch('fetchReqisition')
        .then(()=>{
            sivList = ref(store.getters.sivList)
            filter.value = new Date()
        })
    }
})
watch(toggleDetails, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        
        store.dispatch('fetchReqisition')
        .then(()=>{
            sivList = ref(store.getters.sivList)
            filter.value = new Date()
        })
    }
})

const filterVars = ref({store:null,init:null,end:null})
let filter = ref(1)

const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const storeval = ref()
const searchFilter = ref('')
 
const setstores=(val)=>{
    storeval.value =  val.storeName 
    filterVars.value.store = val.id
    filterAll();
}


const filterAll = ( ) =>{
    sivList = ref(store.getters.sivList)
    if (filterVars.value.store !== null  && filterVars.value.store !== 0) {
        sivList.value = sivList.value.filter((user)=>user.storeId === filterVars.value.store)   
    }
     if (filterVars.value.init !== null){

        sivList.value = sivList.value.filter((user)=>new Date(user.issuedDate) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )
    }
    if (filterVars.value.end !== null){

        sivList.value = sivList.value.filter((user)=>new Date(user.issuedDate) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
    if (searchFilter.value !== ''){

        sivList.value = sivList.value.filter((user)=>(user.sivNumber).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    
    filter.value = new Date() 

}

const detailitem=(val)=>{
        store.dispatch('getSivDetail',{id:val.id})
        .then(()=>{ 
            toggleDetails.value = !toggleDetails.value
        })
}

const deleteitem=(val)=>{
    if (userRole.value === 'MASTER_STORE_MANAGER' ||userRole.value === 'BRANCH_STORE_MANAGER'){

        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteSiv',val.id)
                            .then(()=>{
                                store.dispatch('fetchReqisition')
                            })
                            .then(()=>{
                                sivList = ref(store.getters.sivList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
    }
}

const showScan=(val)=>{
    window.open(val.sivFile);
    window.open(val.srvFile);
    window.open(val.approvalFile);
    window.open(val.gatePassFile);
}



const restrict = computed(()=>{
  
  if ( userRole.value!=='BRANCH_STORE_MANAGER'|| userRole.value!=='MASTER_STORE_MANAGER' ) {
        return false
    }
    else{
        return true
    }
     
})

</script>



<style lang="postcss">
    
</style>