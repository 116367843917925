<template>
    <div  v-if="!toggleEdit"  class="mt-5">

    <!-- backarrow -->
    <div class="flex justify-between " >
        <div class="flex justify-between items-center ml-3 cursor-pointer">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 " @click="$emit('close')"> Back</p>
        </div>
        <div class="mr-16">
          <p v-if="userRole==='MASTER_STORE_MANAGER' || userRole==='BRANCH_STORE_MANAGER'" class="flex text-yellow-400 cursor-pointer" @click.prevent="toggleEdit=true"> EDIT</p>
        </div>
    </div>
        <!-- rest dynamic modal content -->
    <div class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">GRV DETAILS
            <div class="h-2 w-[100px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <div class="mt-10 grid grid-cols-3">
                    <div>
                        <div class="">
                            <label for="GRV doc #" class="text-xs block mb-1 text-gray-500">GRV doc #</label>
                            <input type="text" v-model="grvn" readonly  id="GRV doc #" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Supplier name" class="text-xs block mb-1 text-gray-500">Supplier name</label>
                            <input type="text" v-model="Suppliername" readonly  id="Supplier name" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Delivered by" class="text-xs block mb-1 text-gray-500">Delivered by</label>
                            <input type="text" v-model="deliveredby" readonly  id="Delivered by" class="inputbox  mb-3">
                        </div>
                        
                    </div>
                    <div>
                        <div class="">
                            <label for="GRV doc scan" class="text-xs block mb-1 text-gray-500">GRV doc scan</label>
                            <input type="text"  :value="grvscan.split('---')[1]"  @click="openImage(grvscan)" readonly  id="GRV doc scan" class="inputbox nav_blue cursor-pointer text-nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Invoice #" class="text-xs block mb-1 text-gray-500">Invoice #</label>
                            <input type="text" v-model="invoicen" readonly  id="Invoice #" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="Received by" class="text-xs block mb-1 text-gray-500">Received by</label>
                            <input type="text" v-model="receivedby" readonly  id="Received by" class="inputbox mb-3">
                        </div>
                        
                    </div>
                    <div>
                        <div class="">
                            <label for="Invoice doc scan" class="text-xs block mb-1 text-gray-500">Invoice doc scan</label>
                            <input type="text" :value="invoicescan.split('---')[1]" @click="openImage(invoicescan)" readonly  id="Invoice doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Purchase requisition #" class="text-xs block mb-1 text-gray-500">Purchase requisition #</label>
                            <input type="text" v-model="purchaserequest"  readonly id="Purchase requisition #" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="Delivery date" class="text-xs block mb-1 text-gray-500">Delivery date</label>
                            <input type="text" v-model="deliverydate" readonly  id="Delivery date" class="inputbox mb-3">
                        </div>
                        
                    </div> 
        </div>
    </div>    
       <TableView showdoc="Needed" @delete="deleteitem" @remark="showRemark" exportNeeded='false' :headerList="Headers" :itemlist="grvDetail.Grv1Transaction"/>
       <RemarkDisplay v-if="toggleremark" :remark="remark?remark:null"  @close="toggleremark = !toggleremark"  class="h-[700px] w-full absolute top-0 left-0"/>   
    </div>
       <UpdateGrv v-if="toggleEdit" :currentTr="grvDetail" @close="(toggleEdit = !toggleEdit) ,$emit('close')"  class="absolute top-0 left-0  bg-white w-full"/>    
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import RemarkDisplay from "../../components/RemarkDisplay.vue";
import UpdateGrv from "./UpdateGrv.vue"
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import { useStore } from "vuex";

const toggleremark = ref(false)
const toggleEdit = ref(false)

const store = useStore()
const grvDetail =  computed(() =>store.getters.grvDetail)

const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

    
const grvn = ref(grvDetail.value.grvNumber)
const Suppliername = ref(grvDetail.value.supplierName)
const deliveredby = ref(grvDetail.value.deliverBy)
const grvscan = ref(grvDetail.value.grvFile)
const invoicen = ref(grvDetail.value.invoiceNumber)
const receivedby = ref(grvDetail.value.receivedBy)
const invoicescan = ref(grvDetail.value.invoiceFile)
const purchaserequest = ref(grvDetail.value.pr)
const deliverydate = ref(grvDetail.value.date)

const Headers = [
  { text: "Item ID", value: "item.stock.item.id" , sortable: true },
  { text: "Part #", value: "item.stock.item.partNumber"},
  { text: "Item name", value: "item.stock.item.itemName"},
  { text: "UoM", value: "item.stock.item.uom.abbreviation"},
  { text: "Quantity", value: "item.receivedQty"},
  { text: "Unit price(ETB)", value: "item.price"},
  { text: "Remark", value: "item.remark"},
  
];

const remark = ref()
const showRemark=(val)=>{
    remark.value = val.item.remark
    val.item.remark!==''?toggleremark.value = !toggleremark.value:null
}
const openImage=(val)=>{
    window.open(val, '_blank');
}

</script>

<style lang="postcss">


</style>