<template >
<div v-if="!toggleRecivegoods && !toggleDetails">
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">GOODS RECEIVED
            <div class="h-2 w-28 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict" :class="{'hidden':restrict || !userStore}" class=" disabled:bg-blue-400 disabled:cursor-not-allowed mt-28 ml-8 p-2 px-2 flex items-center bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer " @click="toggleRecivegoods=!toggleRecivegoods">
            <img :src="add" alt="add" class="h-3 mr-1.5">
           receive goods
        </button>
        
        </div>
        <!-- filter -->
        <div class="flex mt-16  ml-auto justify-end ">
           
            <div>
                <div class="flex items-center mr-[100px] space-x-3 " :class="{' mr-[20px]':userStore}">
                     <div class=" font-bold text-[15px] mr-9 "> FILTER BY
                        <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                    </div>
                    <div class=" h-10 py-2 rounded-lg px-3  bg-main_gray">
                        <img :src="calander" alt="calander" @click="toggledatepicker = true" class="h-6" > 
                        <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                    </div>
                        <DropDown v-if="!userStore" placeholderp="Stores" :items="staticStoreList" displayable="storeName" @selected="(selected)=>setstores(selected)"  width="false"/>
                    <input v-else type="text" v-model="userStore.storeName" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">        
                </div>
                <div class=" flex ">
                <div class="inputbox p-0  flex items-center mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll"  placeholder="Search by GRV ID ..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter" showdoc="Needed" @displayScan="showScan" @delete="deleteitem" @showdoc="showdocitem" @detail="detailitem" exportName="itemlist" :headerList="Headers" :itemlist="grvList"/>
        
</div>

        <ReceiveGoods v-if="toggleRecivegoods" @close="toggleRecivegoods=!toggleRecivegoods" class="absolute top-0 left-0  bg-white w-full"/>
        <GrvDetails v-if="toggleDetails" @close="toggleDetails=!toggleDetails"  class="absolute top-0 left-0  bg-white w-full"/>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, watch } from "@vue/runtime-core"
import DropDown from "../../components/DropDown.vue"
import TableView from "../../components/TableView.vue";
import DateRange from "../../components/DateRange.vue";
import ReceiveGoods from "./ReceiveGoods.vue"
import GrvDetails from "./GrvDetails.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

onBeforeMount(() => {  
    store.dispatch('fetchGoodsRecived')
})


const store = useStore()


const toggleRecivegoods = ref(false)
const toggleDetails = ref(false)
const toggledatepicker = ref(false)

const storefilter = ref()
const searchFilter = ref('')


let grvList = ref(store.getters.grvList)
let staticStoreList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList.store)))
staticStoreList.value.unshift({ id: 0, storeName: "ALL"});
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

const Headers = [
  { text: "GRV ID", value: "grvNumber" , sortable: true },
  { text: "Invoice #", value: "invoiceNumber"},
  { text: "Supplier name", value: "supplierName"},
  { text: "Purchase req. #", value: "pr",sortable: true},
  { text: "Date", value: "date",sortable: true},
  { text: "Actions", value: "operation"},
];

watch(toggleDetails, async (newQuestion, oldQuestion) => {

    if ( oldQuestion===true ) {
        store.dispatch('fetchGoodsRecived')
        .then(()=>{
            grvList = ref(store.getters.grvList)
            filter.value = new Date()

        })
    }
})
watch(toggleRecivegoods, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchGoodsRecived')
        .then(()=>{
            grvList = ref(store.getters.grvList)
            filter.value = new Date()
        })
    }
})


const filterVars = ref({store:null,init:null,end:null})
let filter = ref(1)

const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const setstores=(val)=>{
    storefilter.value = val.storeName 
    filterVars.value.store = val.id
    filterAll();
}

const filterAll = ( ) =>{
    grvList = ref(store.getters.grvList)

    if (filterVars.value.store !== null && filterVars.value.store !== 0) {
        grvList.value = grvList.value.filter((user)=>user.storeId === filterVars.value.store)   
    }
     if (filterVars.value.init !== null){

        grvList.value = grvList.value.filter((user)=>new Date(user.deliveredDate) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )

   }
    if (filterVars.value.end !== null){

        grvList.value = grvList.value.filter((user)=>new Date(user.deliveredDate) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
   if (searchFilter.value !== ''){
        grvList.value = grvList.value.filter((user)=>(user.grvNumber).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    
    filter.value = new Date()
}

const detailitem=(val)=>{
        store.dispatch('getGrvDetail',{id:val.id})
        .then(()=>{ 
            toggleDetails.value = !toggleDetails.value
        })
      
}

const deleteitem=(val)=>{
     if (userRole.value === 'MASTER_STORE_MANAGER'){

        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteGrv',val.id)
                            .then(()=>{
                                store.dispatch('fetchGoodsRecived')
                            })
                            .then(()=>{
                                grvList = ref(store.getters.grvList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })

     }      
}

const showScan=(val)=>{
    window.open(val.grvFile);
    window.open(val.invoiceFile);
}

const restrict = computed(()=>{
    if (!userStore ) {
        return true
    }else if (userRole.value!=='MASTER_STORE_MANAGER') {
        return true
    }
    else{
        return false
    }
})

</script>



<style lang="postcss">
    
</style>