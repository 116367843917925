<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">UPDATE USER
            <div class="h-2 w-28 rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{props.olddata.id}}</p>
                <p class="text-[9px]  text-gold_yellow ">-User ID</p>
            </div>
           
            <div class="flex w-[750px]   mt-10 ">
                <div class="grid col-span-1 gap-3">
                    <input type="text" v-model="fname" placeholder="Full name" class="inputbox nav_blue mr-10">
                    <input type="text" v-model="email" readonly placeholder="Email" class="inputbox bg-gray-300 mr-10">
                    <input type="text" v-model="phone" placeholder="Phone #" class="inputbox">
                </div>
                <div class=" space-y-3">
                    <div v-if="oldcompany !=='-'" class="relative">
                        <!-- <drop-down placeholderp="company..." :items="staticList.company" displayable="name" @selected="(selected)=>setcompany(selected)" /> -->
                        <input  type="text" disabled :value="oldcompany" class="inputbox bg-gray-300">  
                    </div>
                    <div class="relative">
                        <!-- <drop-down placeholderp="Role" :items="roles" displayable="name" @selected="(selected)=>setrole(selected)" /> -->
                        <input  type="text" disabled :value="oldrole" class="inputbox bg-gray-300">    
                    </div>    
                </div>
            </div>
        </div>
						
        <div class="mt-40">
            <button  v-if="!isLoading" :disabled="activate" @click.prevent="submitUser"  class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import { useStore } from "vuex"
import backarrow from "../../assets/arrowback.svg"
import Spinner from "../../components/Spinner.vue"
import DropDown from "../../components/DropDown.vue"

const store = useStore()
const close = defineEmits("close")
const props = defineProps({
  olddata:Object,
})

const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
    
const oldcompany = ref(props.olddata.company.name)
const fname = ref(props.olddata.fullName)
const phone = ref(props.olddata.phoneNumber)
const email = ref(props.olddata.email)
const company = ref('')
const role = ref('') 
const isLoading = ref(false);

 const roles = [
    { id: 1, name: 'System Admin', value: "SYSTEM_ADMIN" },
    { id: 2, name: 'Upper Management', value: "UPPER_MANAGEMENT" },
    { id: 3, name: 'Middle Management', value: "MIDDLE_MANAGEMENT" },
    { id: 4, name: 'Project Manager', value: "PROJECT_MANAGER" },
    { id: 5, name: 'Master store Manager', value: "MASTER_STORE_MANAGER" },
    { id: 6, name: 'Brunch store Manager', value: "BRANCH_STORE_MANAGER" },
  ]
const rolelength = computed(()=>{
    return roles.length
})

const oldrole = computed(()=>{
    for (let index = 0; index < rolelength.value; index++) {
        if (props.olddata.role === roles[index].value) {
        return roles[index].name 
    }}   
})

const activate = computed(()=>{
    if(props.olddata.fullName===fname.value &&
       props.olddata.phoneNumber===phone.value
       
       ){
       return true;
    }else{
        return false;
    }
})
const setcompany=(val)=>{
    company.value = val 
}
const setrole=(val)=>{
    role.value = val
}
const submitUser=()=>{

    isLoading.value = true;
     store.dispatch('updateUser',
     {
     id:props.olddata.id,
     fullName:fname.value,
     phoneNumber:phone.value,
    //  email:email.value,
    //  role:role.value!=''?role.value:props.olddata.role,
    //  companyId:company.value!==''?Number(company.value.id):props.olddata.companyId
    })
     .then(()=>{ 
          store.dispatch('fetchUsers')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });

     
}

</script>

<style lang="postcss">


</style>