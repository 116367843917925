<template>
 <div class="grid grid-cols-6 h-screen w-screen">
    <side-nav @navigation="selectetab" class="col-span-1 h-full"/>
    <p class="absolute bottom-4 right-8 z-20 text-gray-700 text-[10px]">System v0.0.1</p>
  <div class=" col-span-5 bg-stone-100 pb-10 h-screen w-full">
    <div class="bg-white  overflow-y-scroll custom-scrollbar  mx-8 h-full rounded-b-lg ">
      <div class="relative">
         <component :is="currentSelected  "></component>
       </div>
    </div>

  </div>
 </div>
</template>

<script setup>
  import {  shallowRef } from "@vue/runtime-core";
  import SideNav from "../components/SideNav.vue"
  import Dashboard from "./Dashboard/Dashboard.vue"
  import Inventory from "./inventory/Inventory.vue"
  import Users from "./Users/Users.vue"
  import Stores from "./Stores/Stores.vue"
  import Requisitions from "./Requisitions/Requisitions.vue"
  import GoodsRecived from "./GoodsRecived/GoodsRecived.vue"
  import Transfers from "./Transfers/Transfers.vue"
  import ReturnedGoods from "./ReturnedGoods/ReturnedGoods.vue"
  import PurchaseOrder from "./PurchaseOrder/PurchaseOrder.vue"
  import Catalogue from "./Catalogue/Catalogue.vue"
  import MyAccount from "./MyAccount/MyAccount.vue"
 
const currentSelected= shallowRef(Dashboard)

function selectetab(selected) {
  
     selected==='Dashboard'?currentSelected.value = Dashboard 
  :  selected==='Inventory'?currentSelected.value = Inventory 
  :  selected==='GoodsRecived'?currentSelected.value = GoodsRecived 
  :  selected==='Requisitions'?currentSelected.value = Requisitions 
  :  selected==='Transfers'?currentSelected.value = Transfers 
  :  selected==='ReturnedGoods'?currentSelected.value = ReturnedGoods 
  :  selected==='PurchaseOrder'?currentSelected.value = PurchaseOrder 
  :  selected==='Stores'?currentSelected.value = Stores 
  :  selected==='Catalogue'?currentSelected.value = Catalogue 
  :  selected==='Users'?currentSelected.value = Users 
  :  selected==='MyAccount'?currentSelected.value = MyAccount 
  : currentSelected.value = Users 
 



}



</script>

<style>
 .custom-scrollbar::-webkit-scrollbar {
    width: 2px;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d7d8d9; /* Tailwind color or your preferred color */
    /* border-radius: 4px; */
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #cbd5e0; /* Tailwind color or your preferred color */
  }

  .custom-scrollbar {
    scrollbar-color: #d7d8d9 #cbd5e0;
  }
  .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #d7d8d9 #cbd5e0;
}
</style>