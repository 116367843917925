<template >
    <div v-if="!toggleAddCompany">
    <div class="grid grid-cols-2 mb-5 -mt-1 ">
        <div class="flex items-center  mt-[94px]">

            <div class=" font-black text-[25px] ml-5 ">COMPANY
                <div class="h-2 w-20 rounded-full bg-gold_yellow "></div>
            </div>
            <button  :disabled="restrict" :class="{'hidden':restrict}" class="  ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleAddCompany=!toggleAddCompany">
                <img :src="add" alt="add" class="h-3 mr-1.5">
                add company
            </button>
            
        </div>
        <div class=" flex items-center mt-24 justify-end">
                <div class="inputbox p-0  flex items-center mr-5  mt-6  w-[340px]   ">
                    <img :src="search"  @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll" placeholder="Search by Company name..." class="inputbox focus:outline-none    ">
                </div>
        </div>
        
    </div>
    <!-- table -->
    <TableView :key="filter" @delete="deleteitem" @detail="detailitem" showeye="Needed" showdel="Needed" exportName="company" :headerList="Headers" :itemlist="companyList"/>
    </div>
    <AddCompany v-if="toggleAddCompany" @close="toggleAddCompany=!toggleAddCompany" class="absolute top-0 left-0 h-full  bg-white w-full"/>
    <UpdateCompany v-if="toggleUpdate" :olddata="olddata"  @close="toggleUpdate=!toggleUpdate" class="absolute top-0 left-0 h-full z-20 bg-white w-full"/>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, onUpdated, watch } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import AddCompany from "./AddCompany.vue"
import UpdateCompany from "./UpdateCompany.vue"
import search from "../../assets/search.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

const store = useStore()

onBeforeMount(() => {
    
    store.dispatch('fetchCompany')
})


const toggleAddCompany = ref(false)
const toggleUpdate = ref(false)

const Headers = [
 { text: "Company ID", value: "id" , sortable: true },
  { text: "Company name", value: "name"},
  { text: "Created on", value: "date"},
  { text: "Actions", value: "operation"},
];

const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))
let companyList = ref(store.getters.companyList)


watch(toggleAddCompany, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {

        store.dispatch('fetchCompany')
        .then(()=>{
            companyList = ref(store.getters.companyList)
            filter.value = new Date()
        })
    }
})
watch(toggleUpdate, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchCompany')
        .then(()=>{
            companyList = ref(store.getters.companyList)
            filter.value = new Date()
        })
    }
})

let filter = ref("tableKey")
const searchFilter = ref('')


const filterAll = ( ) =>{
    companyList = ref(store.getters.companyList)
   
    if (searchFilter.value !== ''){
        companyList.value = companyList.value.filter((user)=>(user.name).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    filter.value = new Date()

}

const olddata = ref();
const detailitem=(val)=>{
     if (userRole.value === 'SYSTEM_ADMIN'){
        olddata.value = val;
        toggleUpdate.value = !toggleUpdate.value
     }
}

const deleteitem=(val)=>{
     if (userRole.value === 'SYSTEM_ADMIN'){
        

      Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteCompany',val.id)
                            .then(()=>{
                                store.dispatch('fetchCompany')
                                store.dispatch('fetchStaticList')
                            })
                            .then(()=>{
                                companyList = ref(store.getters.companyList)
                                filter.value = new Date()                                                                                         
                            })
                            
                            filter.value = new Date()
                        }
                        })
     }      
}

const restrict = computed(()=>{
    if (userRole.value!=='SYSTEM_ADMIN') {
        return true
    }
    else{
        return false
    }
})

</script>



<style lang="postcss">
    
</style>