<template >
    <div v-if="!toggleAddUom">
    <div class="grid grid-cols-2 mb-5 -mt-1 ">
        <div class="flex items-center  mt-[94px]">

            <div class=" font-black text-[25px] ml-5 ">CATALOGUE
                <div class="h-2 w-20 rounded-full bg-gold_yellow "></div>
            </div>
            <button :disabled="restrict" :class="{'hidden':restrict}" class="  ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleAddUom=!toggleAddUom">
                <img :src="add" alt="add" class="h-3 mr-1.5">
                add UoM
            </button>
            
        </div>
        <div class=" flex items-center mt-24 justify-end">
                <img :src="calander" alt="calander" @click="toggledatepicker = true" class=" h-10 py-1.5 rounded-lg px-2.5 mt-6 mr-3 bg-main_gray">
                <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                <div class="inputbox p-0  flex items-center mr-5  mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll" placeholder="Search by UoM name..." class="inputbox focus:outline-none    ">
                </div>
                </div>
        
    </div>
    <!-- table -->
    <TableView :key="filter" @delete="deleteitem" showeye="notNeeded" showdel="Needed" exportName="uom" :headerList="Headers" :itemlist="uomList"/>
    </div>
        <AddUom v-if="toggleAddUom" @close="toggleAddUom=!toggleAddUom" class="absolute top-0 left-0 h-full  bg-white w-full"/>

</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, watch } from "@vue/runtime-core"
import DropDown from "../../components/DropDown.vue"
import TableView from "../../components/TableView.vue";
import DateRange from "../../components/DateRange.vue";
import AddUom from "./AddUom.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

const store = useStore()
store.dispatch('fetchUoM')

const toggleAddUom = ref(false)
const toggledatepicker = ref(false)

const Headers = [
 { text: "UOM ID", value: "id" , sortable: true },
  { text: "UoM name", value: "name"},
  { text: "UoM abbreviation", value: "abbreviation"},
  { text: "No. of items", value: "_count.Item"},
  { text: "Created on", value: "date"},
  { text: "Actions", value: "operation"},
];

const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))
let uomList =  ref(store.getters.uomList)

watch(toggleAddUom, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        uomList =  ref(store.getters.uomList)
        filter.value = new Date()
    }
})

let filter = ref("tableKey")
const searchFilter = ref('')
const filterVars = ref({init:null,end:null})

const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const filterAll = ( ) =>{
    uomList = ref(store.getters.uomList)
   
     if (filterVars.value.init !== null){

        uomList.value = uomList.value.filter((user)=>new Date(user.createdAt) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )

    }
    if (filterVars.value.end !== null){

        uomList.value = uomList.value.filter((user)=>new Date(user.createdAt) <= new Date(filterVars.value.end))
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
    }
    if (searchFilter.value !== ''){

        uomList.value = uomList.value.filter((user)=>(user.name).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    filter.value = new Date()

}

const deleteitem=(val)=>{
     if (userRole.value === 'SYSTEM_ADMIN'){
        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteUom',val.id)
                            .then(()=>{
                                store.dispatch('fetchUoM')
                            })
                            .then(()=>{
                                uomList =  ref(store.getters.uomList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
     }     
}
const restrict = computed(()=>{
    if (userRole.value!=='SYSTEM_ADMIN') {
        return true
    }
    else{
        return false
    }
})

</script>
