<template >
    <div class="h-full   bg-black  bg-opacity-50 z-20 flex justify-center" @click="closeModal">
        <div class="bg-white h-1/3 w-2/5 mt-56  rounded-lg p-6" @click.stop>
            <h3 class="text-xl text-center font-semibold mt-2">FILTER BY DATE</h3>
        <div class="flex justify-center gap-6 mt-10">
         <div class=" relative inputbox   ">
            <img :src="calander" @click="$refs.from.renderView('day')" alt="calander" class="absolute z-10 right-3 h-3.5 mt-1.5">
            <datepicker ref="from" inputFormat="dd/MM/yyyy" v-model="fromdate" placeholder="From date" :upperLimit="new Date()"  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
        </div>
         <div class=" relative inputbox  ">
            <img :src="calander" @click="$refs.to.renderView('day')" alt="calander" class="absolute z-10 right-3 h-3.5 mt-1.5">
            <datepicker ref="to" inputFormat="dd/MM/yyyy" v-model="todate" placeholder="To date" :upperLimit="new Date()"  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
        </div>
        </div>
        <div class="flex justify-center mt-8">
            <button type="submit" @click.prevent="submit" :disabled="activate" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold p-2 px-5 rounded-lg ">Apply filter</button>
        </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { computed } from '@vue/runtime-core'
import Datepicker from 'vue3-datepicker'
import calander from "../assets/calander.svg"

const emit = defineEmits(["dateRange","close"])



const fromdate =ref()
const todate =ref()

const activate = computed(()=>{
    if(!fromdate.value && !todate.value){
        return true;
    }else{
        return false;
    }
})

const submit = ()=>{
   emit('dateRange',fromdate,todate )
   emit("close")
}
const closeModal = ()=>{
   emit("close")
}


</script>
