<template >
    <div  v-if="!toggleRecivegoods" >
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">INVENTORY
            <div class="h-2 w-16 rounded-full bg-gold_yellow "></div>
        </div>
        
        </div>
        <!-- filter -->
        <div class="flex mt-5  ml-auto justify-end ">
            <div class=" font-bold text-[15px] mr-5 mt-1 "> FILTER BY
                <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                
            </div>
            <img :src="resetx" alt="reset" v-if="showRestFilter" @click="resetFilter" class=" h-10 py-2 rounded-lg px-2.5 mr-3 cursor-pointer bg-main_gray">
            <div  >
                <div class="flex justify-end mr-[115px] space-x-24 " :class="{'space-x-6':userRole !== 'SYSTEM_ADMIN' && userRole !== 'UPPER_MANAGEMENT'}">
                    
                    <div class="space-y-3 -ml-5">
                    
                        <DropDown :key="companyRef" v-if="userRole === 'SYSTEM_ADMIN' || userRole === 'UPPER_MANAGEMENT'" placeholderp="All companies" :items="staticList.company" displayable="name" @selected="(selected)=>setcompany(selected)"  width="false"/>
                        <input v-else type="text" v-model="userCompany" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">
                    
                        <DropDown :key="storeRef" v-if=" userRole === 'SYSTEM_ADMIN' || userRole === 'UPPER_MANAGEMENT'" placeholderp="All stores" :items="staticList.store" displayable="storeName" @selected="(selected)=>setstore(selected)"  width="false"/>
                        <input v-else type="text" :value="userStore!==null ?userStore.storeName:'Non'" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">    
                    </div>
                    <div class="space-y-3 -ml-5">
                        <DropDown :key="catagRef" placeholderp="All categories" :items="staticList.category" displayable="name" @selected="(selected)=>setcategory(selected)"  width="lilwider"/>
                        <DropDown :key="subcatagRef" placeholderp="All sub-categories" :items="staticList.subcategory" displayable="name" @selected="(selected)=>setsubcategory(selected)"  width="lilwider"/>
                    </div>
                </div>
                <div class=" flex items-center ">
                
                <div class="inputbox p-0  flex items-center mr-5  mt-6  w-[350px]   ">
                    <img :src="search" @click.prevent="searchAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="searchAll" placeholder="Search by item ID..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <TableView :key="filter" showdoc="notNeede" showeye="Needed" showdel="notNeeded"  @detail="detailitem" exportName="inventory" :headerList="Headers" :itemlist="inventoryList"/>
    <!-- table -->

    </div>
    <Bincard v-if="toggleRecivegoods" @close="toggleRecivegoods=!toggleRecivegoods" :currentItem="currentItem" class="absolute top-0 left-0  bg-white w-full"/>

</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import DropDown from "../../components/DropDown.vue"
import search from "../../assets/search.svg"
import resetx from "../../assets/resetx.svg"
import TableView from "../../components/TableView.vue";
import Bincard from "./Bincard.vue"
import { useStore } from "vuex"

const store = useStore()

onBeforeMount(() => {  
    store.dispatch('fetchInventory')
    .then(()=>{
        inventoryList = ref (store.getters.inventoryList)
    })
})

let inventoryList = ref (store.getters.inventoryList)
const staticList =  computed(() =>(store.getters.staticList))
const storeList =  computed(() =>(store.getters.staticList).store)

staticList.value.category.length>1 && staticList.value.category[0].name!=="ALL"?staticList.value.category.unshift({ id: 0, name: "ALL"}):null
staticList.value.subcategory.length>1 && staticList.value.subcategory[0].name!=="ALL"?staticList.value.subcategory.unshift({ id: 0, name: "ALL"}):null

const userRole =  computed(() =>(store.getters.USER_ROLE))
const userCompany =  computed(() =>(store.getters.USER_COMPANY))
const userCompanyId =  computed(() =>(store.getters.USER_COMPANY_ID))
const userStore =  computed(() =>(store.getters.USER_STORE))


    if (userCompany!=='-') {
         storeList.value.filter((store)=>store.company.id === userCompanyId.value)
    }


const toggleRecivegoods = ref(false)
const currentItem = ref()

const detailitem=(val)=>{
    currentItem.value = val
    toggleRecivegoods.value = !toggleRecivegoods.value 
}

const Headers = [
  { text: "Item ID", value: "id" , sortable: true },
  { text: "Part #", value: "partNumber"},
  { text: "Item name", value: "name"},
  { text: "Category", value: "category"},
  { text: "Sub-category", value: "subcategory"},
  { text: "Balance", value: "balance"},
  { text: "Actions", value: "operation"},
];

const company = ref()
const storeFilter = ref()
const subcategoryFilter = ref()
const categoryFilter = ref()
const searchFilter = ref('')
const showRestFilter = ref(false)

const filterVars = ref({store:null,company:null,category:null,subcategory:null})
let filter = ref(new Date())
let catagRef = ref('0')
let storeRef = ref('1')
let subcatagRef = ref('2')
let companyRef = ref('3')

const setcompany=(val)=>{
    filterVars.value.company = val.id
    company.value = val.name 
    filterAll()
}
const setstore=(val)=>{
    filterVars.value.store = val.id
    storeFilter.value = val.storeName  
    filterAll()
    

}
const setcategory=(val)=>{
    val.name!=="ALL"?filterVars.value.category = val.id:filterVars.value.category = null
    categoryFilter.value = val.name 
    filterAll()

}
const setsubcategory=(val)=>{
    val.name!=="ALL"?filterVars.value.subcategory = val.id:filterVars.value.subcategory = null
    subcategoryFilter.value = val.name
    filterAll()    
}

const searchAll = ( ) =>{
    inventoryList = ref (store.getters.inventoryList)
    if (searchFilter.value !== ''){
        inventoryList.value = inventoryList.value.filter((user)=>user.id.toLowerCase().includes(searchFilter.value.toLowerCase()))
    }
    
    filter.value = new Date()

}

const resetFilter = ()=>{
    filterVars.value.category = null
    filterVars.value.subcategory = null
    filterVars.value.store = null
    filterVars.value.company = null

    catagRef.value = new Date()
    subcatagRef.value = new Date()
    companyRef.value = new Date()
    storeRef.value = new Date()

     inventoryList = ref (store.getters.inventoryList)
    filter.value = new Date()
    showRestFilter.value=false;
}

const filterAll = async ( ) =>{
    showRestFilter.value=true;
    
    store.dispatch('getFilterInventory',filterVars.value)
    
    .then(()=>{
        inventoryList = ref (store.getters.FilteredinvList)
        filter.value = new Date()
   })

}

</script>



<style lang="postcss">
    
</style>