<template>
    <div>

    <!-- backarrow -->
    <div class="flex items-center ml-3 mt-5" >
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5" @click="$emit('close')">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer " @click="$emit('close')"> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">ADD UOM
            <div class="h-2 w-20 rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{newid}}</p>
                <p class="text-[9px]  text-gold_yellow ">~ UoM ID</p>
            </div>
           
            <div class=" w-[70px]   mt-10 ">
              
                    <input type="text" v-model="name" placeholder="UoM name..." class="inputbox nav_blue mr-10 mb-4">
                    <input type="text" v-model="abbreviation" placeholder="UoM abbreviation..." class="inputbox nav_blue mr-10">
                   
                
            </div>
        </div>
						
        <div class="mt-28">
            <button v-if="!isLoading" :disabled="activate" @click.prevent="submitUom" class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import { useStore } from "vuex"
import backarrow from "../../assets/arrowback.svg"
import Spinner from "../../components/Spinner.vue"
import attachment from "../../assets/attachment.svg"

const close = defineEmits("close")

const store = useStore()
const uomId = ref()
const isLoading = ref(false);

const newUom =  computed(() =>JSON.parse(JSON.stringify(store.getters.newUom)))
let newid = Number(newUom.value)+1
newid= 'UoM/' + newid;

// if (newid<10) {
//         uomId.value=('UoM/00'+newid)
//     }else if(newid=>10 && newid<100) {
//         uomId.value=('UoM/0'+newid)
//     }else{
//         uomId.value=('UoM/'+newid)
//     }

const name = ref()
const abbreviation = ref()

const activate = computed(()=>{
    if(!name.value||!abbreviation.value){
        return true;
    }else{
        return false;
    }
})

const submitUom=()=>{
    isLoading.value = true;
     store.dispatch('addUom',
        {id:newid,name:name.value,abbreviation:abbreviation.value})
     .then(()=>{ 
          store.dispatch('fetchUoM')
         store.dispatch('fetchStaticList')
         isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });
}

</script>

<style lang="postcss">


</style>