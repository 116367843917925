<template>
    <div class="grid grid-cols-3 ">
        <div class="col-span-1">    </div>
        <div class="h-full mt-20 ">
            <img :src="dashboardLogo" alt="Tsemex_brand" class="h-52 mx-auto">
            <h1 class="text-3xl font-bold text-center">Inventory Management System</h1>
            <div class="h-1.5 w-56 rounded-full mt-4 mx-auto bg-gold_yellow"></div>
            <form class="grid col-span-1 mt-12 ml-20">
                <h3 class="text-[18px] font-bold">CREATE PASSWORD</h3>
                <h6 class="text-gray-500 mb-4">Verify your new account by creating password.</h6>
                <div class=" relative my-5 flex">
                    <input :type="showPassword" v-model="userPassword" placeholder="Password" class="inputbox p-2.5  ">
                    <p v-if="showPassword === 'password'" @click.prevent="changeshowpassword" class="text-gray-500 cursor-pointer font-medium absolute inset-y-0 inset-x-64  flex items-center">SHOW</p>
                    <p v-else @click.prevent="changeshowpassword" class="text-gray-500 cursor-pointer font-medium absolute inset-y-0 inset-x-64  flex items-center">HIDE</p>   
                </div>
                <input type="password" v-model="confPassword" placeholder="Confirm password" class="inputbox p-2.5 ">
                <button :disabled="activate" @click.prevent="verify" class="submitbutton w-[108px]  mt-10">Save</button>
            </form>
        </div>
        <div class="col-span-1">    </div>
        
    </div>
</template>
<script setup>
import { ref } from "@vue/reactivity"
import dashboardLogo from "../../assets/dashboardLogo.png"
import { computed, onBeforeMount } from "@vue/runtime-core"
import { useStore } from "vuex"
import { useRoute } from 'vue-router'

const store = useStore()

onBeforeMount(() => {
    localStorage.removeItem('userstore');
    // store.commit('Sign_Out')
})

const showPassword = ref('password')
const confPassword = ref('')
const userPassword = ref('')
const token = ref()
token.value = (window.location.href).split('/:')[1];

const activate = computed(()=>{
    if(!confPassword.value || !userPassword.value){
        return true;
    }else{
        return false;
    }
})

const verify =()=>{
    if(confPassword.value && userPassword.value && confPassword.value === userPassword.value){
    store.dispatch('ActivateAccount',{password:userPassword.value,token:token.value})
    }
}
const changeshowpassword =()=>{
   
    if (showPassword.value === 'text') {
        showPassword.value = 'password'
    }else{
        showPassword.value = 'text'
    }
}

</script>
<style lang="">

    
</style>