<template >
    
   <div v-if="!toggleAddItem">
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center mt-[90px]">

        <div class=" font-black text-[25px]  ml-5 ">CATALOGUE
            <div class="h-2 w-20 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict" :class="{'hidden':restrict}" class=" ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleAddItem=!toggleAddItem">
            <img :src="add" alt="add" class="h-3 mr-1.5">
            add item
        </button>
        </div>
        <!-- filter -->
        <div class="flex  ml-auto justify-end ">
            <div  >
                <div class="flex justify-end mr-[100px]  ">
                    
            <div class=" font-bold text-[15px]  mr-12 mt-2 "> FILTER BY
                <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
            </div>
                        <div class="space-y-3 -ml-5">
                    <DropDown placeholderp="Categories" :items="staticList.category" displayable="name" @selected="(selected)=>setcatagory(selected)"  width="false"/>
                    <DropDown placeholderp="Sub-categories" :items="staticList.subcategory" displayable="name" @selected="(selected)=>setsubcatagory(selected)"  width="false"/>
                        </div>
                </div>
                <div class=" flex items-center  ">
                <img :src="calander" alt="calander" @click="toggledatepicker = true" class=" h-10 py-1.5 rounded-lg px-2.5 mt-6 mr-3 bg-main_gray">
                <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                <div class="inputbox p-0  flex items-center mr-5  mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll" placeholder="Search by item name..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter" showdoc=Needed @displayScan="showScan" @detail="detailitem" @delete="deleteitem" exportName="item" :headerList="Headers" :itemlist="itemList"/>
    </div>
    <AddItem v-if="toggleAddItem" @close="toggleAddItem=!toggleAddItem" class="absolute top-0 left-0 h-full  bg-white w-full"/>
    <UpdateItem v-if="toggleUpdate" :olddata="olddata"  @close="toggleUpdate=!toggleUpdate" class="absolute top-0 left-0 h-full z-20 bg-white w-full"/>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, watch } from "@vue/runtime-core"
import DropDown from "../../components/DropDown.vue"
import DateRange from "../../components/DateRange.vue";
import TableView from "../../components/TableView.vue";
import AddItem from "./AddItem.vue"
import UpdateItem from "./UpdateItem.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

const toggleAddItem = ref(false)
const toggleUpdate = ref(false)
const toggledatepicker = ref(false)


const Headers = [
 { text: "Item ID", value: "id" , sortable: true },
  { text: "Part #", value: "partNumber"},
  { text: "Item name", value: "itemName"},
  { text: "UoM", value: "uom.abbreviation"},
  { text: "Category", value: "category.name"},
  { text: "Sub-category", value: "subcategory.name"},
  { text: "Actions", value: "operation"},
];
const store = useStore()
let staticList =  computed(() =>store.getters.staticList)
staticList.value.category.length>1 && staticList.value.category[0].name!=="ALL"?staticList.value.category.unshift({ id: 0, name: "ALL"}):null
staticList.value.subcategory.length>1 && staticList.value.subcategory[0].name!=="ALL"?staticList.value.subcategory.unshift({ id: 0, name: "ALL"}):null
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))
let itemList =  ref(store.getters.itemList)
 
watch(toggleAddItem, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        staticList =  computed(() =>store.getters.staticList)
            itemList =  ref(store.getters.itemList)
            filter.value = new Date()
       
    }
})

watch(toggleUpdate, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        staticList =  computed(() =>store.getters.staticList)
            itemList =  ref(store.getters.itemList)
            filter.value = new Date()
    }
})

onBeforeMount(() => {

    store.dispatch('fetchItems')
    store.dispatch('fetchCatagory')
    store.dispatch('fetchSubCatagory')
    store.dispatch('fetchUoM')

})


const catagoryfilter = ref()
const subcategoryfilter = ref()
const searchFilter = ref('')

 const menu = [
    { name: 'Items', display: false },
    { name: 'Categories', display: false },
    { name: 'Sub-categories', display: false },
    { name: 'Unit of Measures', display: false },
    { name: 'Companies', display: false },
    
  ]

let filter = ref("tableKey")
const filterVars = ref({catagory:null,subcategory:null,init:null,end:null})

const setcatagory=(val)=>{
    catagoryfilter.value = val.name 
    filterVars.value.catagory = val.name
    filterAll();
}

const setsubcatagory=(val)=>{
    subcategoryfilter.value = val.name 
    filterVars.value.subcategory = val.name
    filterAll();
}

const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const closeUpdate=(val)=>{
    itemList = ref(store.getters.itemList)
    filter.value = new Date() 
   toggleUpdate.value = !toggleUpdate.value 
}

const filterAll = ( ) =>{
    itemList = ref(store.getters.itemList)
    if (filterVars.value.catagory !== null && filterVars.value.catagory !=='ALL') {
        itemList.value = itemList.value.filter((user)=>user.category.name === filterVars.value.catagory)   
    }
    if (filterVars.value.subcategory !== null && filterVars.value.subcategory !=='ALL'){

        itemList.value = itemList.value.filter((user)=>user.subcategory.name === filterVars.value.subcategory)
    }
    if (filterVars.value.init !== null){

        itemList.value = itemList.value.filter((user)=>new Date(user.createdAt) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )

   }
    if (filterVars.value.end !== null){

        itemList.value = itemList.value.filter((user)=>new Date(user.createdAt) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
    if (searchFilter.value !== ''){
        itemList.value = itemList.value.filter((user)=>(user.itemName ).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    
    filter.value = new Date()
}

const olddata = ref();
const detailitem=(val)=>{
     if (userRole.value === 'SYSTEM_ADMIN'){
        olddata.value = val;
        toggleUpdate.value = !toggleUpdate.value
     }
}

const deleteitem=(val)=>{
     if (userRole.value === 'SYSTEM_ADMIN'){
        

        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteItem',val.id)
                            .then(()=>{
                                store.dispatch('fetchItems')
                            })
                            .then(()=>{
                                itemList =  ref(store.getters.itemList)
                                staticList =  computed(() =>store.getters.staticList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
     }     
}
const showScan=(val)=>{
    window.open(val.itemSpecification);
}

const restrict = computed(()=>{
    if (userRole.value!=='SYSTEM_ADMIN') {
        return true
    }
    else{
        return false
    }
})
</script>



<style lang="postcss">
    
</style>