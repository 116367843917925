<template>
    <div v-if="!toggleEdit" class="mt-5">

    <!-- backarrow -->
   <div class="flex justify-between " >
        <div class="flex justify-between items-center ml-3 cursor-pointer">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 " @click="$emit('close')"> Back</p>
        </div>
        <div class="mr-16">
          <p v-if="userRole==='MASTER_STORE_MANAGER' || userRole==='BRANCH_STORE_MANAGER'" class="flex text-yellow-400 cursor-pointer" @click.prevent="toggleEdit=true"> EDIT</p>
        </div>
    </div>
        <!-- rest dynamic modal content -->
    <div class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">RV DETAILS
            <div class="h-2 w-[90px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <div class="mt-10 grid grid-cols-3">
                    <div>
                        <div class="">
                            <label for="SIV doc #" class="text-xs block mb-1 text-gray-500">SIV doc #</label>
                            <input type="text" v-model="sivnum" readonly  id="SIV doc #" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="GRV doc #" class="text-xs block mb-1 text-gray-500">GRV doc #</label>
                            <input type="text" v-model="grvnum" readonly  id="GRV doc #" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="Return date" class="text-xs block mb-1 text-gray-500">Return date</label>
                            <input type="text" v-model="retundate" readonly  id="Return date" class="inputbox  mb-3">
                        </div>
                        
                    </div>
                    <div>
                        <div class="">
                            <label for="GRV doc scan" class="text-xs block mb-1 text-gray-500">GRV doc scan</label>
                            <input type="text" :value="grvscan.split('---')[1]" @click="openImage(grvscan)" readonly  id="GRV doc scan" class="inputbox nav_blue cursor-pointer text-nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Returned by" class="text-xs block mb-1 text-gray-500">Returned by</label>
                            <input type="text" v-model="returnedby" readonly  id="Returned by" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="Recipient" class="text-xs block mb-1 text-gray-500">Recipient</label>
                            <input type="text" v-model="recipient" readonly  id="Recipient" class="inputbox mb-3">
                        </div>
                        
                    </div>
                    <div>
                        <div class="">
                            <label for="RV doc scan" class="text-xs block mb-1 text-gray-500">RV doc scan</label>
                            <input type="text" :value="rvscan.split('---')[1]" @click="openImage(rvscan)" readonly  id="RV doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="SIV doc scan" class="text-xs block mb-1 text-gray-500">SIV doc scan</label>
                            <input type="text" :value="sivscan.split('---')[1]" @click="openImage(sivscan)" readonly id="SIV doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
                        </div>
                       
                        
                    </div> 
        </div>
    </div>    
      <TableView showdoc="Needed" @delete="deleteitem" @remark="showRemark" exportNeeded='false' :headerList="Headers" :itemlist="srItemsDetail"/>
      <RemarkDisplay v-if="toggleremark" :remark="remark?remark:null"  @close="toggleremark = !toggleremark"  class="h-[700px] w-full absolute top-0 left-0"/>         
    </div>
     <UpdateReturn v-if="toggleEdit" :currentTr="srDetail" @close="toggleEdit = !toggleEdit , $emit('close')"  class="absolute top-0 left-0  bg-white w-full"/>    
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import RemarkDisplay from "../../components/RemarkDisplay.vue";
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import UpdateReturn from "./UpdateReturn.vue"
import { useStore } from "vuex";

const store = useStore()
const srDetail =  computed(() =>store.getters.srDetail)
const srItemsDetail =  computed(() =>store.getters.srDetail.Grv3transaction)

const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

const toggleEdit = ref(false)

const sivnum = ref(srDetail.value.siv.sivNumber)
const grvnum = ref(srDetail.value.grvNumber)
const retundate = ref(srDetail.value.date)
const grvscan = ref(srDetail.value.grvFile)
const returnedby = ref(srDetail.value.returnedBy)
const recipient = ref(srDetail.value.recipient)
const rvscan = ref(srDetail.value.rvFile)
const sivscan = ref(srDetail.value.siv.sivFile)

const Headers = [
  { text: "Item ID", value: "item.stock.item.id" , sortable: true },
  { text: "Part #", value: "item.stock.item.partNumber"},
  { text: "Item name", value: "item.stock.item.itemName"},
  { text: "UoM", value: "item.stock.item.uom.abbreviation"},
  { text: "Quantity", value: "item.receivedQty"},
  { text: "Due date", value: "item.date"},
  { text: "Remark", value: "item.remark"},
  
];

const toggleremark = ref(false)
const remark = ref()
const showRemark=(val)=>{
    remark.value = val.item.remark
    val.item.remark!==''?toggleremark.value = !toggleremark.value:null
}
const openImage=(val)=>{
    window.open(val, '_blank');
}

</script>

<style lang="postcss">


</style>