<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">UPDATE RETURN GOODS
            <div class="h-2 w-44 rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <input type="text" v-model="sivnum"   placeholder="GRV doc #..." required class="inputbox  mb-6 mt-10">
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                       
                        <input type="text" v-model="grvnum"   placeholder="GRV doc #..." required class="inputbox  mb-3 ">
                        <input type="text" v-model="returnedby"   placeholder="Returned by..." required class="inputbox  mb-3">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.grvfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="grvscan.name?grvscan.name:grvscan.split('---')[1]" disabled  placeholder="Attach GRV doc scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                             <input type="file" ref="grvfile" accept=".pdf,image/*" @change="uploadgrv" style="display: none" />
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                        
                       <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="returndate" :upperLimit="new Date()" placeholder="Return date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                        <input type="text" v-model="recipient"   placeholder="Recipient..." required class="inputbox  mb-3">
                       <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.rvfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="rvscan.name?rvscan.name:rvscan.split('---')[1]" disabled   placeholder="Attach RV doc scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                             <input type="file" ref="rvfile" accept=".pdf,image/*" @change="uploadrv" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in currentTransaction.Grv3transaction.length" :key="item" class="mb-2"> 
            <div class="flex">
                <input type="text" v-model="allItems[index].itemId" readonly  placeholder="Item ID..." required class="inputbox      mr-3 w-36">
                <input type="text" v-model="alldesc[index].uom"  readonly placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="number" v-model="allItems[index].receivedQty"  @input="(event)=>checkBalance(index,event)"  placeholder="Qty..." required class="inputbox ml-3 mr-3 w-36">
                <input type="text" v-model="allItems[index].returnDate" readonly  placeholder="returnDate..." class="inputbox  mr-3 w-44">
                <input type="text" v-model="allItems[index].remark" readonly  placeholder="Remark..." class="inputbox  mr-3 w-56">
                <button v-if="index>0 || currentTransaction.Grv3transaction.length!==1" :key="index" @click="deductList(index)" class="bg-red-500 text-white text-3xl w-9 h-9 mx-2 items-center  rounded-full ">-</button>
               
            </div>
                 <p class="text-[12px] block">{{alldesc[index].desc}}</p>
        </div>

    <div class="mt-20">
            <button  v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="submitbutton">Update</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
        </div>
    </form>    
      
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Datepicker from 'vue3-datepicker'
import DropDown from "../../components/DropDown.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const close = defineEmits("close")
const store = useStore()

const props = defineProps({
 currentTr: Object
})

onBeforeMount(() => {  
    store.dispatch('fetchSivToAccept')
})

const currentTransaction = ref(props.currentTr)

const returnedby = ref(currentTransaction.value.returnedBy)
const grvscan = ref(currentTransaction.value.grvFile)
const rvscan = ref(currentTransaction.value.rvFile)
const recipient = ref(currentTransaction.value.recipient)
const grvnum = ref(currentTransaction.value.grvNumber)
const sivnum = ref(currentTransaction.value.siv.sivNumber)
const returndate = ref(new Date(currentTransaction.value.returnDate))


const itemId = ref()
const uom = ref()
const qty = ref()
const remark = ref()
const returnDate = ref()
const isLoading = ref(false);

const activate = computed(()=>{
    if(!returnedby.value || !grvscan.value || !rvscan.value ||
     !recipient.value|| !grvnum.value|| !sivnum.value|| !returndate.value){
        return true;
    }else{
        return false;
    }
})

const allItems = ref([
    {itemId:'',receivedQty:'',returnDate:'',remark:''}
])


const alldesc = ref([
    {desc:'',uom:'',date:'',balance:null}
])

const checkBalance =(index,event)=>{
    if(Number(allItems.value[index].receivedQty)>0 && Number(allItems.value[index].receivedQty)<=Number(alldesc.value[index].balance) +  Number(currentTransaction.value.Grv3transaction[index].item.receivedQty)){
        //  allItems.value[index].receivedQty =allItems.value[index].receivedQty.concat(Number(event.data))  ;
    }else{
        allItems.value[index].receivedQty = null
    }
}

const setUom =(selected,index)=>{
    alldesc.value[index].uom = selected.name
}

    
const addItemInputs = ()=>{
    alldesc.value.push({desc:'',uom:'',date:'',balance:null})
    allItems.value.push({itemId:'',receivedQty:'',returnDate:'',remark:''})
}
const deductList=(i)=> {
  alldesc.value = alldesc.value.filter((_, index) => index !== i)  
  allItems.value = allItems.value.filter((_, index) => index !== i)
}

const uploadgrv =(event)=>{ 
 grvscan.value =  event.target.files[0]
}

const uploadrv =(event)=>{ 
 rvscan.value =  event.target.files[0]
}

//   --------------------------
  
const setReturn = ()=>{
        allItems.value=[]
        alldesc.value=[]
        addItemInputs();
    
    
     for (let index = 0; index < currentTransaction.value.Grv3transaction.length; index++) {
        allItems.value[index].itemId = currentTransaction.value.Grv3transaction[index].item.stock.item.id
        alldesc.value[index].uom = currentTransaction.value.Grv3transaction[index].item.stock.item.uom.abbreviation
        allItems.value[index].receivedQty = currentTransaction.value.Grv3transaction[index].item.receivedQty
         alldesc.value[index].balance = currentTransaction.value.Grv3transaction[index].item.stock.item.Returnitem[0].balance
         allItems.value[index].returnDate = new Date (currentTransaction.value.Grv3transaction[index].item.returnDate).toLocaleString().split(',')[0]
        allItems.value[index].remark = currentTransaction.value.Grv3transaction[index].item.remark
        index < currentTransaction.value.Grv3transaction.length-1 && addItemInputs(index);
    }


}
setReturn ()



//   --------------------------

const submit =()=>{
    const formData = new FormData();
    formData.append("id", currentTransaction.value.id)
    returnedby.value && formData.append("returnedBy", returnedby.value);
    grvscan.value && formData.append("GRV", grvscan.value);
    rvscan.value && formData.append("RV", rvscan.value);
    recipient.value && formData.append("recipient", recipient.value);
    grvnum.value && formData.append("grvNumber", grvnum.value);
    currentTransaction.value && formData.append("sivId", currentTransaction.value.sivId);
    returndate.value && formData.append("returnDate", returndate.value);
    allItems.value && formData.append("items",(JSON.stringify(allItems.value)));

    isLoading.value = true;
    store.dispatch('UpdateStoreReturn',
        formData,
    )
    .then(()=>{
          store.dispatch('fetchReturnedGoods')
          store.dispatch('fetchInventory')
          isLoading.value = false;
    })  
    .then(()=>{ 
            close('close')
        }) 
        .catch((error) => {
            isLoading.value = false;
        });  
}


</script>

<style lang="postcss">


</style>