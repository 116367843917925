<template >
<div v-if="!toggleReturn && !toggleOverdue  && !toggleDetails">
    <div class="grid grid-cols-2 mb-5 -mt-2">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">RETURNED GOODS
            <div class="h-2 w-28 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict || !userStore"  :class="{'hidden':restrict|| !userStore}" class="mt-28 ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleReturn=!toggleReturn">
            <img :src="add" alt="add" class="h-3 mr-1.5">
           receive goods
        </button>
       
        
        </div>
        <!-- filter -->
        <div class="flex mt-16  ml-auto justify-end ">
           
            <div>
                <div class="flex items-center mr-20 " :class="{' mr-[0px]':userStore}">
                    
                        <div class=" font-bold text-[15px] mt-4  "> FILTER BY
                            <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                        </div>
                    <div class="flex mt-4 mx-5 ">
                       
                        <div class="relative h-10  rounded-lg  ml-[65px] mr-2  bg-main_gray cursor-pointer" :class="{' ml-[5px]':!userStore}" >
                            <img :src="clock" alt="clock" class=" h-10 w-10 p-2" @click="toggleOverdue=!toggleOverdue">    
                            <p class="absolute -top-1.5 -right-1.5  px-1.5 text-[13px] text-white font-light rounded-full bg-[#ED1C24]">{{overdueList}}</p>
                        </div> 
                        <div class=" h-10 py-2 rounded-lg px-3 mr-2  bg-main_gray">
                            <img :src="calander" alt="calander" @click="toggledatepicker = true" class="h-6" > 
                            <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                        </div>
                            <DropDown v-if="!userStore" placeholderp="Stores" :items="staticStoreList" displayable="storeName" @selected="(selected)=>setstores(selected)"  width="false"/>
                            <input v-else type="text" v-model="userStore.storeName" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">        
                    </div>
                    
                </div>
                <div class=" flex justify-end ">
                <div class="inputbox p-0  flex items-center mt-6 mr-5 w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll" placeholder="Search by GRV ID..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter" showdoc="Needed" @displayScan="showScan" showeye="Needed" showdel="Needed" @delete="deleteitem" @showdoc="showdocitem" @detail="detailitem" exportName="ReturnedGoods" :headerList="Headers" :itemlist="returnedList"/>
</div>    
   <ReturnedGoods v-if="toggleReturn" @close="toggleReturn=!toggleReturn" class="absolute top-0 left-0  bg-white w-full"/>
   <OverdueGood v-if="toggleOverdue" @close="toggleOverdue=!toggleOverdue " class="absolute top-0 left-0  bg-white w-full"/>
   <ReturnDetail v-if="toggleDetails" @close="toggleDetails=!toggleDetails"  class="absolute top-0 left-0  bg-white w-full"/> 

</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, watch } from "@vue/runtime-core"
import ReturnDetail from "./ReturnDetail.vue"
import DropDown from "../../components/DropDown.vue"
import TableView from "../../components/TableView.vue";
import DateRange from "../../components/DateRange.vue";
import ReturnedGoods from "./ReturnGoods.vue"
import OverdueGood from "./OverdueGood.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import clock from "../../assets/clock.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";

const store = useStore()
onBeforeMount(() => {  
    store.dispatch('fetchReturnedGoods')
    store.dispatch('fetchSivToAccept')
    store.dispatch('fetchOverdue')
})

let returnedList = ref(store.getters.returnedList)
let staticStoreList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList.store)))
staticStoreList.value.unshift({ id: 0, storeName: "ALL"});
const overdueList =  computed(() =>JSON.parse(JSON.stringify(store.getters.overdueList)).length)
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))


const Headers = [
  { text: "GRV ID", value: "grvNumber" , sortable: true },
  { text: "SIV ID", value: "siv.sivNumber",sortable: true},
  { text: "Returned by", value: "returnedBy"},
  { text: "Recipient", value: "recipient"},
  { text: "Date", value: "date"},
  { text: "Actions", value: "operation"},
];

const toggleOverdue = ref(false)
const toggleReturn = ref(false)
const toggleDetails = ref(false)
const toggledatepicker = ref(false)

const storefilter = ref()
const searchFilter = ref('')

const filterVars = ref({store:null,init:null,end:null})
let filter = ref(1)

watch(toggleOverdue, async (newQuestion, oldQuestion) => {

    if ( oldQuestion===true ) {
        returnedList = ref(store.getters.returnedList)
        filter.value = new Date()
    }
})
watch(toggleReturn, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchReturnedGoods')
        .then(()=>{
            returnedList = ref(store.getters.returnedList)
            filter.value = new Date()
        })
    }
})
watch(toggleDetails, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchReturnedGoods')
        .then(()=>{
            returnedList = ref(store.getters.returnedList)
            filter.value = new Date()
        })
    }
})

const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const setstores=(val)=>{
    storefilter.value = val.storeName
    filterVars.value.store = val.id
    filterAll();
}

const filterAll = ( ) =>{
    returnedList = ref(store.getters.returnedList)
    if (filterVars.value.store !== null && filterVars.value.store !== 0) {
        returnedList.value = returnedList.value.filter((user)=>user.storeId === filterVars.value.store)   
    }
     if (filterVars.value.init !== null){

        returnedList.value = returnedList.value.filter((user)=>new Date(user.returnDate) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )
    }
    if (filterVars.value.end !== null){

        returnedList.value = returnedList.value.filter((user)=>new Date(user.returnDate) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
    if (searchFilter.value !== ''){
        returnedList.value = returnedList.value.filter((user)=>(user.grvNumber).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    
    filter.value = new Date()

}



const detailitem=(val)=>{
        store.dispatch('getReturnDetail',{id:val.id,sivId:val.sivId})
        .then(()=>{ 
            toggleDetails.value = !toggleDetails.value
        }) 
    
}

const deleteitem=(val)=>{
    if (userRole.value === 'MASTER_STORE_MANAGER' ||userRole.value === 'BRANCH_STORE_MANAGER'){
        
        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteGrv3',val.id)
                            .then(()=>{
                                store.dispatch('fetchReturnedGoods')
                            })
                            .then(()=>{
                                returnedList = ref(store.getters.returnedList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
    }
}

const showScan=(val)=>{
    window.open(val.grvFile);
    window.open(val.rvFile);
}

const restrict = computed(()=>{
  
  if ( userRole.value!=='BRANCH_STORE_MANAGER'|| userRole.value!=='MASTER_STORE_MANAGER' ) {
        return false
    }
    else{
        return true
    }
     
})
</script>



<style lang="postcss">
    
</style>