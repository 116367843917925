<template>
    <div  >

    <!-- backarrow -->
    <div class="flex items-center ml-3 mt-5 ">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5 cursor-pointer" @click="$emit('close')">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer " @click="$emit('close')"> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">ADD CATEGORY
            <div class="h-2 w-28 rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{newid}}</p>
                <p class="text-[9px]  text-gold_yellow ">~ Category ID</p>
            </div>
           
            <div class="flex w-[750px]   mt-10 ">
              
                    <input type="text" v-model="catagoryname" placeholder="Category name..." class="inputbox nav_blue mr-10">
                   
                
            </div>
        </div>
						
        <div class="mt-28">
            <button v-if="!isLoading" :disabled="activate"  @click.prevent="submitCatagory" class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import { useStore } from "vuex"
import backarrow from "../../assets/arrowback.svg"
import attachment from "../../assets/attachment.svg"
import Spinner from "../../components/Spinner.vue"

const close = defineEmits("close")

const store = useStore()
const catagoryname = ref()
const catagId = ref()
const isLoading = ref(false);

const newCategory =  computed(() =>JSON.parse(JSON.stringify(store.getters.newCategory)))
let newid = Number(newCategory.value)+1
newid= 'CID/' + newid;
// if (newid<10) {
//         catagId.value=('CID/00'+newid)
//     }else if(newid=>10 && newid<100) {
//         catagId.value=('CID/0'+newid)
//     }else{
//         catagId.value=('CID/'+newid)
//     }


const activate = computed(()=>{
    if(!catagoryname.value){
        return true;
    }else{
        return false;
    }
})


const submitCatagory=()=>{
    isLoading.value = true;
     store.dispatch('addCatagory',
        {id:newid,name:catagoryname.value})
     .then(()=>{ 
          store.dispatch('fetchCatagory')
          store.dispatch('fetchStaticList')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });
}
</script>

