<template >
    <div class="   bg-black  bg-opacity-50 z-20 flex justify-center">
        <div class="bg-white h-1/3 w-2/5 mt-56  rounded-lg p-3 relative">
            <h3 class="text-xl text-center font-semibold mt-">REMARK</h3>
            <div class="mx-10 mt-3">
                {{props.remark}}
            </div>

        <div class="absolute bottom-3 left-[178px]">
            <button type="submit" @click.prevent="submit" :disabled="activate" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold p-2 px-5 rounded-lg ">Ok, got it</button>
        </div>
        </div>
    </div>
</template>

<script setup>


const emit = defineEmits(["close"])

const props = defineProps({
  remark: String,
})


const submit = ()=>{
   emit("close")
}

</script>
