<template>
    <div  >

    <!-- backarrow -->
    <div class="flex items-center ml-3 mt-5">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5 cursor-pointer" @click="$emit('close')">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer " @click="$emit('close')"> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <div class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">UPDATE SUB-CATEGORY
            <div class="h-2 w-[180px] rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{props.olddata.id}}</p>
                <p class="text-[9px]  text-gold_yellow ">~ Sub-category ID</p>
            </div>
           
            <div class=" w-[750px]   mt-10 ">
                    <input type="text" v-model="subcatagoryname" placeholder="Sub-category name..." class="inputbox nav_blue mr-10 mb-4">
                    <div class="relative">
                        <drop-down placeholderp="Category..." :items="staticList.category" displayable="name" @selected="(selected)=>setCatagory(selected)" />
                        <input v-if="category===''" type="text" disabled :value="props.olddata.category.name" class="bg-main_gray absolute top-2.5 left-4 text-[15px] w-64">            
                    </div>    
            </div>
        </div>
						
        <div class="mt-28">
            <button  v-if="!isLoading" :disabled="activate" @click.prevent="submitSubcatagory" class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </div>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import Spinner from "../../components/Spinner.vue"
import attachment from "../../assets/attachment.svg"
import { useStore } from "vuex"

const close = defineEmits("close")

const props = defineProps({
  olddata:Object,
})

const subcatagoryname = ref(props.olddata.name)
const category = ref('')
const categoryName = ref()
const isLoading = ref(false);

const store = useStore()
const subCatagoryList =  computed(() =>JSON.parse(JSON.stringify(store.getters.subCatagoryList)))

const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
staticList.value.category.length>1 && staticList.value.category[0].name==='ALL'?staticList.value.category.shift():null

const setCatagory=(val)=>{
    category.value = val.id 
    categoryName.value = val.name
}

const activate = computed(()=>{
    if(props.olddata.name!==subcatagoryname.value||
        category.value!==''?props.olddata.category.name !== categoryName.value:false){

        return false;
    }else{
        return true;
    }
})

const submitSubcatagory=()=>{
    isLoading.value = true;
     store.dispatch('UpdateSubcatagory',
        {id:props.olddata.id,name:subcatagoryname.value,category:category.value?category.value:props.olddata.category.id})
     .then(()=>{ 
          store.dispatch('fetchCatagory')
       store.dispatch('fetchStaticList')
       isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });
}

</script>

<style lang="postcss">


</style>