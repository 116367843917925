<template>
<div class="w-20">

  <Listbox v-model="selectedPerson" v-slot="{ open }">
    <ListboxButton  @focus="selectedPerson?$emit('selected',selectedPerson):null" class="z-0 h-10 flex justify-between items-center text-[15px] relative bg-main_gray  px-4 inputbox"
    :class="{'rounded-b-none':open , 'text-gray-400':!selectedPerson,'w-40':width==='false','w-44':width==='lilwider'}">
       
        {{!selectedPerson? placeholder : selectedPerson[display].length>10 && (width==='false' || width==='lilwider') ? selectedPerson[display].substring(0,10)+"...":selectedPerson[display] }}
        <img :src="arrowdown" alt="arrowdown" class="h-2"  :class="{'rotate-180 transition ease-in duration-100':open}">
    </ListboxButton>
    <div static class="rounded-lg   ">
      
      <ListboxOptions :class="{'overflow-hidden overflow-y-scroll ': itemLength > 6}" class="absolute z-30 h-40   " >
        <ListboxOption 
          v-for="person,index in people"
        :key="person.id"
        :value="person"
         class="  w-[330px] cursor-pointer   text-gray-800 gap-1 ui-active:bg-blue-500 ui-active:text-white ui-not-active: bg-main_gray  ui-not-active:text-black border-t-2 px-4"
        :class="{'rounded-b-lg':index=== itemLength-1,'w-[160px]':width==='false','w-[176px]':width==='lilwider'}"
        >   
            
         <p v-if="person[display].length>20 && width==='false' "> {{ person[display].substring(0,20)+"..." }}</p>
         <p v-else-if="person[display].length>23 && width==='lilwider'"> {{ person[display].substring(0,23)+"..." }}</p>
         <p v-else-if="person[display].length>32"> {{ person[display].substring(0,32)+"..." }}</p>
         <p v-else> {{ person[display] }}</p>
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</div>
</template>

<script setup>
  import { computed, ref } from 'vue'
  import arrowdown from "../assets/arrowdown.svg"
  import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  } from '@headlessui/vue'


defineEmits("selected")

const props = defineProps({
  placeholderp: String,
  items:String,
  width:Number,
  displayable:String
})  
const {placeholderp,items,width,displayable} =props
  const people = items;
  const selectedPerson = ref()
  const placeholder = ref(placeholderp)
  const itemLength = computed(() => items.length)
 const display=ref(displayable)

</script>