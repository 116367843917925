<template >
    <div>
    <!-- backarrow -->
        <div class="flex items-center mt-5 ml-5 cursor-pointer" @click="$emit('close')">
            <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
            <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
        </div>
        <div class="grid grid-cols-2 mb-8">
            
            <div class="flex items-center">

            <div class=" font-black text-[25px] mt-[90px] ml-5 ">OVERDUE GOODS
                <div class="h-2 w-28 rounded-full bg-gold_yellow "></div>
            </div>
        
            
            </div>
            
        
        </div>
        <!-- table -->
        <TableView showdoc="Needed" showeye="notNeeded" showdel="notNeeded" @delete="deleteitem"   @displayScan="showdocitem" @detail="detailitem" :headerList="Headers" :itemlist="overdueList"/>
        
   
    </div>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import { useStore } from "vuex";
import backarrow from "../../assets/arrowback.svg"
import TableView from "../../components/TableView.vue";

const close = defineEmits("close")

const store = useStore()
onBeforeMount(() => {  
    store.dispatch('fetchOverdue')
})
const overdueList =  computed(() =>JSON.parse(JSON.stringify(store.getters.overdueList)))

const Headers = [
  { text: "Item ID", value: "item.id" , sortable: true },
  { text: "Part #", value: "item.partNumber"},
  { text: "Item name", value: "item.itemName"},
  { text: "UoM", value: "item.uom.abbreviation"},
  { text: "Quantity", value: "balance"},
  { text: "Withdrawn by", value: "siv.issuedTo"},
  { text: "Return date", value: "siv.date",sortable: true},
];




</script>



<style lang="postcss">
    
</style>