<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer" > Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">UPDATE RECIVED GOODS 
            <div class="h-2 w-[180px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <input type="text" v-model="grvn"   placeholder="GRV doc #…" required class="inputbox nav_blue mt-10 mb-6">
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                        <input type="text" v-model="Suppliername"   placeholder="Supplier's name..." required class="inputbox  mb-3">
                        <input type="text" v-model="deliveredby"   placeholder="Delivered by..." required class="inputbox  mb-3">
                        <input type="text" v-model="purchaserequest"   placeholder="Purchase requisition #..." required class="inputbox  mb-3 ">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.grvscanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="grvscan.name?grvscan.name:grvscan.split('---')[1]" disabled  placeholder="Attach GRV scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="grvscanfile" accept=".pdf,image/*" @change="uploadgrv" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                   
                        <input type="text" v-model="invoicen"   placeholder="Invoice #..." required class="inputbox  mb-3">
                        <input type="text" v-model="receivedby"   placeholder="Received by..." required class="inputbox  mb-3">
                       <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="deliverydate" :upperLimit="new Date()" placeholder="Delivery date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                       <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.invoicescanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="invoicescan.name?invoicescan.name:invoicescan.split('---')[1]" disabled  placeholder="Attach invoice scan..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="invoicescanfile" accept=".pdf,image/*" @change="uploadinvoice" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in itemList" :key="item" class="mb-2"> 
            <div class="flex">
                <!-- <div class=" relative inputbox  w-40 mr-2">
                    <img :src="magnifyingglass" alt="magnifyingglass" class="absolute right-3 h-3 mt-1.5">
                    <input type="text" v-model="allItems[index].itemId" @input="searchItem(index)"  placeholder="Item ID..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                </div> -->
                <ItemSearch  :items="staticItemList" :defaultValue="allItems[index].itemId" displayable="name" @selected="(selected)=>searchItem(selected,index)"  width="false" class="mr-24 mx-1 "/>
                <input type="text" v-model="allItems[index].uom" readonly   placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="number" v-model="allItems[index].balance" @input="(event)=>checkBalance(index,event)"   placeholder="Qty..." required class="inputbox ml-3 mr-3 w-36">
                <input type="number" v-model="allItems[index].price"   placeholder="Unit price..." required class="inputbox  mr-3 w-36">
                <input type="text" v-model="allItems[index].remark"   placeholder="Remark..." class="inputbox  mr-3 w-56">
                <button  v-if="index>0 || itemList!==1"  @click.prevent="deductList(index)" class="bg-red-500 text-white text-3xl w-9 h-9 mx-2 items-center  rounded-full ">-</button>
                <button v-if="index===itemList-1" @click.prevent="addItemInputs" class=" text-white text-3xl w-9 h-9 mx-2 items-center  pb-3 rounded-full "
                :class="{'bg-nav_blue':itemList!==1,'bg-[#8F8F8F]':itemList===1}">+</button>
            </div>
                <p class="text-[12px] block">{{allItems[index].desc}}</p>
        </div>

    <div class="mt-20">
            <button  v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold  rounded-lg p-2 px-6 ">Update</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
    </div>
    </form>    
      
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import Datepicker from 'vue3-datepicker'
import ItemSearch from "@/components/ItemSearch.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const close = defineEmits("close")
const store = useStore()

onBeforeMount(() => {  
    store.dispatch('fetchGoodsRecived')
})

const props = defineProps({
 currentTr: Object
})

const currentTransaction = ref(props.currentTr)
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
const staticItemList =  ref(JSON.parse(JSON.stringify(store.getters.itemList)))


const grvn = ref(currentTransaction.value.grvNumber)
const Suppliername = ref(currentTransaction.value.supplierName)
const deliveredby = ref(currentTransaction.value.deliverBy)
const grvscan = ref(currentTransaction.value.grvFile)
const invoicen = ref(currentTransaction.value.invoiceNumber)
const receivedby = ref(currentTransaction.value.receivedBy)
const invoicescan = ref(currentTransaction.value.invoiceFile)
const purchaserequest = ref(currentTransaction.value.pr)
const deliverydate = ref(new Date(currentTransaction.value.deliveredDate))


const itemId = ref()
const uom = ref()
const balance = ref()
const stockQty = ref()
const price = ref()
const remark = ref()
const isLoading = ref(false);


const allItems = ref([
    {itemId:'',desc:'',uom:'',balance:'',presetQty:'',stockQty:'',price:'',remark:''}
])

const setUom =(selected,index)=>{
    allItems.value[index].uom = selected.name
}

const itemList =computed(()=>{
   return allItems.value.length===0 ?allItems.value.length + 1:allItems.value.length
})
    
const addItemInputs = ()=>{
    allItems.value.push({itemId:'',desc:'',uom:'',balance:'',presetQty:'',stockQty:'',price:'',remark:''})
}


const deductList=(i)=> {
    
    if (allItems.value[i].stockQty===''||Number(allItems.value[i].stockQty) > Number(allItems.value[i].presetQty )) {
        
        allItems.value = allItems.value.filter((_, index) => index !== i)
        
        }
}

const uploadgrv =(event)=>{ 
 grvscan.value =  event.target.files[0]
}
const uploadinvoice =(event)=>{ 
 invoicescan.value =  event.target.files[0]
}

const isListEmpty = computed(()=>{
         for (let index = 0; index < allItems.value.length; index++) {
            if( !allItems.value[index].itemId|| !allItems.value[index].uom|| !allItems.value[index].balance || !allItems.value[index].price){
                return true
            }
            
        }
})
const activate = computed(()=>{
    if(!grvn.value || !Suppliername.value || !deliveredby.value || !grvscan.value || !invoicen.value ||
     !receivedby.value|| !invoicescan.value|| !purchaserequest.value|| !deliverydate.value ||isListEmpty.value){
        return true;
    }else{
        return false;
    }
})

const checkBalance =(index,event)=>{
    if((Number(allItems.value[index].balance)>=0)){
        //  allItems.value[index].balance = Number(event.data);
        
        if ((Number(allItems.value[index].stockQty) < Number(allItems.value[index].presetQty )) 
             
            //  ||allItems.value[index].stockQty === undefined 
             ) {

                if((Number(allItems.value[index].balance)>=Number(allItems.value[index].presetQty )-Number(allItems.value[index].stockQty )) ){

                }else{
            allItems.value[index].balance = null
                 }
            }


    }else{
        allItems.value[index].balance = null
    }
}


const searchItem=(selected,val)=>{
    allItems.value[val].itemId =selected
    for (let index = 0; index < staticItemList.value.length; index++) {
        if((staticItemList.value[index].id).toLowerCase() === (allItems.value[val].itemId).toLowerCase()) {
            allItems.value[val].uom = staticItemList.value[index].uom.abbreviation
            allItems.value[val].desc = staticItemList.value[index].name
            allItems.value[val].stockQty = staticItemList.value[index].balance
            return 
        } else{
            allItems.value[val].uom = ''
            allItems.value[val].desc = ''
            
        } 
    }
   
}

const setGrv = ()=>{
    
   
        allItems.value=[]
        allItems.value=[]
        addItemInputs();
    
     for (let index = 0; index < currentTransaction.value.Grv1Transaction.length; index++) {
        // const element = array[index];
        allItems.value[index].itemId = currentTransaction.value.Grv1Transaction[index].item.stock.item.id
        allItems.value[index].uom = currentTransaction.value.Grv1Transaction[index].item.stock.item.uom.abbreviation
        allItems.value[index].balance = currentTransaction.value.Grv1Transaction[index].item.receivedQty
        allItems.value[index].presetQty = currentTransaction.value.Grv1Transaction[index].item.receivedQty
        allItems.value[index].price = currentTransaction.value.Grv1Transaction[index].item.price
        allItems.value[index].remark = currentTransaction.value.Grv1Transaction[index].item.remark
        allItems.value[index].desc = currentTransaction.value.Grv1Transaction[index].item.stock.item.itemName

            for (let i = 0; i < staticItemList.value.length; i++) {
            if((staticItemList.value[i].id).toLowerCase() === (allItems.value[index].itemId).toLowerCase()) {
                    
                    allItems.value[index].stockQty = staticItemList.value[i].balance
                    
                } 
            }

        index < currentTransaction.value.Grv1Transaction.length-1 && addItemInputs(index);
    }

   
}
setGrv ()


const submit =()=>{
   const formData = new FormData();
    formData.append("id", currentTransaction.value.id);
    grvn.value && formData.append("grvNumber", grvn.value);
    Suppliername.value && formData.append("supplierName", Suppliername.value);
    deliveredby.value && formData.append("deliverBy", deliveredby.value);
    grvscan.value && formData.append("GRV", grvscan.value);
    invoicen.value && formData.append("invoiceNumber", invoicen.value);
    receivedby.value && formData.append("receivedBy", receivedby.value);
    invoicescan.value && formData.append("INVOICE", invoicescan.value);
    purchaserequest.value && formData.append("pr", purchaserequest.value);
    deliverydate.value && formData.append("deliveredDate", deliverydate.value);
    allItems.value && formData.append("items", JSON.stringify(allItems.value));

    isLoading.value = true;
    store.dispatch('updateGrv',
        formData
        )
        .then(()=>{ 
          store.dispatch('fetchGoodsRecived')
          store.dispatch('fetchInventory')
          isLoading.value = false;
        }) 
        .then(()=>{ 
            close('close')
        }) 
        .catch((error) => {
            isLoading.value = false;
        });
        
                 


     
}


</script>

<style lang="postcss">

</style>