<template>
    <div  class="mt-5">

    
        <!-- rest dynamic modal content -->
    <div class="mx-7">
        <div class="flex justify-between">
        <div class=" font-black text-[30px] mt-16 ">EDIT PROFILE
            <div class="h-2 w-[110px] rounded-full bg-gold_yellow "></div>
        </div>
       
        </div>
        <!--   input fields  -->
        <div class="my-8 flex">
                    <div class="space-y ">
                    
                        <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg ">
                            <p class="text-gold_yellow">{{userId}}</p>
                            <p class="text-[9px]  text-gold_yellow ">-User ID</p>
                        </div>
                        <div >
                            <input type="text" v-model="fullname" readonly  id="fullname" class="inputbox bg-gray-300 nav_blue mb-3 mt-4">
                        </div>
                        <div >
                            <input type="text" v-model="email" readonly  id="email" class="inputbox bg-gray-300 mb-3">
                        </div>
                        <div >
                            <input type="text" v-model="phoneNumber"   id="phoneNumber" class="inputbox nav_blue mb-3">
                        </div>
                        <div >
                            <input type="text" autocomplete="off" v-model="currentPassword" placeholder="Current password"  id="currentPassword" class="inputbox nav_blue mb-3 mt-4">
                        </div>
                        <div >
                            <input type="text" autocomplete="off" v-model="password" placeholder="New password"  id="password" class="inputbox nav_blue mb-3 ">
                        </div>
                        <div >
                            <input type="text" v-model="confirmPassword"  placeholder="Confirm password" id="confirmPassword" class="inputbox ">
                        </div>
                         <p class="text-xs">Leave this empty if you don't want to change your password.</p>
                        
                    </div>
                    <div class=" mx-10  mt-44">
                            <div type="text"   class="w-36 h-32 bg-main_gray text-nav_blue outline-none rounded-lg" :class="{'w-32 ml-2':profilePic}">

                            <img :src="profilePic?profilePic:logo" alt="attachment" class="w-full h-full object-cover" >
                            </div>
                             <div class="mt-6">
                                <button type="submit" @click.prevent="$refs.invoicescanfile.click()"  class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-lg font-semibold  rounded-lg p-2 px-3 ">Upload photo</button>
                            </div>
                            <input type="file" ref="invoicescanfile" accept="image/*" @change="uplaodProfile" style="display: none" />   
                        </div>
                   
                   
        </div>
         <button type="submit"  v-if="!isPicLoading || !isProfileLoading" :disabled="!activate" @click.prevent="updateProfile"  class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-lg font-semibold  rounded-lg p-2 px-3 ">UPDATE</button>
         <button type="submit"  v-else :disabled="true"   class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-lg font-semibold  rounded-lg p-2 px-3 ">
            <Spinner/>
        </button>
    </div>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import { useStore } from "vuex";
import Swal from 'sweetalert2'
import  logo  from '../../assets/Tsemex_brand.png'
import Spinner from "../../components/Spinner.vue"


const store = useStore()

const userName =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_NAME)))
const currentuserId =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ID)))
const userPhoneNumber =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_PHONE)))
const userEmail =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_EMAIL)))
const userImg =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_IMG)))


const userId = ref(currentuserId?currentuserId.value:null)
const fullname = ref(userName?userName.value:null)
const email = ref(userEmail?userEmail.value:null)
const phoneNumber = ref(userPhoneNumber?userPhoneNumber.value:null)
const currentPassword = ref()
const password = ref()
const confirmPassword = ref(null)
const profileImage =ref()

const profilePic = ref(userImg?userImg.value:null)

const isProfileLoading = ref(false);
const isPicLoading = ref(false);

const uplaodProfile =(event)=>{ 
    profileImage.value =  event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(profileImage.value);
    reader.onload = (e) => {
        profilePic.value = e.target.result;
    };
         
}
const updateProfile =(event)=>{ 
    const formData = new FormData();
    profileImage ? formData.append("PROFILE", profileImage.value):formData.append("PROFILE", profilePic.value);
    phoneNumber.value && formData.append("phoneNumber", phoneNumber.value);
   
    isProfileLoading.value = true;
     store.dispatch('updateMyProfile', formData )
            .then(() => {
                isProfileLoading.value = false;
            })
            .catch((error) => {
                isProfileLoading.value = false;
            });

     if (password.value !== undefined &&currentPassword.value !== undefined && confirmPassword) {
        if (password.value === confirmPassword.value) {
            isPicLoading.value = true;
             store.dispatch('updatePassword', {password:password.value,currentPassword:currentPassword.value })
                  .then(() => {
                        isProfileLoading.value = false;
                    })
                    .catch((error) => {
                        isProfileLoading.value = false;
                    });
        }else{
            Swal.fire({
            width: 300,
            color: 'white',
            text: "Password don't matched.",
            position: 'top',
            background: '#F96666',
            timer: 2500,
            showConfirmButton:false
        })
        }
        
     }

    
}

const activate = computed(()=>{
    
   
    if(currentPassword.value === null||userPhoneNumber.value!==phoneNumber.value ||password.value===confirmPassword.value ||profileImage.value){
        return true;
    }else{
        return false;
    }
})







</script>

<style lang="postcss">


</style>