<template>
  <div class="h-full w-full  ">
    <router-view />
    <!-- <MainContent :key="mainkey" v-if="pageswitch" :Selected="currentSelected" />
    <SignUp v-else/> -->
  
  </div>
  
</template>

<script setup>
import MainContent from "./views/MainContent.vue"
import SignUp from "./views/SignUp/SignUp.vue"
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { computed, onBeforeMount } from "@vue/runtime-core";
import HTTP from "./Axios/Axios";


const store = useStore()
const router = useRouter();
//  get the persisted userdata froim local storage
let TOKEN =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_TOKEN)))
let persisteduser = JSON.parse(localStorage.getItem('userstore'))
let location =window.location.pathname
const mainkey = ref(new Date())
// localhost:8081/Verification/:eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRla2xpdGZpc3NlaGEyN0BnbWFpbC5jb20iLCJpYXQiOjE3MDI1NTMwMDcsImV4cCI6MTcwMzQxNzAwN30.DuH-9zb6b9cGHPmQN6Zmi-KGg0hxmml6F9L8CLstGV8

if ( persisteduser && TOKEN.value ==='' && location.split('/:')[0]!=="/Verification" ) {
  store.commit('Sign_Up',persisteduser)
  TOKEN =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_TOKEN)))
  mainkey.value = new Date()
}

  
  TOKEN =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_TOKEN)))

  if(TOKEN.value!=='' ){
    // router.push('/Home')
  }else{
    // router.push('/')
  }

// **********************adds Token to the Axios instance*****************//
onBeforeMount(() => {
  
 const TOKEN =  computed(() =>store.getters.USER_TOKEN)
  HTTP.interceptors.request.use(
    config => {
      if (TOKEN) {
         config.headers["Authorization"] = "Bearer " + TOKEN.value
    }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
    );
    }) 

</script>
