import { createStore } from 'vuex'
import staticList from "./StasticStates/StaticList"
import transactionList from "./TransactionStates/TransactionStates"
import SignUpState from "./SignUp/SignUp"

export default createStore({
 
  modules: {
    staticList,
    transactionList,
    SignUpState
  }
})
