import HTTP from "../../Axios/Axios";
import Swal from 'sweetalert2'

const showAlert = (val) => {
  Swal.fire({
    width: 300,
    color: 'white',
    text: val[0],
    position: 'top',
    // icon: 'success',
    background: val[1]==='success'? '#68B984':'#F96666',
    timer: 1500,
    showConfirmButton:false
    // confirmButtonText: 'no'
  })
}

const state = {
 inventory:[],
 purchaseorder:[],
 goodsrecived:[],
 requisition:[],
 interstoretransfer:[],
 pending:[],
 stvtoaccept:[],
 sivtoaccept:[],
 returnedgoods:[],
 overdue:[],
 // -------------------------------------------------------//
 grvdetail:{},
 podetail:{},
 istvdetail:{},
 sivdetail:{},
 srdetail:{},

 filteredinventory:[],


};
const getters = {
  inventoryList: (state)=>{
      return state.inventory
    },
  FilteredinvList: (state)=>{
      return state.filteredinventory
    },
  poList: (state)=>{
    return state.purchaseorder
  },
  grvList: (state)=>{
    return state.goodsrecived
  },
  sivList: (state)=>{
    return state.requisition
  },
  stvList: (state)=>{
    return state.interstoretransfer
  },
  pendingList: (state)=>{
    return state.pending
  },
  stvToAccept: (state)=>{
    return state.stvtoaccept
  },
  sivToAccept: (state)=>{
    return state.sivtoaccept
  },
  returnedList: (state)=>{
    return state.returnedgoods
  },
  overdueList: (state)=>{
    return state.overdue
  },

// -------------------------------------------------------//

  grvDetail: (state)=>{
    return state.grvdetail
  },
  poDetail: (state)=>{
    return state.podetail
  },
  istvDetail: (state)=>{
    return state.istvdetail
  },
  sivDetail: (state)=>{
    return state.sivdetail
  },
  srDetail: (state)=>{
    return state.srdetail
  },
  
  
 
};

// -------------------------------------------------------//


const mutations = {
    PURCHASE_ORDER:(state,item) =>(state.purchaseorder = item),
    GOODS_RECIVED:(state,item) =>(state.goodsrecived = item),
    REQUISITION:(state,item) =>(state.requisition = item),
    INVENTORY:(state,item) =>(state.inventory = item),
    FILTERED:(state,item) =>(state.filteredinventory = item),
    STORE_TRANSFER:(state,item) =>(state.interstoretransfer = item),
    PENDING:(state,item) =>(state.pending = item),
    STV_TO_ACCEPT:(state,item) =>(state.stvtoaccept = item),
    SIV_TO_ACCEPT:(state,item) =>(state.sivtoaccept = item),
    RETURNED_GOODS:(state,item) =>(state.returnedgoods = item),
    OVERDUE:(state,item) =>(state.overdue = item),

// -------------------------------------------------------//

    ADD_GRV:(state,grv)=>{state.goodsrecived.push(grv)},
    ADD_PO:(state,po)=>{state.purchaseorder.push(po)},
    ADD_STV:(state,stv)=>{state.interstoretransfer.push(stv)},
    // ADD_STRV:(state,strv)=>{state.purchaseorder.push(strv)},
    ADD_SIV:(state,siv)=>{state.requisition.push(siv)},
    ADD_SRV:(state,srv)=>{state.returnedgoods.push(srv)},
   
// -------------------------------------------------------//

    GRV_DETAIL:(state,detail)=>{state.grvdetail = detail},
    PO_DETAIL:(state,detail)=>{state.podetail = detail},
    ISTV_DETAIL:(state,detail)=>{state.istvdetail = detail},
    SIV_DETAIL:(state,detail)=>{state.sivdetail = detail},
    RETURN_DETAIL:(state,detail)=>{state.srdetail = detail},
   
// -------------------------------------------------------//

    DELETE_GRV:(state,id)=>{state.goodsrecived=state.goodsrecived.filter((item)=>item.id !== (id))},
    DELETE_SIV:(state,id)=>{state.requisition=state.requisition.filter((item)=>item.id !== (id))},
    DELETE_STV:(state,id)=>{state.interstoretransfer=state.interstoretransfer.filter((item)=>item.id !== (id))},
    DELETE_GRV3:(state,id)=>{state.returnedgoods=state.returnedgoods.filter((item)=>item.id !== (id))},
    DELETE_PO:(state,id)=>{state.purchaseorder=state.purchaseorder.filter((item)=>item.id !== (id))},
  
};


// -------------------------------------------------------//



const actions = {
    async fetchInventory({commit}){
      try {
        const resp =await HTTP.get('inventory/getAll')
        commit('INVENTORY',resp.data.inventory)
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchPurchaseOrder({commit}){
      try {
          const resp =await HTTP.get('purchaseOrder/getAll')
          commit('PURCHASE_ORDER',resp.data)
          
        } catch (error) {
          if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
  
        }
    },
    async fetchGoodsRecived({commit}){
      try {
        const resp =await HTTP.get('grv1/getAll')
        commit('GOODS_RECIVED',resp.data.grv1)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchReqisition({commit}){
      try {
        const resp =await HTTP.get('siv/getAll')
          commit('REQUISITION',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchStoreTransfer({commit}){
      try {
        const resp =await HTTP.get('istv/getAll')
        commit('STORE_TRANSFER',resp.data.istv)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchPending({commit}){
      try {
        const resp =await HTTP.get('istv/getPendingIstv')
        commit('PENDING',resp.data.istv)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchReturnedGoods({commit}){
      try {
        const resp =await HTTP.get('grv3/getAll')
        commit('RETURNED_GOODS',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchOverdue({commit}){
      try {
        const resp =await HTTP.get('grv3/overdue')
        commit('OVERDUE',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
    async fetchStvToAccept({commit}){
      try {
        const resp =await HTTP.get('istv/getIstvInfo')
        commit('STV_TO_ACCEPT',resp.data.istv)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }

    },
    async fetchSivToAccept({commit}){
      try {
        const resp =await HTTP.get('siv/sivInfo')
        commit('SIV_TO_ACCEPT',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },

// ***************************************************//

    async addGrv ({commit},grv){
      try {
        const resp =await HTTP.post('grv1/create',grv)
        commit('ADD_GRV',resp.data.grv1)
        showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
     },
    async updateGrv ({commit},grv){
      try {
        const resp =await HTTP.patch('grv1/update',grv)
        showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
     },
    
   async addPurchaseOrder ({commit},payload){
      try {
        const resp =await HTTP.post('purchaseOrder/create',payload)
        console.log(resp);
        commit('ADD_PO',resp.data.purchaseOrders)  
        showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
   async UpdatePurchaseOrder ({commit},payload){
      try {
        const resp =await HTTP.patch('purchaseOrder/update',payload)
        showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },
   
    async addStoreTransfer ({commit},payload){
        try {
          const resp =await HTTP.post('istv/create',payload)
          commit('ADD_STV',resp.data.istv)  
          showAlert([resp.data.message, 'success'])
        } catch (error) {
          if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
  
        }
    },
   async UpdateStoreTransfer ({commit},payload){
      try {
        const resp =await HTTP.patch('istv/update',payload)
        showAlert([resp.data.message, 'success'])  
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
      
    },

   async addStoreReciving ({commit},payload){
     try {
       const resp =await HTTP.post('grv2/create',payload)
       showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
    },

   async addStoreIssue ({commit},payload){
       try {
         const resp =await HTTP.post('siv/create',payload)
         commit('ADD_SIV',resp.data.siv)  
         showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async UpdateStoreIssue ({commit},payload){
       try {
         const resp =await HTTP.patch('siv/update',payload)
         showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }

   },

   async addStoreReturn ({commit},payload){
       try {
         const resp =await HTTP.post('grv3/create',payload)
         commit('ADD_SRV',resp.data.grv3)  
         showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async UpdateStoreReturn ({commit},payload){
       try {
         const resp =await HTTP.patch('grv3/update',payload)
         showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
     
   },
   

// -------------------------------------------------------//

   async getGrvDetail ({commit},payload){
       try {
         const resp =await HTTP.post('grv1/getdetail',payload)
         commit('GRV_DETAIL',resp.data.grv1)  
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async getPoDetail ({commit},payload){
      try {
         const resp =await HTTP.post('purchaseOrder/getdetail',payload)
         commit('PO_DETAIL',resp.data)  
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async getIstvDetail ({commit},payload){
      try {
         const resp =await HTTP.post('istv/getdetail',payload)
         commit('ISTV_DETAIL',resp.data.istv)  
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async getSivDetail ({commit},payload){
     try {
       const resp =await HTTP.post('siv/getdetail',payload)
       commit('SIV_DETAIL',resp.data)  
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async getReturnDetail ({commit},payload){
     try {
       const resp =await HTTP.post('grv3/getdetail',payload)
       commit('RETURN_DETAIL',resp.data)  
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }

      }
   },
   async getFilterInventory ({commit},payload){
     try {
       const resp =await HTTP.post('inventory/getFilterInventory',payload)
       commit('FILTERED',resp.data.inventory)
        
      } catch (error) {
        console.log(error);
        // if (error.response.statusText == "Unauthorized") {
        //    router.push('/')
        // } else {
        //   showAlert(["Error: " +error.response.data.message])
        // }

      }

   },

   // -------------------------------------------------------//

  async deleteGrv ({commit},payload){
    try {
      const resp =await HTTP.delete('grv1/delete',{data: {id: payload }})
      commit('DELETE_GRV',payload)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
    }
  },
  async deleteSiv ({commit},payload){
    try {
      const resp =await HTTP.delete('siv/delete',{data: {id: payload }})
      commit('DELETE_SIV',payload)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
     
    }
  },
  async deleteStv ({commit},payload){
    try {
      const resp =await HTTP.delete('istv/delete',{data: {id: payload }})
      commit('DELETE_STV',payload)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
    }
  },
  async deleteStrv ({commit},payload){
    try {
      const resp =await HTTP.delete('grv2/delete',{data: {id: payload }})
      // commit('DELETE_STV',payload)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
    }
  },
  async deleteGrv3 ({commit},payload){
    try {
      const resp =await HTTP.delete('grv3/delete',{data: {id: payload }})
      commit('DELETE_GRV3',payload)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
    }
  },
  async deletePo ({commit},payload){
    try {
      const resp =await HTTP.delete('purchaseOrder/delete',{data: payload})
      commit('DELETE_PO',payload)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          showAlert(["Error: " +error.response.data.message])
        }
    }
  },
  // async deleteOverdue ({commit},payload){
  //   const resp =await HTTP.delete('overdue/delete',{data: {id: payload }})
  //   commit('DELETE_PO',payload)
  // },
  

 };
 
// -------------------------------------------------------//
const transactionList = {
  state,
  mutations,
  actions,
  getters,
};

export default transactionList;