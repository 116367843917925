<template>
    <div  v-if="!toggleEdit" class="mt-5">

    <!-- backarrow -->
     <div class="flex justify-between " >
        <div class="flex justify-between items-center ml-3 cursor-pointer">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 " @click="$emit('close')"> Back</p>
        </div>
        <div class="mr-16">
          <p v-if="userRole==='MASTER_STORE_MANAGER' || userRole==='BRANCH_STORE_MANAGER'" class="flex   text-yellow-400 cursor-pointer" @click.prevent="toggleEdit=true"> EDIT</p>
        </div>
    </div>
        <!-- rest dynamic modal content -->
    <div class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">ORDER DETAILS
            <div class="h-2 w-[140px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <div class="mt-10 grid grid-cols-3">
                    <div>
                        <div class="">
                            <label for="POV doc #" class="text-xs block mb-1 text-gray-500">POV doc #</label>
                            <input type="text" v-model="poNumber" readonly  id="POV doc #" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="From" class="text-xs block mb-1 text-gray-500">From</label>
                            <input type="text" v-model="from"  readonly id="From" class="inputbox nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Prepared by" class="text-xs block mb-1 text-gray-500">Prepared by</label>
                            <input type="text" v-model="preparedBy" readonly  id="Prepared by" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="POV doc scan" class="text-xs block mb-1 text-gray-500">POV doc scan</label>
                            <input type="text" :value="poscan.split('---')[1]" @click="openImage(poscan)"  readonly  id="POV doc scan" class="inputbox nav_blue text-nav_blue mb-3">
                        </div>
                    </div>
                    <div>
                        
                        <div class="">
                            <label for="Project" class="text-xs block mb-1 text-gray-500">Project</label>
                            <input type="text" v-model="project" readonly  id="Project" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="To" class="text-xs block mb-1 text-gray-500">To</label>
                            <input type="text" v-model="to" readonly  id="To" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="Approved by" class="text-xs block mb-1 text-gray-500">Approved by</label>
                            <input type="text" v-model="approvedby" readonly  id="Approved by" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="SRV doc scan" class="text-xs block mb-1 text-gray-500">SRV doc scan</label>
                            <input type="text" :value="srscan.split('---')[1]" @click="openImage(srscan)" readonly  id="SRV doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
                        </div>
                        
                    </div>
                    <div>
                        
                        <div class="">
                            <label for="SRV doc #" class="text-xs block mb-1 text-gray-500">SRV doc #</label>
                            <input type="text" v-model="srNumber" readonly  id="SRV doc #" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="Store request date" class="text-xs block mb-1 text-gray-500">Store request date</label>
                            <input type="text" v-model="srDate" readonly  id="Store request date" class="inputbox mb-3">
                        </div>
                        <div class="">
                            <label for="Purchase order date" class="text-xs block mb-1 text-gray-500">Purchase order date</label>
                            <input type="text" v-model="poDate" readonly  id="Purchase order date" class="inputbox mb-3">
                        </div>
                        
                    </div> 
        </div>
    </div>    
       <TableView showdoc="Needed" @delete="deleteitem" @remark="showRemark"  :headerList="Headers" exportNeeded='false' :itemlist="poItemsDetail"/>
       <RemarkDisplay v-if="toggleremark" :remark="remark?remark:null"  @close="toggleremark = !toggleremark"  class="h-full w-full absolute top-0 left-0"/>       
    </div>
     <UpdatePo v-if="toggleEdit" :currentTr="poDetail" @close="toggleEdit = !toggleEdit , $emit('close')"  class="absolute top-0 left-0  bg-white w-full"/>    
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import RemarkDisplay from "../../components/RemarkDisplay.vue";
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import UpdatePo from "./UpdatePo.vue"
import { useStore } from "vuex";

const store = useStore()
const poDetail =  computed(() =>store.getters.poDetail)
const poItemsDetail =  computed(() =>store.getters.poDetail.ItemOrdered)
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

const toggleEdit = ref(false)
const toggleremark = ref(false)

const poNumber = ref(poDetail.value.povNumber)
const from = ref(poDetail.value.from)
const preparedBy = ref(poDetail.value.preparedBy)
const poscan = ref(poDetail.value.povFile)
const project = ref(poDetail.value.project)
const to = ref(poDetail.value.to)
const approvedby = ref(poDetail.value.approvedBy)
const srscan = ref(poDetail.value.srvFile)
const srNumber = ref(poDetail.value.srvNumber)
const srDate = ref(poDetail.value.dateSrv)
const poDate = ref(poDetail.value.datePov)

const Headers = [
  { text: "Item ID", value: "item.id" , sortable: true },
  { text: "Part #", value: "item.partNumber"},
  { text: "Item name", value: "item.itemName"},
  { text: "UoM", value: "item.uom.abbreviation"},
  { text: "Quantity", value: "balance"},
  { text: "Remark", value: "item.remark"},
  
];


const remark = ref()
const showRemark=(val)=>{
    remark.value = val.remark
    val.remark!==''?toggleremark.value = !toggleremark.value:null
}
const openImage=(val)=>{
    window.open(val, '_blank');
}

</script>

<style lang="postcss">


</style>