<template >
<div v-if="!toggleMaster && !toggleBranch && !toggleUpdate">
    <div class="grid grid-cols-2 mb-5">
        <div class="flex items-center">

        <div class=" font-black text-[25px] mt-[120px] ml-5 ">STORES
            <div class="h-2 w-12 rounded-full bg-gold_yellow "></div>
        </div>
        <button :disabled="restrict" :class="{'hidden':restrict}" class="  mt-28 ml-8 p-2 px-2 flex items-center disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleMaster=!toggleMaster">
            <img :src="add" alt="add" class="h-3 mr-1.5">
            add master store
        </button>
        <button :disabled="restrict" :class="{'hidden':restrict}" class="mt-28 ml-4 p-2 px-2 flex items-center  disabled:bg-blue-400 disabled:cursor-not-allowed bg-nav_blue rounded-lg text-xs text-white font-medium cursor-pointer" @click="toggleBranch=!toggleBranch">
            <img :src="add" alt="add" class="h-3 mr-1.5">
            add branch store
        </button>
        </div>
        <!-- filter -->
        <div class="flex mt-16  ml-auto justify-end ">
           
            <div>
                <div class="flex items-center mr-[100px] space-x-3 " :class="{'mr-[30px]':userStore}">
                     <div class=" font-bold text-[15px] mr-9 "> FILTER BY
                        <div class="h-1.5 w-9 rounded-full bg-gold_yellow "></div>
                    </div>
                    <div class=" h-10 py-2 rounded-lg px-3  bg-main_gray">
                        <img :src="calander" alt="calander" @click="toggledatepicker = true" class="h-6" > 
                        <DateRange v-if="toggledatepicker" @dateRange="dateRange" @close="toggledatepicker = !toggledatepicker"  class="h-[700px] w-full absolute top-0 left-0"/>       
                    </div>
                    <div>
                        <DropDown v-if="!userStore" placeholderp="Company" :items="staticList.company" displayable="name" @selected="(selected)=>setFilter(selected)"  width="false"/>
                            <input v-else type="text" v-model="userStore.storeName" readonly  class="inputbox focus:outline-none flex justify-end items-end w-40 ">    
                    </div>
                </div>
                <div class=" flex ">
                <div class="inputbox p-0  flex items-center mt-6  w-[340px]   ">
                    <img :src="search" @click.prevent="filterAll()" alt="search" class="pl-2 h-4">
                    <input type="search" v-model="searchFilter" @keyup.enter="filterAll" placeholder="Search by Store name..." class="inputbox focus:outline-none    ">
                </div>
                </div>
            </div>
        </div>

    
    </div>
    <!-- table -->
    <TableView :key="filter"  showeye="Needed" showdel="Needed" @delete="deleteitem" @showdoc="showdocitem" @detail="detailitem" exportName="stores" :headerList="Headers" :itemlist="storeList"/>
</div>       
   <RegMasterStore v-if="toggleMaster" @close="toggleMaster=!toggleMaster" class="absolute top-0 left-0  bg-white w-full"/>
   <RegBranchStore v-if="toggleBranch" @close="toggleBranch=!toggleBranch" class="absolute top-0 left-0  bg-white w-full"/>
   <UpdateStore v-if="toggleUpdate" :olddata="oldid" :prevCompanyId="prevCompanyId" @close="closeUpdate" class="absolute top-0 left-0  bg-white w-full"/>

</template>

<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount, watch } from "@vue/runtime-core"
import DropDown from "../../components/DropDown.vue"
import TableView from "../../components/TableView.vue";
import DateRange from "../../components/DateRange.vue";
import RegMasterStore from "./RegMasterStore.vue"
import RegBranchStore from "./RegBranchStore.vue"
import UpdateStore from "./UpdateStore.vue"
import search from "../../assets/search.svg"
import calander from "../../assets/calander.svg"
import add from "../../assets/add.svg"
import Swal from 'sweetalert2'
import { useStore } from "vuex";


const store = useStore()

onBeforeMount(() => {  
    store.dispatch('fetchStores')
})


let storeList = ref(store.getters.storeList)
let staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
staticList.value.company.length>1 && staticList.value.company[0].name!=='ALL'?staticList.value.company.unshift({ id: 0, name: "ALL"}):null
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))


const toggleMaster = ref(false)
const toggleBranch = ref(false)
const toggleUpdate = ref(false)
const toggledatepicker = ref(false)


const searchFilter = ref('')
const companyFilter = ref()

const Headers = [
 { text: "Store ID", value: "id" , sortable: true },
  { text: "Store Name", value: "storeName"},
  { text: "Store Type", value: "type"},
  { text: "Manager name", value: "user.fullName"},
  { text: "Phone #", value: "user.phoneNumber"},
  { text: "Company", value: "company.name"},
  { text: "Date", value: "date",sortable: true},
  { text: "Actions", value: "operation"},
];



let filter = ref('1')
const filterVars = ref({company:null,init:null,end:null})


watch(toggleBranch, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchStores')
        .then(()=>{
           storeList = ref(store.getters.storeList)
           filter.value = new Date()
       })
    }
})
watch(toggleMaster, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchStores')
        .then(()=>{
           storeList = ref(store.getters.storeList)
           filter.value = new Date()
       })
    }
})
watch(toggleUpdate, async (newQuestion, oldQuestion) => {
    if ( oldQuestion===true ) {
        store.dispatch('fetchStores')
        .then(()=>{
           storeList = ref(store.getters.storeList)
           filter.value = new Date()
       })
    }
})
const dateRange=(init,end)=>{
    init.value? filterVars.value.init = init.value:null
    end.value? filterVars.value.end = end.value:null
    filterAll()
}

const setFilter=(val)=>{
    companyFilter.value = val.name 
    filterVars.value.company = val.name
    filterAll();
}

const filterAll = ( ) =>{
    storeList = ref(store.getters.storeList)
    if (filterVars.value.company !== null && filterVars.value.company !== "ALL") {
        storeList.value = storeList.value.filter((user)=>user.company.name === filterVars.value.company)   
    }
    if (filterVars.value.init !== null){

        storeList.value = storeList.value.filter((user)=>new Date(user.openingDAte) >= new Date(filterVars.value.init) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.init).getDate()+'/'+new Date(filterVars.value.init).getMonth() + 1+'/'+new Date(filterVars.value.init).getFullYear()
        )
    }
    if (filterVars.value.end !== null){

        storeList.value = storeList.value.filter((user)=>new Date(user.openingDAte) <= new Date(filterVars.value.end) || 
        new Date(user.createdAt).getDate()+'/'+new Date(user.createdAt).getMonth() + 1+'/'+new Date(user.createdAt).getFullYear()===new Date(filterVars.value.end).getDate()+'/'+new Date(filterVars.value.end).getMonth() + 1+'/'+new Date(filterVars.value.end).getFullYear()
        )
    }
    if (searchFilter.value !== ''){

        storeList.value = storeList.value.filter((user)=>(user.storeName).toLowerCase().includes((searchFilter.value).toLowerCase()))
    }
    
    
    filter.value = new Date()

}

const oldid = ref();
const prevCompanyId = ref();

const detailitem=(val)=>{
     if (userRole.value==='SYSTEM_ADMIN') {
        oldid.value = val.id;
        prevCompanyId.value = val.company.id;
        console.log("detail",val.company.id);
        toggleUpdate.value = !toggleUpdate.value  
    }
}

const deleteitem=(val)=>{
    if (userRole.value==='SYSTEM_ADMIN') {
        
        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteStore',val.id)
                            .then(()=>{
                                store.dispatch('fetchStores')
                            })
                            .then(()=>{
                                storeList = ref(store.getters.storeList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
    }
}

const closeUpdate=(val)=>{
    storeList = ref(store.getters.storeList)
    filter.value = new Date() 
   toggleUpdate.value = !toggleUpdate.value 
}

const restrict = computed(()=>{
    if (userRole.value!=='SYSTEM_ADMIN') {
        return true
    }
    else{
        return false
    }
})

</script>



<style lang="postcss">
    
</style>