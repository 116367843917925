<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">ORDER PURCHASE
            <div class="h-2 w-[135px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                       
                        <input type="text" v-model="povnum"   placeholder="POV doc #..." required class="inputbox  mb-3 mt-10 ">
                        <input type="text" v-model="from"   placeholder="From..." required class="inputbox  mb-3 ">
                        <input type="text" v-model="preparedby"   placeholder="Prepared by..." required class="inputbox  mb-3">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment"  @click="$refs.pofile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" disabled :value="povscan?povscan.name:''"   placeholder="Attach POV doc scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                             <input type="file" ref="pofile" accept=".pdf,image/*" @change="uploadpov" style="display: none" />
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                       
                        <input type="text" v-model="project"   placeholder="Project..." required class="inputbox  mb-3 mt-10 ">
                        <input type="text" v-model="to"   placeholder="To..." required class="inputbox  mb-3 ">
                        <input type="text" v-model="approvedby"   placeholder="Approved by..." required class="inputbox  mb-3">
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.file.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" disabled :value="srvscan?srvscan.name:''"   placeholder="Attach SRV doc scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="file" accept=".pdf,image/*" @change="uploadsrv" style="display: none" />
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                       
                        <input type="text" v-model="srvnum"   placeholder="SRV doc #…" required class="inputbox  mb-3 mt-10 ">
                       <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="srdate" :upperLimit="new Date()" placeholder="Store request date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                        <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput2.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5 picker">
                            <datepicker ref="calanderinput2" inputFormat="dd/MM/yyyy" v-model="podate" :upperLimit="new Date()" placeholder="Purchase order date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        
                        </div>
                           
                    </div>
                    
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in itemList" :key="item" class="mb-2"> 
            <div class="flex">
                <!-- <input type="text" v-model="allItems[index].itemId"  @input="searchItem(index)"  placeholder="Item ID..." required class="inputbox      mr-3 w-36"> -->
                <!-- <DropDown placeholderp="UoM..." :items="staticList.uom" displayable="name" @selected="(selected)=>setUom(selected,index)"  width="false" class="mr-20 mx-1 "/> -->
                    <ItemSearch  :items="staticItemList" displayable="itemName" @selected="(selected)=>searchItem(selected,index)"  width="false" class="mr-24 mx-1 "/>
                <input type="text" v-model="alldesc[index].uom"  readonly placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="number" v-model="allItems[index].balance" pattern="^[0-9]*$" @input="(event)=>checkBalance(index,event)"  placeholder="Qty..." required class="inputbox ml-3 mr-3 w-36">
                <input type="text" v-model="allItems[index].remark"   placeholder="Remark..." class="inputbox  mr-3 w-56">
                <button v-if="index>0" @click="deductList(index)" class="bg-red-500 text-white text-3xl w-9 h-9 mx-2 items-center  rounded-full ">-</button>
                <button v-if="index===itemList-1" @click="addItemInputs" class=" text-white text-3xl w-9 h-9 mx-2 items-center  pb-3 rounded-full "
                :class="{'bg-nav_blue':itemList!==1,'bg-[#8F8F8F]':itemList===1}">+</button>
             </div>
             <div>
                <p class="text-[12px]">{{alldesc[index].desc}}</p>
                <p class="-mt-3 ml-[345px] text-[12px] font-semibold text-green-400">{{alldesc[index].balance}}</p>
            </div> 
        </div>

    <div class="mt-20">
            <button  v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="submitbutton">submit</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
        </div>
    </form>    
      
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Datepicker from 'vue3-datepicker'
import ItemSearch from "@/components/ItemSearch.vue";
import DropDown from "../../components/DropDown.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const close = defineEmits("close")


const store = useStore()
const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
const staticItemList =  ref(JSON.parse(JSON.stringify(store.getters.itemList)))

const povnum = ref()
const from = ref()
const preparedby = ref()
const povscan = ref()
const project = ref()
const to = ref()
const approvedby = ref()
const srvnum = ref()
const srvscan = ref()
const srdate = ref()
const podate = ref()

const itemId = ref()
const uom = ref()
const balance = ref()
const remark = ref()
const isLoading = ref(false);

const allItems = ref([
    {itemId:'',balance:'',remark:''}
])
const alldesc = ref([
    {desc:'',uom:'',balance:null}
])

const setSiv =(selected)=>{
    istvnum = selected.name
}

const setUom =(selected,index)=>{
    alldesc.value[index].uom= selected.name
}

const itemList =computed(()=>{
   return allItems.value.length===0 ?allItems.value.length + 1:allItems.value.length
})
    
const addItemInputs = ()=>{
    allItems.value.push({itemId:'',balance:'',remark:''})
    alldesc.value.push({desc:'',uom:'',balance:null})
}

const deductList=(i)=> {
  allItems.value = allItems.value.filter((_, index) => index !== i)
  alldesc.value = alldesc.value.filter((_, index) => index !== i)
}

const uploadsrv =(event)=>{ 
 srvscan.value =  event.target.files[0]
}
const uploadpov =(event)=>{ 
 povscan.value =  event.target.files[0]
}

const checkBalance =(index,event)=>{
    if(Number(allItems.value[index].balance)>0 ){
        //  allItems.value[index].receivedQty =allItems.value[index].receivedQty.concat(Number(event.data))  ;
    }else{
        allItems.value[index].balance = null
    }
}

const isListEmpty = computed(()=>{
         for (let index = 0; index < itemList.value; index++) {
            if( !allItems.value[index].itemId||! alldesc.value[index].uom||! allItems.value[index].balance){
                return true
            }
            
        }
})

// to disable button checking method
const activate = computed(()=>{
    if(!povnum.value || !from.value || !preparedby.value || !povscan.value || !project.value || !to.value || !approvedby.value||
     !srvnum.value|| !srdate.value|| !podate.value|| !srvscan.value ||isListEmpty.value){
        return true;
    }else{
        return false;
    }
})

const searchItem=(selected,val)=>{

    allItems.value[val].itemId =selected
    var inventoryList = JSON.parse(JSON.stringify(store.getters.inventoryList)).filter(item=>item.id.toLowerCase()===selected.toLowerCase())    
      
    if (inventoryList.length>0) {alldesc.value[val].balance = inventoryList[0].balance }
    
    for (let index = 0; index < staticItemList.value.length; index++) {

       if((staticItemList.value[index].id).toLowerCase() === (allItems.value[val].itemId).toLowerCase()) {
             alldesc.value[val].uom = staticItemList.value[index].uom.abbreviation
             alldesc.value[val].desc = staticItemList.value[index].itemName
             staticItemList.value = staticItemList.value.filter(item=>item.id.toLowerCase()!==allItems.value[val].itemId.toLowerCase())    
             return 
         }else{
             alldesc.value[val].uom = ''
             alldesc.value[val].desc = ''
                
         }  
    } 
}

const submit =()=>{
    const formData = new FormData();
    povnum.value && formData.append("povNumber", povnum.value);
    povscan.value && formData.append("POV", povscan.value);
    srvscan.value && formData.append("SRV", srvscan.value);
    preparedby.value && formData.append("preparedBy", preparedby.value);
    project.value && formData.append("project", project.value);
    from.value && formData.append("from", from.value);
    to.value && formData.append("to", to.value);
    approvedby.value && formData.append("approvedBy", approvedby.value);
    srvnum.value && formData.append("srvNumber", srvnum.value);
    srdate.value && formData.append("srvDate", srdate.value);
    podate.value && formData.append("povDate", podate.value);
    allItems.value && formData.append("items",(JSON.stringify(allItems.value)));

    isLoading.value = true;
    store.dispatch('addPurchaseOrder',
        formData,
     ) 
      .then(()=>{ 
          store.dispatch('fetchGoodsRecived')
          isLoading.value = false;
      })
      .then(()=>{ 
            close('close')
        }) 
        .catch((error) => {
            isLoading.value = false;
        });
}

</script>

<style lang="postcss">


</style>