<template>
<div class="w-20">

  <Combobox v-model="selectedPerson" @update:modelValue="emitSelected" v-slot="{ open }">
    <ComboboxInput
        v-model="query"
        @change="query = $event.target.value"
        :displayValue="(person) =>{ person.id}"
        :placeholder="defaultValue?defaultValue:'Item ID...'"
        :value="query"
        class="z-0 h-10 flex justify-between items-center text-[15px] relative bg-main_gray px-4 inputbox"
        :class="{'rounded-b-none': open, 'text-gray-400': !selectedPerson, 'w-40': width==='false', 'w-44': width==='lilwider'}"
      />
      
      <div static class="rounded-lg   w-40 ">
      
        <ComboboxOptions :class="{'h-[155px] overflow-hidden overflow-y-scroll ': itemLength > 6}" class="absolute z-30 h-40 w-40">
      <ComboboxOption
        v-for="person in filteredPeople"
        :key="person.id"
        :value="person"
        class="  w-40    text-gray-800 gap-1 ui-active:bg-blue-500 ui-active:text-white ui-not-active: bg-main_gray  ui-not-active:text-black border-t-2 px-4"
      >
        {{ person.itemName?person.itemName:person.name }}
      </ComboboxOption>
    </ComboboxOptions>
    </div>
  </Combobox>
</div>
</template>

<script setup>
  import { computed, ref } from 'vue'
  import arrowdown from "../assets/arrowdown.svg"
  import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  } from '@headlessui/vue'
// defineEmits("selected")
const emit = defineEmits(['selected'])

const props = defineProps({
  items:String,
  width:Number,
  displayable:String,
  defaultValue:String
})  
const {items,width,displayable,defaultValue} =props
  const people = items;
  const selectedPerson = ref()
  const display=ref(displayable)
  const itemLength = computed(() => filteredPeople.value.length)

  
  const query = ref(defaultValue?defaultValue:'')
  
  const filteredPeople = computed(() =>
    query.value === ''
      ? people
      : people.filter((person) => {
        if (person.itemName!==undefined) {
          return  person.itemName.toLowerCase().includes(query.value.toLowerCase()) || person.id.toLowerCase().includes(query.value.toLowerCase())
        } else {
          return  person.name.toLowerCase().includes(query.value.toLowerCase()) || person.id.toLowerCase().includes(query.value.toLowerCase())
        }
        })
  )

  const emitSelected = () => {
    query.value=selectedPerson.value.id
    emit("selected", selectedPerson.value.id);
};

</script>