<template>
    <div>

    <!-- backarrow -->
    <div class="flex items-center ml-3 mt-5">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5 cursor-pointer" @click="$emit('close')">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer " @click="$emit('close')"> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">UPDATE ITEM
            <div class="h-2 w-[105px] rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{props.olddata.id}}</p>
                <p class="text-[9px]  text-gold_yellow ">~ Item ID</p>
            </div>
           
            <div class="flex w-[750px]   mt-10 ">
                <div class="grid col-span-1 gap-3">
                    <input type="text" v-model="partnumber" placeholder="Part number..." class="inputbox nav_blue mr-10">
                    <div class="relative">
                        <drop-down placeholderp="Category..." :items="storeList.category" displayable="name" @selected="(selected)=>setCatagory(selected)" />
                        <input v-if="catagory===''" type="text" disabled :value="oldcatagory" class="bg-main_gray absolute top-2.5 left-4 text-[15px] w-64">        
                    </div>
                    <div class="relative">
                        <drop-down placeholderp="Unit of measure..." :items="storeList.uom" displayable="abbreviation" @selected="(selected)=>setUom(selected)" />
                        <input v-if="unitmeasure===''" type="text" disabled :value="oldunitmeasure" class="bg-main_gray absolute top-2.5 left-4 text-[15px] w-64">        
                    </div>
                </div>
                <div>
                    <input type="text" v-model="name" placeholder="Item name..." class="inputbox nav_blue mr-10">
                   <div class="relative">
                        <drop-down :key="selectedCatagId"  placeholderp="Sub-category..." :items="subcatagoryList" displayable="name" @selected="(selected)=>setSubcatagory(selected)" class="my-3" />
                        <input v-if="subcatagory===''" type="text" disabled :value="oldsubcatagory" class="bg-main_gray absolute top-2.5 left-4 text-[15px] w-64">    
                   </div>
                    <div class=" relative inputbox  ">
                            <img :src="attachment" alt="attachment" @click="$refs.invoicescanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="itemspec.split('---')[1]" @click="openImage(itemspec)" readonly  required class="w-full cursor-pointer bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="invoicescanfile" accept=".pdf,image/*" @change="uploadinvoice" style="display: none" />   
                    </div>
                    <p class="text-[10px] mt-0.5 text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                </div>
            </div>
        </div>
						
        <div class="mt-28">
            <button v-if="!isLoading" :disabled="activate" @click.prevent="submitItem" class="submitbutton">Update</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import Spinner from "../../components/Spinner.vue"
import attachment from "../../assets/attachment.svg"
import { useStore } from "vuex"

const close = defineEmits("close")

const store = useStore()

const props = defineProps({
  olddata:Object,
})

const storeList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))

const partnumber = ref(props.olddata.partNumber)
const catagory = ref('')
const oldcatagory = ref(props.olddata.category.name)
const subcatagory = ref('')
const oldsubcatagory = ref(props.olddata.subcategory.name)
const unitmeasure = ref('')
const oldunitmeasure = ref(props.olddata.uom.abbreviation)
const name = ref(props.olddata.itemName)
const itemspec = ref(props.olddata.itemSpecification)
 
const isLoading = ref(false);
const selectedCatagId = ref('')
 
const subcatagoryList = computed(()=>{
    return (storeList.value.subcategory.filter((sub)=>sub.categoryId === selectedCatagId.value))
})
  
const activate = computed(()=>{
    if( 
        props.olddata.partNumber!==partnumber.value ||
        props.olddata.itemName!==name.value||
        itemspec.value|| 
        (subcatagory.value!=='')?props.olddata.subcategory.name!==subcatagory.value:false ||   
        unitmeasure.value!==''?props.olddata.uom.abbreviation!==unitmeasure.value.abbreviation:false ||
        catagory.value!==''?props.olddata.category.name!==catagory.value.name:false  
        ){
        return false;
    }else{
        return true;
    }
})
const setCatagory=(val)=>{
    selectedCatagId.value=val.id
    catagory.value = val 
}
const setUom=(val)=>{
    unitmeasure.value = val 
}

const setSubcatagory=(val)=>{
    subcatagory.value = val
    
}
const uploadinvoice =(event)=>{ 
 itemspec.value =  event.target.files[0]
}

const openImage=(val)=>{
    window.open(val, '_blank');
}

const submitItem = async (event) => {
    const formData = new FormData();
     formData.append("ITEMSPECIFICATION", itemspec.value?itemspec.value:props.olddata.itemSpecification);
     formData.append("id",props.olddata.id);
     formData.append("partNumber", partnumber.value);
     formData.append("category",catagory.value!==''?catagory.value.id:props.olddata.category.id );
     formData.append("subcategory",(subcatagory.value!=='')?subcatagory.value.id:props.olddata.subcategory.id );
     formData.append("uom", unitmeasure.value!==''?unitmeasure.value.id:props.olddata.uom.id );
     formData.append("itemName", name.value);
    

     isLoading.value = true;
   store.dispatch('UpdateItem',{formData}) 
   .then(()=>{ 
        store.dispatch('fetchItems')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });

}

</script>

