<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer "> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-20 mx-10">
        <div class=" font-black text-[30px] ">Add USER
            <div class="h-2 w-24 rounded-full bg-gold_yellow "></div>
        </div>
        <div class="mt-16">
            <div class="bg-blacker w-[330px] flex items-center justify-between p-2 px-4 rounded-lg">
                <p class="text-gold_yellow">{{newid}}</p>
                <p class="text-[9px]  text-gold_yellow ">-User ID</p>
            </div>
           
            <div class="flex w-[750px]   mt-10 ">
                <div class="grid col-span-1 gap-3">
                    <input type="text" v-model="fname" placeholder="Full name" class="inputbox nav_blue mr-10">
                    <input type="text" v-model="email" placeholder="Email" class="inputbox mr-10">
                    <input type="text"  @input="validatePhoneNumber"  maxlength="10" v-model="phone" placeholder="Phone #" class="inputbox">
                </div>
                <div class=" space-y-3">
                     <drop-down v-if="!hideCompany && role" placeholderp="Company..." :items="staticList.company" displayable="name" @selected="(selected)=>setcompany(selected)" />
                    <drop-down placeholderp="Role" :items="roles" displayable="name" @selected="(selected)=>setrole(selected)" />
                </div>
            </div>
        </div>
						
        <div class="mt-40">
            <button v-if="!isLoading" :disabled="activate || activ" @click.prevent="submitUser"  class="submitbutton">Save</button>
            <button v-else :disabled="true"   class="submitbutton px-8 py-2">
                <Spinner/>
            </button>
        </div>
       
    </form>    
    </div>
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { computed, onBeforeMount } from "@vue/runtime-core"
import { useStore } from "vuex"
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import Spinner from "../../components/Spinner.vue"

const store = useStore()
const close = defineEmits("close")

const staticList =  computed(() =>JSON.parse(JSON.stringify(store.getters.staticList)))
if (staticList.value.company.length>0 && staticList.value.company[0].name==="ALL") {
    staticList.value.company.shift();
}

// -------creating new user id--------
    const userId = ref()
    const newUser =  computed(() =>JSON.parse(JSON.stringify(store.getters.newUser)))
    let newid = Number(newUser.value)+1
    newid= 'UID/' + newid;
    // if (newid<10) {
    //     userId.value=('UID/00'+newid)
    // }else if(newid=>10 && newid<100) {
    //     userId.value=('UID/0'+newid)
    // }else{
    //     userId.value=('UID/'+newid)
    // }
    
    

const fname = ref()
const company = ref()
const role = ref()
const phone = ref()
const email = ref()

const isLoading = ref(false);

 const roles = [
    { id: 1, name: 'System Admin', value: "SYSTEM_ADMIN" },
    { id: 2, name: 'Upper Management', value: "UPPER_MANAGEMENT" },
    { id: 3, name: 'Middle Management', value: "MIDDLE_MANAGEMENT" },
    { id: 4, name: 'Project Manager', value: "PROJECT_MANAGER" },
    { id: 5, name: 'Master store Manager', value: "MASTER_STORE_MANAGER" },
    { id: 6, name: 'Branch store Manager', value: "BRANCH_STORE_MANAGER" },
  ]
const hideCompany = computed(()=>{
    if(role.value === "UPPER_MANAGEMENT" || role.value === "MIDDLE_MANAGEMENT" || role.value === "SYSTEM_ADMIN" ){
            return true;
    }else{
        return false;
    }
})
const activ =computed (()=>{
    if( hideCompany.value === false ){
        if (company.value===undefined ) {
            
            return true
        }
    }
    else{
        false
    }
})
const activate = computed(()=>{
    if( !fname.value || !role.value || !phone.value || !email.value ){
       return true;
    }
    else{
        return false;
    }
})


const setcompany=(val)=>{
    company.value = val 
}

const setrole=(val)=>{
    role.value = val.value

     
}

const isValidPhoneNumber = ref(false); 

const validatePhoneNumber = () => {
    let inputValue = phone.value;

// Check if the input exceeds 11 characters
if (inputValue.length > 11) {
  inputValue = inputValue.slice(0, 11);
}

// Check if the first character is not '0' or the second character is not '9'
if (inputValue.length >= 2 && (inputValue[0] !== '0' || inputValue[1] !== '9')) {
  inputValue = inputValue.slice(0, inputValue.length - 1);
}

// Remove non-numeric characters
inputValue = inputValue.replace(/\D/g, '');

// Update the phone ref with the sanitized value
phone.value = inputValue;
};

const submitUser=()=>{
    isLoading.value = true;
    store.dispatch('addUser',{id:newid,fullName:fname.value,phoneNumber:phone.value,email:email.value.toLowerCase(),role:role.value,companyId:Number(company.value?company.value.id:0)})
     .then(()=>{ 
          store.dispatch('fetchUsers')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      })
      .catch((error) => {
        isLoading.value = false;
    });

     
}

</script>

<style lang="postcss">


</style>