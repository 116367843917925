<template>
    <div v-if="!toggleEdit" class="mt-5">

    <!-- backarrow -->
   <div class="flex justify-between " >
        <div class="flex justify-between items-center ml-3 cursor-pointer">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 " @click.prevent="emitValue"> Back</p>
        </div>
        <div class="mr-16">
          <p v-if="updateAccess" class="flex   text-yellow-400 cursor-pointer" @click.prevent="update"> EDIT</p>
        </div>
    </div>
        <!-- rest dynamic modal content -->
    <div class=" mt-8 mb-7 mx-7">
        <div class="flex">

            <div class=" font-black text-[30px] ">ISTV DETAILS
                <div class="h-2 w-[110px] rounded-full bg-gold_yellow "></div>
            </div>
            <!-- if the transfered item is recived display thr grv doc -->
            <div v-if="istvDetail.status" class="-mb-8 ml-auto mr-24">
                <label for="GRV doc scan" class="text-xs block mb-1 text-gray-500">GRV doc scan</label>
                <input type="text" :value="grvscan.split('---')[1]" @click="openImage(grvscan)" readonly  id="GRV doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
            </div>
        </div>
        <!--   input fields  -->
        <div class="mt-5 grid grid-cols-3">
                    <div>
                        <div class="">
                            <label for="ISTV doc #" class="text-xs block mb-1 text-gray-500">ISTV doc #</label>
                            <input type="text" v-model="istvnum" readonly  id="ISTV doc #" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="Transfer from" class="text-xs block mb-1 text-gray-500">Transfer from</label>
                            <input type="text" v-model="from"  readonly id="Transfer from" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="Truck plate #" class="text-xs block mb-1 text-gray-500">Truck plate #</label>
                            <input type="text" v-model="truckplate" readonly  id="Truck plate #" class="inputbox  mb-3">
                        </div>
                        
                    </div>
                    <div>
                        <div class="">
                            <label for="ISTV doc scan" class="text-xs block mb-1 text-gray-500">ISTV doc scan</label>
                            <input type="text" :value="istvscan.split('---')[1]" @click="openImage(istvscan)" readonly  id="ISTV doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Transfer to" class="text-xs block mb-1 text-gray-500">Transfer to</label>
                            <input type="text" v-model="to" readonly  id="Transfer to" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="Invoice #" class="text-xs block mb-1 text-gray-500">Invoice #</label>
                            <input type="text" v-model="invoicenum" readonly  id="Invoice #" class="inputbox mb-3">
                        </div>
                        
                    </div>
                    <div>
                        <div class="">
                            <label for="Transfer approval doc scan" class="text-xs block mb-1 text-gray-500">Transfer approval doc scan</label>
                            <input type="text" :value="approvalscan.split('---')[1]" @click="openImage(approvalscan)" readonly  id="Transfer approval doc scan" class="inputbox cursor-pointer text-nav_blue mb-3">
                        </div>
                        <div class="">
                            <label for="Delivery date" class="text-xs block mb-1 text-gray-500">Delivery date</label>
                            <input type="text" v-model="deliverydate" readonly  id="Delivery date" class="inputbox  mb-3">
                        </div>
                        <div class="">
                            <label for="S/R #" class="text-xs block mb-1 text-gray-500">S/R #</label>
                            <input type="text" v-model="srnum" readonly  id="S/R #" class="inputbox mb-3">
                        </div>
                        
                    </div> 
        </div>
    </div>    
      <TableView showdoc="Needed" @delete="deleteitem" @remark="showRemark" exportNeeded='false' :headerList="Headers" :itemlist="istvItemsDetail"/>
      <RemarkDisplay v-if="toggleremark" :remark="remark?remark:null"  @close="toggleremark = !toggleremark"  class="h-[700px] w-full absolute top-0 left-0"/>         
    </div>

     <UpdateIstv v-if="toggleEdit" :currentTr="istvDetail" @close="toggleEdit = !toggleEdit "  class="absolute top-0 left-0  bg-white w-full"/>    
</template>


<script setup>
import { ref } from "@vue/reactivity"
import { defineEmits } from "vue";
import { computed, onUpdated } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import RemarkDisplay from "../../components/RemarkDisplay.vue";
import backarrow from "../../assets/arrowback.svg"
import DropDown from "../../components/DropDown.vue"
import UpdateIstv from "./UpdateIstv.vue"
import { useStore } from "vuex";

const store = useStore()


const emit = defineEmits(['pass-value']);

const emitValue = () => {
  emit('pass-value', 'close detail page'); 
}


onUpdated(() => {  

     istvDetail =  computed(() =>store.getters.istvDetail)
     istvItemsDetail =  computed(() =>store.getters.istvDetail.IstvTransaction)

})

let istvDetail =  ref(store.getters.istvDetail)
let istvItemsDetail =  ref(store.getters.istvDetail.IstvTransaction)
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))



const grvscan = ref(istvDetail.value.status?istvDetail.value.Grv2[0].grvFile:'')
const istvnum = ref(istvDetail.value.istvNumber)
const from = ref(istvDetail.value.transferFrom)
const truckplate = ref(istvDetail.value.truckPlate)
const istvscan = ref(istvDetail.value.istvFile)

const to = ref(istvDetail.value.transferTo.storeName)
const invoicenum = ref(istvDetail.value.invoiceNumber)
const approvalscan = ref(istvDetail.value.approvalFile)
const deliverydate = ref(istvDetail.value.date)
const srnum = ref(istvDetail.value.sr)

const toggleEdit = ref(false)

const Headers = [
  { text: "Item ID", value: "item.stock.item.id" , sortable: true },
  { text: "Part #", value: "item.stock.item.partNumber"},
  { text: "Item name", value: "item.stock.item.itemName"},
  { text: "UoM", value: "item.stock.item.uom.abbreviation"},
  { text: "Quantity", value: "item.issuedQty"},
  { text: "Remark", value: "item.remark"},
  
];

const update=()=>{
    if ( !istvDetail.value.status && userStore.value.storeName === from.value &&(userRole.value==='MASTER_STORE_MANAGER' || userRole.value==='BRANCH_STORE_MANAGER') ) {
        toggleEdit.value=true
    }

}
const updateAccess =computed(()=>{
    if ( !istvDetail.value.status && (userRole.value==='MASTER_STORE_MANAGER' || userRole.value==='BRANCH_STORE_MANAGER') ) {
        if (userStore.value && userStore.value.storeName === from.value ) {
            return true
        }else{
            return false
        }
    }
    else{
        false
    }
})

const toggleremark = ref(false)
const remark = ref()
const showRemark=(val)=>{
    remark.value = val.item.remark
    val.item.remark!==''?toggleremark.value = !toggleremark.value:null
}

const openImage=(val)=>{
    window.open(val, '_blank');
}


</script>

