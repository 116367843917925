import router from '../../router/index'
import HTTP from "../../Axios/Axios";
import Swal from 'sweetalert2'

const showAlert = (val) => {
  Swal.fire({
    width: 300,
    color: 'white',
    text: val[0],
    position: 'top',
    // icon: 'success',
    background: val[1]==='success'? '#68B984':'#F96666',
    timer: 1500,
    showConfirmButton:false
    // confirmButtonText: 'no'
  })
}

const state = {
 staticList:[],
 users:[],
 items:[],
 catagory:[],
 subcatagory:[],
 uom:[],
 store:[],
 company:[],

 newuserid:'',
 newitemid:'',
 newcategoryid:'',
 newsubcategoryid:'',
 newcompanyid:'',
 newuomid:''
};
const getters = {
  staticList: (state)=>{
    return state.staticList
  },
  usersList: (state)=>{
    return state.users
  },
  itemList: (state)=>{
    return state.items
  },
  catagoryList: (state)=>{
    return state.catagory
  },
  subCatagoryList: (state)=>{
    return state.subcatagory
  },
  uomList: (state)=>{
    return state.uom
  },
  storeList: (state)=>{
    return state.store
  },
  companyList: (state)=>{
    return state.company
  },
  newItem: (state)=>{
    return state.newitemid
  },
  newUser: (state)=>{
    return state.newuserid
  },
  newCategory: (state)=>{
    return state.newcategoryid
  },
  newSubcategory: (state)=>{
    return state.newsubcategoryid
  },
  newCompany: (state)=>{
    return state.newcompanyid
  },
  newUom: (state)=>{
    return state.newuomid
  },


};
const mutations = {
 
  refList:(state,refList) =>(state.staticList = refList),
  allUsers:(state,data) =>(state.users = data.users,state.newuserid=data.count),
  allItems:(state,data) =>(state.items = data.item,state.newitemid=data.count),
  allCatagory:(state,data) =>(state.catagory = data.category ,state.newcategoryid=data.count),
  allSubCatagory:(state,data) =>(state.subcatagory = data.subcategory ,state.newsubcategoryid=data.count),
  allUoM:(state,data) =>(state.uom = data.uom,state.newuomid=data.count),
  allStores:(state,stores) =>(state.store = stores ),
  allCompany:(state,data) =>(state.company = data.company,state.newcompanyid = data.count),

  // -----------
  TOGGLE_USER:(state,id)=>{
   const news = state.users.map((i)=>id.id===i.id && (i.status = !i.status))
  },

  // -------------------------------------------------------

  DELETE_USER:(state,id)=>{state.users=state.users.filter((item)=>item.id !== (id))},
  DELETE_STORE:(state,id)=>{state.store=state.store.filter((item)=>item.id !== (id))},
  DELETE_ITEM:(state,id)=>{state.items=state.items.filter((item)=>item.id !== (id))},
  // DELETE_CATEGORY:(state,id)=>{state.catagory=state.catagory.filter((item)=>item.id !== (id))},
  DELETE_SUBCATEGORY:(state,id)=>{state.subcatagory=state.subcatagory.filter((item)=>item.id !== (id))},
  DELETE_UOM:(state,id)=>{state.uom=state.uom.filter((item)=>item.id !== (id))},
  DELETE_COMPANY:(state,id)=>{state.company=state.company.filter((item)=>item.id !== (id))},


  // -------------------------------------------------------

  ADD_USER:(state,user)=>{state.users.push(user)},
  ADD_STORE:(state,store)=>{state.store.push(store)},
  ADD_ITEM:(state,item)=>{state.items.push(item)},
  ADD_CATEGORY:(state,category)=>{state.catagory.push(category)},
  ADD_SUBCATEGORY:(state,subcategory)=>{state.subcatagory.push(subcategory)},
  ADD_UOM:(state,uom)=>{state.uom.push(uom)}
  
};
const actions = {

 async fetchStaticList ({commit}){
   try {
     const resp =await HTTP.get('inventory/getInfo')
     commit('refList',resp.data)
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
        }
      }
 },

 async fetchUsers ({commit}){
     try {
       const resp =await HTTP.get('user/get')
       commit('allUsers',resp.data)
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async toggleUser ({commit},payload){
    try {
      const id = payload.id
      const status = Boolean(payload.status)
      const resp =await HTTP.patch('user/statusChange',{id:payload.id,status:Boolean(payload.status)})
      commit('TOGGLE_USER',payload)
          // 
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
    }
 },

 async fetchStores ({commit}){
   try {
     const resp =await HTTP.get('store/getAll')
     commit('allStores',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async fetchItems ({commit}){
   try {
     const resp =await HTTP.get('item/getAll')
     commit('allItems',resp.data)
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
        error.response.statusText == "Unauthorized" ? router.push('/') : null
      }
 },

 async fetchCatagory ({commit}){
   try {
     const resp =await HTTP.get('category/getAll')
     commit('allCatagory',resp.data)        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async fetchSubCatagory ({commit}){
    try {
      
      const resp =await HTTP.get('subcategory/getAll')
      commit('allSubCatagory',resp.data)
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
    }
  },
 
 async fetchUoM ({commit}){
   try {
     const resp =await HTTP.get('uom/getAll')
     commit('allUoM',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async fetchCompany ({commit}){
   try {
     const resp =await HTTP.get('company/getAll')
     commit('allCompany',resp.data)
        
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

//  ************************************************************************************************************************************
 
 async addUser ({commit},payload){
   try {
     const resp =await HTTP.post('user/create',payload)
     commit('ADD_USER',resp.data.user)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async addStore ({commit},payload){
   try {
     const resp =await HTTP.post('store/create',payload)
     commit('ADD_STORE',resp.data.store)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async addItem ({commit},item){
   try {
     const resp =await HTTP.post('item/create',item.formData)
     commit('ADD_ITEM',resp.data.item)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async addCatagory ({commit},payload){
   try {
     const resp =await HTTP.post('category/create',payload)
     commit('ADD_CATEGORY',resp.data.category)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async addSubcatagory ({commit},payload){
   try {
     const resp =await HTTP.post('subcategory/create',payload)
     commit('ADD_SUBCATEGORY',resp.data.subcategory)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

 async addUom ({commit},Uom){
   try {
     const resp =await HTTP.post('uom/create',Uom)
     commit('ADD_UOM',resp.data.uom)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },
 async addCompany ({commit},company){
   try {
     const resp =await HTTP.post('company/create',company)
     showAlert([resp.data.message, 'success']) 
     
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
 },

  // -------------------------------------------------------//

  async deleteUser ({commit},payload){
     try {
       const resp =await HTTP.delete('user/delete',{data: {id: payload }})
       commit('DELETE_USER',payload)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },
  async deleteStore ({commit},payload){
     try {
       const resp =await HTTP.delete('store/delete',{data: {id: Number(payload) }})
       commit('DELETE_STORE',payload)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },
  async deleteItem ({commit},payload){
     try {
       const resp =await HTTP.delete('item/delete',{data: {id: payload }})
       commit('DELETE_ITEM',payload)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },
  async deleteCategory ({commit},payload){
     try {
       const resp =await HTTP.delete('category/delete',{data: {id: payload }})
      //  commit('DELETE_CATEGORY',payload)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },
  async deleteSubcategory ({commit},payload){
     try {
       const resp =await HTTP.delete('subcategory/delete',{data: {id: payload }})
       commit('DELETE_SUBCATEGORY',payload)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },
  async deleteUom ({commit},payload){
     try {
       const resp =await HTTP.delete('uom/delete',{data: {id: payload }})
       commit('DELETE_UOM',payload)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },
  async deleteCompany ({commit},payload){
     try {
       const resp =await HTTP.delete('company/delete',{data: {id: payload }})
       commit('DELETE_COMPANY',payload)
       showAlert([resp.data.message, 'success'])

      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
  },

  // -------------------------------------------------------//

  async updateStore ({commit},store){
     try {
       const resp =await HTTP.patch('store/update',store)
      //  commit('allStores',resp.data.stores)
      showAlert([resp.data.message, 'success'])
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
   },

  async updateUser ({commit},user){
     try {
       const resp =await HTTP.patch('user/edit',user)
       commit('allUsers',resp.data)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
   },
  async UpdateItem ({commit},item){
     try {
       const resp =await HTTP.patch('item/update',item.formData)
       commit('allItems',resp.data)
      showAlert([resp.data.message, 'success'])
       
      } catch (error) {
        if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
      }
   },
  async UpdateCatagory ({commit},category){
    try {
      const resp =await HTTP.patch('category/update',category)
      commit('allCatagory',resp.data)
    showAlert([resp.data.message, 'success'])
      
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
    }
   },
  async UpdateSubcatagory ({commit},subcategory){
    try {
      const resp =await HTTP.patch('subcategory/update',subcategory)
      commit('allSubCatagory',resp.data)
      showAlert([resp.data.message, 'success'])
      
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
    }
   },
  async UpdateCompany ({commit},company){
    try {
      const resp =await HTTP.patch('company/update',company)
      commit('allCompany',resp.data.company)
      showAlert([resp.data.message, 'success'])
    } catch (error) {
      if (error.response.statusText == "Unauthorized") {
           router.push('/')
        } else {
          alert(["Error: " +error.response.data.message])
        }
    }
   },

 };


// -------------------------------------------------------
const staticList = {
  state,
  mutations,
  actions,
  getters,
};

export default staticList;