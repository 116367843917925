<template >
    <div v-if="!toggleDetails" >
    <!-- backarrow -->
        <div class="flex items-center mt-5 ml-3 cursor-pointer" @click="$emit('close')">
            <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
            <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 "> Back</p>
        </div>
        <div class="grid grid-cols-2 mb-8">
            
            <div class="flex items-center">

            <div class=" font-black text-[25px] mt-[90px] ml-5 ">PENDING TRANSFERS
                <div class="h-2 w-36 rounded-full bg-gold_yellow "></div>
            </div>
        
            
            </div>
            
        
        </div>
        <!-- table -->
        <TableView :key="filter" showdoc="Needed" showeye="Needed" showdel="Needed" @detail="detailitem" @delete="deleteitem" @displayScan="showScan" exportName="PendingTransfers" :headerList="Headers" :itemlist="pendingList"/>
        
   
    </div>
    <pendingDetail v-if="toggleDetails" @pass-value="handleValue"  class="absolute top-0 left-0  bg-white w-full"/>
</template>

<script setup>
import { ref } from "@vue/reactivity"
import { defineEmits } from "vue";
import { computed, onBeforeMount, onUpdated, watch } from "@vue/runtime-core"
import backarrow from "../../assets/arrowback.svg"
import Swal from 'sweetalert2'
import TableView from "../../components/TableView.vue";
import pendingDetail from "./pendingDetail.vue"
import { useStore } from "vuex";

const store = useStore()
const emit = defineEmits(['close']);
onBeforeMount(() => {  
    store.dispatch('fetchPending')
})

const handleValue = (value) => {
    emit('close')
    toggleDetails.value=!toggleDetails.value // Update the receivedValue in the parent component
};

onUpdated(() => {  
     pendingList = computed(() =>(store.getters.pendingList))
    filter.value = new Date()

})

let toggleDetails = ref(false)
let filter = ref(1)

const back = ()=>{
    toggleDetails.value=!toggleDetails.value
    console.log('back to pending transfer');
}

let pendingList =  ref(store.getters.pendingList)
const userStore =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_STORE)))
const userRole =  computed(() =>JSON.parse(JSON.stringify(store.getters.USER_ROLE)))

const istvDetail =  computed(() =>store.getters.istvDetail)



const fname = ref()
const company = ref()
const role = ref()
const phone = ref()
const email = ref()

const Headers = [
  { text: "ISTV ID", value: "istvNumber" , sortable: true },
  { text: "Transfer from", value: "transferFrom"},
  { text: "Transfer to", value: "transferTo.storeName"},
  { text: "Truck plate #", value: "truckPlate"},
  { text: "Invoice #", value: "invoiceNumber"},
  { text: "S/R #", value: "sr"},
  { text: "Date", value: "date",sortable: true},
  { text: "Actions", value: "operation"},
];


const detailitem=(val)=>{
        store.dispatch('getIstvDetail',{id:val.id})
        .then(()=>{ 
            toggleDetails.value = !toggleDetails.value
        }) 

}
const deleteitem=(val)=>{
      if (userRole.value === 'MASTER_STORE_MANAGER' ||userRole.value === 'BRANCH_STORE_MANAGER'){

        if(val.transferFrom===userStore.value.storeName){
       
        
        Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            store.dispatch('deleteStv',val.id)
                            .then(()=>{
                                store.dispatch('fetchPending')
                            })
                            .then(()=>{
                                pendingList =  ref(store.getters.pendingList)
                                filter.value = new Date()
                            })
                            
                            filter.value = new Date()
                        }
                        })
    
    }
    }
}

const showScan=(val)=>{
    window.open(val.istvFile);
}

</script>



<style lang="postcss">
    
</style>