<template>
  <div class="flex justify-center">
    <!-- <div class="col-span-1">    </div> -->
    <div class="h-full mt-20">
      <div class="">
        <img :src="dashboardLogo" alt="Tsemex_brand" class="h-52 mx-auto" />
        <h1 class="text-3xl font-bold text-center">
          Inventory Management System
        </h1>
        <div class="h-1.5 w-56 rounded-full mt-4 mx-auto bg-gold_yellow"></div>
      </div>
      <form class="flex mt-16 space-x-20">
        <div class="">
          <h3 class="text-[16px] font-bold">ENTER CODE</h3>
          <h6 class="text-[14px] text-gray-500 mt-2">
            We've emailed a reset code to your email. <br />
            Please enter the code below.
          </h6>
          <input
            type="text"
            v-model="verifCode"
            placeholder="Enter code..."
            class="inputbox p-2.5 mt-7"
          />
        </div>

        <div class="">
          <h3 class="text-[16px] font-bold">NEW PASSWORD</h3>
          <div class="relative mt-2 flex">
            <input
              :type="showPassword"
              v-model="userPassword"
              placeholder="Password..."
              class="inputbox p-2.5"
            />
            <p
              v-if="showPassword === 'password'"
              @click.prevent="changeshowpassword"
              class="text-gray-500 cursor-pointer font-medium absolute inset-y-0 inset-x-64 flex items-center"
            >
              SHOW
            </p>
            <p
              v-else
              @click.prevent="changeshowpassword"
              class="text-gray-500 cursor-pointer font-medium absolute inset-y-0 inset-x-64 flex items-center"
            >
              HIDE
            </p>
          </div>
          <input
            type="password"
            v-model="confPassword"
            placeholder="Confirm password..."
            class="inputbox p-2.5 mt-6"
          />
          <div class="flex justify-end">
            <button
              :disabled="activate"
              @click.prevent="ResetPassword"
              class="submitbutton w-[108px] mt-10"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import dashboardLogo from "../../assets/dashboardLogo.png";
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const router = useRouter();
const email = ref();
email.value = window.location.href.split("/:")[1];

const showPassword = ref("password");
const verifCode = ref("");
const userPassword = ref("");
const confPassword = ref("");
const store = useStore();

const activate = computed(() => {
  if (!verifCode.value || !userPassword.value || !confPassword.value) {
    return true;
  } else {
    return false;
  }
});

const ResetPassword = () => {
  if (userPassword.value === confPassword.value) {
    store.dispatch("ResetPassword", {
      email: email.value.toLowerCase(),
      code: verifCode.value,
      password: userPassword.value,
    });
  } else {
  }
};
const changeshowpassword = () => {
  if (showPassword.value === "text") {
    showPassword.value = "password";
  } else {
    showPassword.value = "text";
  }
};
</script>
<style lang=""></style>
