<template>
    <div  class="mt-5">

    <!-- backarrow -->
    <div class="flex items-center ml-3 cursor-pointer" @click="$emit('close')">
          <img :src="backarrow" alt="backarrow" class="h-3 mr-2.5">
          <p class="flex text-sm  text-nav_blue underline underline-offset-2 decoration-1 cursor-pointer"> Back</p>
    </div>
        <!-- rest dynamic modal content -->
    <form class=" mt-8 mb-7 mx-7">
        <div class=" font-black text-[30px] ">UPDATE ISSUE GOODS
            <div class="h-2 w-[160px] rounded-full bg-gold_yellow "></div>
        </div>
        <!--   input fields  -->
        <input type="text" v-model="sivnum"   placeholder="SIV doc #…" required class="inputbox nav_blue mt-10 mb-6">
        <div class="grid grid-cols-3 mb-8">
                     
                    <div>
                        <input type="text" v-model="requestedby"   placeholder="Requested by..." required class="inputbox  mb-3">
                        <input type="text" v-model="srnum"   placeholder="Store requisition #..." required class="inputbox  mb-3">
                        <div class=" relative inputbox  mb-0.5">
                            <img :src="attachment" alt="attachment" @click="$refs.srvscanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="srvscan.name?srvscan.name:srvscan.split('---')[1]" disabled   placeholder="Attach SRV scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="srvscanfile" accept=".pdf,image/*" @change="uploadsrv" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500 mb-1.5">Only PDF, PNG, JPG files allowed.</p>
                        <div class=" relative inputbox  mb-0.5">
                            <img :src="attachment" alt="attachment" @click="$refs.gatepassscanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="gatepassscan.name?gatepassscan.name:gatepassscan.split('---')[1]" disabled  placeholder="Attach Gate Pass scan..." class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="gatepassscanfile" accept=".pdf,image/*" @change="uploadgatepass" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                    <div>
                        <input type="text" v-model="issuedto"   placeholder="Issued to..." required class="inputbox  mb-3">
                       <div class=" relative inputbox  mb-3">
                            <img :src="calander" @click="$refs.calanderinput.renderView('day')" alt="calander" class="absolute z-20 right-3 h-3.5 mt-1.5">
                            <!-- <input type="date" v-model="issuedate"   placeholder="Issue date..." required class="w-full bg-main_gray outline-none "> -->
                            <datepicker ref="calanderinput" inputFormat="dd/MM/yyyy" v-model="issuedate" :upperLimit="new Date()" placeholder="Issue date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                       <div class=" relative inputbox  mb-0.5">
                            <img :src="attachment" alt="attachment" @click="$refs.approvalscanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text" :value="approvalscan.name?approvalscan.name:approvalscan.split('---')[1]" disabled  placeholder="Attach approval form scan..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="approvalscanfile" accept=".pdf,image/*" @change="uploadapproval" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500 mb-1.5">Only PDF, PNG, JPG files allowed.</p>
                        <div class=" relative inputbox  mb-1">
                            <img :src="attachment" alt="attachment" @click="$refs.sivscanfile.click()" class="absolute right-3 h-3.5 mt-1.5">
                            <input type="text"  :value="sivscan.name?sivscan.name:sivscan.split('---')[1]" disabled  placeholder="Attach SIV scan..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                            <input type="file" ref="sivscanfile" accept=".pdf,image/*" @change="uploadsiv" style="display: none" />   
                        </div>
                            <p class="text-[10px] text-gray-500">Only PDF, PNG, JPG files allowed.</p>
                    </div>
                   
        </div>
        <!-- insert items -->
        <div v-for="(item,index) in itemList" :key="item" class="mb-4"> 
            <div class="flex">
                <!-- <div class=" relative inputbox  w-40 mr-2">
                    <img :src="magnifyingglass" alt="magnifyingglass" class="absolute right-3 h-3 mt-1.5">
                    <input type="text" v-model="allItems[index].itemId" @input="searchItem(index)"  placeholder="Item ID..." required class="w-full bg-main_gray text-nav_blue outline-none ">
                </div> -->
                <!-- <DropDown placeholderp="UoM..." :items="companies"  displayable="name" @selected="(selected)=>setUom(selected,index)"  width="false" class="mr-20 mx-1 "/> -->
                <ItemSearch  :items="staticItemList" :defaultValue="allItems[index].itemId" displayable="name" @selected="(selected)=>searchItem(selected,index)"  width="false" class="mr-24 mx-1 "/>
                <input type="text" v-model="alldesc[index].uom"   placeholder="UoM..." required class="inputbox ml-1 w-36">
                <input type="number" v-model="allItems[index].balance" @input="(event)=>checkBalance(index,event)"  placeholder="Quantity..." required class="inputbox ml-3 mr-3 w-36">
               <div class=" relative inputbox  w-40 mr-3 " :class="{'hidden':alldesc[index].itemType=='ONE_TIME_USE',}"  >
                            
                            <img :src="calander" @click="$refs.returndate.renderView('day')" alt="calander" class="absolute z-10 right-3 h-3.5 mt-1.5">
                            <datepicker ref="returndate" inputFormat="dd/MM/yyyy" v-model="allItems[index].returnDate" :upperLimit="new Date()" placeholder="Return date..."  style="--vdp-hover-bg-color: #F0A500;--vdp-selected-bg-color:#F0A500" class="picker bg-main_gray w-full outline-none "/>
                        </div>
                <input type="text" v-model="allItems[index].remark"   placeholder="Remark..." class="inputbox  mr-3 w-56">
                <button v-if="index>0 || itemList!==1" :key="index" @click="deductList(index)" class="bg-red-500 text-white text-3xl w-9 h-9 mx-2 items-center  rounded-full ">-</button>
                <button v-if="index===itemList-1" @click="addItemInputs" class=" text-white text-3xl w-9 h-9 mx-2 items-center  pb-3 rounded-full "
                :class="{'bg-nav_blue':itemList!==1,'bg-[#8F8F8F]':itemList===1}">+</button>
            </div>
            <div class="" >
                <p class="text-[12px]">{{alldesc[index].desc}}</p>
                <p class="-mt-3 ml-[345px] text-[12px] font-semibold text-green-400">{{alldesc[index].balance}}</p>
            </div>    
        </div>

    <div class="mt-20">
            <button v-if="!isLoading" type="submit" @click.prevent="submit" :disabled="activate" class="submitbutton">Update</button>
            <button v-else :disabled="true" class="disabled:bg-blacker disabled:text-gold_yellow bg-gold_yellow text-black text-xl font-semibold rounded-lg p-2 px-6 ">
                <Spinner/>
            </button>
        </div>
    </form>    
      
    </div>
</template>


<script setup>
import { reactive, ref } from "@vue/reactivity"
import { computed } from "@vue/runtime-core"
import TableView from "../../components/TableView.vue";
import Datepicker from 'vue3-datepicker'
import ItemSearch from "@/components/ItemSearch.vue";
import DropDown from "../../components/DropDown.vue";
import backarrow from "../../assets/arrowback.svg"
import calander from "../../assets/calander.svg"
import attachment from "../../assets/attachment.svg"
import magnifyingglass from "../../assets/magnifyingglass.svg"
import Spinner from "../../components/Spinner.vue"
import { useStore } from "vuex";

const close = defineEmits("close")
const store = useStore()

const props = defineProps({
 currentTr: Object
})

const currentTransaction = ref(props.currentTr)

const staticItemList =  ref(JSON.parse(JSON.stringify(store.getters.inventoryList)))
console.log(staticItemList);
const sivnum = ref(currentTransaction.value.sivNumber)
const requestedby = ref(currentTransaction.value.requestedBy)
const srnum = ref(currentTransaction.value.srvNumber)
const srvscan = ref(currentTransaction.value.srvFile)
const gatepassscan = ref(currentTransaction.value.gatePassFile)
const sivscan = ref(currentTransaction.value.sivFile)
const returndate = ref(currentTransaction.value.date)
const approvalscan = ref(currentTransaction.value.approvalFile)
const issuedate = ref(new Date(currentTransaction.value.issuedDate))
const issuedto = ref(currentTransaction.value.issuedTo)

const itemId = ref()
const uom = ref()
const balance = ref()
const returnDate = ref()
const remark = ref()
const isLoading = ref(false);

const allItems = ref([
    {itemId:'',balance:'',returnDate:'',remark:''}
])

const alldesc = ref([
    {desc:'',uom:'',oldQty:'',balance:null ,itemType:''}
])

const setUom =(selected,index)=>{
    alldesc.value[index].uom = selected.name
}

const checkBalance =(index,event)=>{
    if((Number(allItems.value[index].balance)>0)&&(Number(allItems.value[index].balance)<=Number(alldesc.value[index].balance )+Number(alldesc.value[index].oldQty ))){
        //  allItems.value[index].balance = Number(event.data);
    }else{
        allItems.value[index].balance = null
    }
}
const itemList =computed(()=>{
   return allItems.value.length===0 ?allItems.value.length + 1:allItems.value.length
})
    
const addItemInputs = ()=>{
    alldesc.value.push({desc:'',uom:'',oldQty:'',balance:null ,itemType:''})
    allItems.value.push( {itemId:'',balance:'',returnDate:'',remark:''})
}

const deductList=(i)=> {
  allItems.value = allItems.value.filter((_, index) => index !== i)
  alldesc.value = alldesc.value.filter((_, index) => index !== i)
}

const uploadapproval =(event)=>{ 
 approvalscan.value =  event.target.files[0]
}

const uploadsrv =(event)=>{ 
 srvscan.value =  event.target.files[0]
}

const uploadsiv =(event)=>{ 
 sivscan.value =  event.target.files[0]
}

const uploadgatepass =(event)=>{ 
 gatepassscan.value =  event.target.files[0]
}

const isListEmpty = computed(()=>{
         for (let index = 0; index < itemList.value; index++) {
            if( !allItems.value[index].itemId||! alldesc.value[index].uom||! allItems.value[index].balance){
                return true
            }
            
        }
})

// to disable button checking method
const activate = computed(()=>{
    if(!sivnum.value||!requestedby.value||!srnum.value ||!srvscan.value||!gatepassscan.value||!issuedto.value||!issuedate.value||!approvalscan.value||!sivscan.value||isListEmpty.value  ){
        return true;
    }else{
        return false;
    }
})

const searchItem=(selected,val)=>{
    allItems.value[val].itemId =selected
    //  staticItemList.value =  JSON.parse(JSON.stringify(store.getters.inventoryList))
    // for (let index = val-1; index >= 0; index--) {
    //    staticItemList.value = staticItemList.value.filter(item=>item.id.toLowerCase()!==allItems.value[index].itemId.toLowerCase())    
    // }
    for (let index = 0; index < staticItemList.value.length; index++) {
           if((staticItemList.value[index].id).toLowerCase() === (allItems.value[val].itemId).toLowerCase()) {
                 alldesc.value[val].uom = staticItemList.value[index].uom
                 alldesc.value[val].desc = staticItemList.value[index].name
                 alldesc.value[val].balance = staticItemList.value[index].balance
                 alldesc.value[val].itemType = staticItemList.value[index].itemType
                 staticItemList.value = staticItemList.value.filter(item=>item.id.toLowerCase()!==allItems.value[val].itemId.toLowerCase())    
                 return 
         } else{
                 alldesc.value[val].uom = ''
                 alldesc.value[val].desc = ''
                 alldesc.value[val].balance = ''
                
         } 
    }
}

const setSiv = ()=>{
    
        allItems.value=[]
        alldesc.value=[]
        addItemInputs();

    
     for (let index = 0; index < currentTransaction.value.SivTransaction.length; index++) {
        allItems.value[index].itemId = currentTransaction.value.SivTransaction[index].item.stock.item.id
        alldesc.value[index].uom = currentTransaction.value.SivTransaction[index].item.stock.item.uom.abbreviation
        allItems.value[index].balance = currentTransaction.value.SivTransaction[index].item.issuedQty
        alldesc.value[index].oldQty = currentTransaction.value.SivTransaction[index].item.issuedQty
        allItems.value[index].returnDate = new Date(currentTransaction.value.SivTransaction[index].item.returnDate)
        allItems.value[index].remark = currentTransaction.value.SivTransaction[index].item.remark
        alldesc.value[index].desc = currentTransaction.value.SivTransaction[index].item.stock.item.itemName

         for (let i = 0; i < staticItemList.value.length; i++) {
            if((staticItemList.value[i].id).toLowerCase() === (allItems.value[index].itemId).toLowerCase()) {
                    
                    alldesc.value[index].balance = staticItemList.value[i].balance
                    
                } 
         }

        index < currentTransaction.value.SivTransaction.length-1 && addItemInputs();
    }

   
}
setSiv ()


const submit =()=>{
    const formData = new FormData();
    formData.append("id", currentTransaction.value.id);
    sivnum.value && formData.append("sivNumber", sivnum.value);
    requestedby.value && formData.append("requestedBy", requestedby.value);
    srvscan.value && formData.append("SRV", srvscan.value);
    srnum.value && formData.append("srvNumber", srnum.value);
    gatepassscan.value && formData.append("GATEPASS", gatepassscan.value);
    issuedto.value && formData.append("issuedTo", issuedto.value);
    issuedate.value && formData.append("issuedDate", issuedate.value);
    approvalscan.value && formData.append("APPROVAL", approvalscan.value);
    sivscan.value && formData.append("SIV", sivscan.value);
    allItems.value && formData.append("items",(JSON.stringify(allItems.value)));
   
    isLoading.value = true;
     store.dispatch('UpdateStoreIssue',
        formData,
     ) 
      .then(()=>{ 
          store.dispatch('fetchReqisition')
          store.dispatch('fetchInventory')
          isLoading.value = false;
      })
      .then(()=>{
          close('close')
      }) 
      .catch((error) => {
            isLoading.value = false;
        });

}

</script>

<style lang="postcss">


</style>